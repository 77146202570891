import { Routes } from "@angular/router";
import { ProductsComponent } from "../../../feature/products/pages/products/products.component";
import { RefreshResolver } from "../resolvers/refresh.resolver";
import { CleanDataResolver } from "../resolvers/clean-data.resolver";
import { HomeComponent } from "../../../feature/quote/pages/home/home.component";
import { PassengersComponent } from "../../../feature/passengers/pages/passengers.component";
import { DocumentsResolver } from "../resolvers/documents.resolver";
import { PurchaseDetailsResolver } from "../resolvers/details.resolver";
import { authGuard } from "../guards/auth.guard";
import { OrderPayedGuard } from "../guards/order-payed.guard";
import { PaymentComponent } from "../../../feature/payment/pages/payment/payment.component";
import { VoucherComponent } from "../../../feature/voucher/voucher.component";
import { MetaResolver } from "../../meta/resolver/meta.resolver";
import { CallingCodeResolver } from "../resolvers/calling-code.resolver";

export const ptRoutes : Routes = [
    { path: '', component: HomeComponent, canActivate: [authGuard], resolve: {route: RefreshResolver, MetaResolver} },
    { path: 'precos', component: ProductsComponent, canActivate: [authGuard], resolve: {route: RefreshResolver, CleanDataResolver, MetaResolver} },
    { path: 'passageiros', component: PassengersComponent, canActivate: [authGuard, OrderPayedGuard], resolve: {route: RefreshResolver, purchaseDetails: PurchaseDetailsResolver, documents: DocumentsResolver, MetaResolver,  prefixes: CallingCodeResolver} },
    { path: 'pagamento', pathMatch: 'full', component: PaymentComponent, canActivate: [authGuard, OrderPayedGuard], resolve: {route: RefreshResolver, purchaseDetails: PurchaseDetailsResolver, MetaResolver} },
    { path: 'compra-bem-sucedida', pathMatch: 'full', component: VoucherComponent, canActivate: [authGuard], resolve: {route: RefreshResolver, purchaseDetails: PurchaseDetailsResolver, MetaResolver} },
] 