<form class="lg:col-span-3" [formGroup]="paxForm" (ngSubmit)="onSubmit()">
  <div formArrayName="passengers">
    <div *ngFor="let passengerForm of passengers.controls; let i = index">
      <div class="mb-4 rounded-xl p-4 md:p-8 shadow border border-grey3" [formGroup]="passengerForm" #formDir="ngForm">
        <div class="font-bold text-blue flex justify-between">
            <div class="my-auto">
                <span class="text-lg">VIAJERO {{ i + 1 }}</span>              
                <img src="../../../../assets/img/icons/pasajero.png" alt=""
                    class="w-[20px] inline mb-2 ml-8 mr-4"> {{ passengersAges[i] }} años
            </div>
            
            <img 
              src="../../../../assets/img/icons/caret-down.svg" 
              *ngIf="passengerForm.get('visible').value" 
              alt=""
              class="w-[20px] my-auto" 
              (click)="passengerForm.get('visible').value = !passengerForm.get('visible').value"
            >
            <img 
              src="../../../../assets/img/icons/caret-up.svg" 
              *ngIf="!passengerForm.get('visible').value" alt=""
              class="w-[20px] my-auto" 
              (click)="passengerForm.get('visible').value = !passengerForm.get('visible').value"
            >
        </div>
        <hr class="mb-2 border-black" />
        <div *ngIf="passengerForm.get('visible').value">
            <div class="grid grid-cols-1 gap-2 lg:gap-4 lg:grid-cols-3">
                <div class="w-full mx-auto space-y-1">
                        <input 
                            type="text" 
                            class="w-full rounded-lg border p-2 text-xs border-grey-3" 
                            placeholder="Nombre" 
                            formControlName="name"
                            [ngClass]="{
                                'border-red text-red focus-visible:outline-red': passengerForm.get('name').invalid && (passengerForm.get('name').touched || passengerForm.get('name').dirty),
                                'border-grey3' : passengerForm.get('name').valid
                            }"      
                            letterOnly                    
                        />
                    <span *ngIf="passengerForm.get('name').hasError('required') && (passengerForm.get('name').touched || passengerForm.get('name').dirty)" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un nombre.
                    </span>
                    <span *ngIf="passengerForm.get('name').hasError('pattern') && (passengerForm.get('name').touched || passengerForm.get('name').dirty)" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un nombre válido.
                    </span>
                </div>

                <div class="w-full mx-auto space-y-1">
                    <input 
                        type="text" 
                        class="w-full rounded-lg border border-grey3 p-2 text-xs" 
                        placeholder="Apellido" 
                        formControlName="lastName"
                        [ngClass]="{
                            'border-red text-red focus-visible:outline-red': passengerForm.get('lastName').invalid && (passengerForm.get('lastName').touched || passengerForm.get('lastName').dirty),
                            'border-grey3' : passengerForm.get('lastName').valid
                        }"
                        letterOnly
                    />
                    <span *ngIf="passengerForm.get('lastName').hasError('required') && (passengerForm.get('lastName').touched || passengerForm.get('lastName').dirty)" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un apellido.
                    </span>
                    <span *ngIf="passengerForm.get('lastName').hasError('pattern') && (passengerForm.get('lastName').touched || passengerForm.get('lastName').dirty)" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un apellido válido.
                    </span>
                </div>

                <div class="w-full mx-auto space-y-1">

                    <input 
                        type="text" 
                        class="w-full rounded-lg border border-grey3 p-2 text-xs"
                        placeholder="DD/MM/AAAA" 
                        formControlName="birthDate" 
                        (input)="formatDate($event)"
                        [ngClass]="{
                            'border-red text-red focus-visible:outline-red': passengerForm.get('birthDate').invalid && passengerForm.get('birthDate').touched,
                            'border-grey3' : passengerForm.get('birthDate').valid
                        }"
                    />

                    <span *ngIf="passengerForm.get('birthDate').hasError('required') && passengerForm.get('birthDate').touched" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar una fecha de nacimiento.
                    </span>
                    <span *ngIf="passengerForm.get('birthDate').hasError('invalidPattern') && passengerForm.get('birthDate').touched" class="text-rose-600 dark:text-rose-500 text-xs">
                        Ingrese una fecha de nacimiento válida.
                    </span>
                    <span *ngIf="passengerForm.get('birthDate').hasError('wrongDate') && passengerForm.get('birthDate').touched" class="text-rose-600 dark:text-rose-500 text-xs">
                        No coincide con la edad informada en la cotización.
                    </span>
                </div>
                <div class="w-full mx-auto space-y-1">
                    <p class="text-xs font-medium text-grey">Tipo y número de documento</p>
                    <div class="flex gap-2">
                        <select name="docType" id="docType"
                                class="inline w-2/6 rounded-lg border border-grey3 bg-white p-2 text-grey text-xs"
                                formControlName="documentType"
                                [ngClass]="{
                                    'border-red focus-visible:outline-red': passengerForm.get('documentNumber').invalid && passengerForm.get('documentNumber').touched,
                                    'border-grey3' : passengerForm.get('documentNumber').valid
                                }"
                            >
                            <option 
                                *ngFor="let document of enableDocuments; let i = index" 
                                value="{{ document.code }}" 
                                [selected] = "i == 0"
                            >
                                {{ document.description }}
                            </option>
                        </select>
                        <input 
                            type="text" 
                            class="inline w-4/6 rounded-lg border p-2 text-xs"
                            placeholder="Número" 
                            formControlName="documentNumber"
                            [ngClass]="{
                                'border-red text-red focus-visible:outline-red': passengerForm.get('documentNumber').invalid && passengerForm.get('documentNumber').touched,
                                'border-grey3' : passengerForm.get('documentNumber').valid
                            }"
                            
                        />
                    </div>
                    <span *ngIf="passengerForm.get('documentNumber').hasError('required') && passengerForm.get('documentNumber').touched" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un número de documento.
                    </span>
                    <span *ngIf="
                            ((passengerForm.get('documentNumber').hasError('minlength') || passengerForm.get('documentNumber').hasError('maxlength')) && !passengerForm.get('documentNumber').hasError('required')) && passengerForm.get('documentNumber').touched" 
                            class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un número de documento válido.
                    </span>
                    <span 
                        *ngIf="passengerForm.get('documentNumber').hasError('duplicateDocument')" 
                        class="text-rose-600 dark:text-rose-500 text-xs">
                        El número de documento ya está en uso.
                    </span>   
                </div>

                <div class="w-full mx-auto space-y-1">
                    <p class="text-xs font-medium text-grey invisible hidden lg:block">Email</p>
                    <input 
                        type="mail" 
                        class="w-full rounded-lg border p-2 text-xs"
                        placeholder="Email" 
                        formControlName="email"
                        [ngClass]="{
                            'border-red text-red focus-visible:outline-red': passengerForm.get('email').invalid && passengerForm.get('email').touched,
                            'border-grey3' : passengerForm.get('email').valid
                        }"
                    />

                    <span *ngIf="passengerForm.get('email').touched && passengerForm.get('email').hasError('required')" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un email.
                    </span>
                    <span *ngIf="passengerForm.get('email').touched && passengerForm.get('email').hasError('invalidEmail')" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un email válido.
                    </span>
                </div>

                <div class="w-full mx-auto space-y-1">
                  <p class="text-xs font-medium text-grey">Teléfono</p>
                  <div class="flex">    

                    <prefix-selector class="relative block w-[80px]"
                        [showError]="passengerForm.get('phone').invalid && passengerForm.get('phone').touched"                    
                        [selectedCode]="selectedCodeNumbers[i]"
                        (prefixSelected)="onPrefixSelected($event, i)"
                    >                     
                    </prefix-selector>

                    <input type="text"
                        class="block w-full text-xs rounded-e-lg border-y border-r p-2 focus-visible:border-y focus-visible:border-l focus-visible:border-r-0"
                        placeholder="+{{selectedCodeNumbers[i]}}" 
                        formControlName="phone"
                        [ngClass]="{
                        'border-red text-red focus-visible:outline-red': passengerForm.get('phone').invalid && passengerForm.get('phone').touched,
                        'border-grey3' : passengerForm.get('phone').valid
                        }"
                        numberOnly
                    />
                    </div>
                    <span *ngIf="passengerForm.get('phone').hasError('required') && passengerForm.get('phone').touched" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un número de teléfono.
                    </span>
                    <span *ngIf="
                        ((passengerForm.get('phone').hasError('pattern') 
                        || passengerForm.get('phone').hasError('minlength')
                        || passengerForm.get('phone').hasError('maxlength')
                        ) && !passengerForm.get('phone').hasError('required'))
                        && passengerForm.get('phone').touched" class="text-rose-600 dark:text-rose-500 text-xs">
                        Debe ingresar un número de teléfono válido.
                    </span>
                </div>
            </div>
            <div *ngIf="i == 0 || !uniqueEmergencyContact" class="my-4 lg:mb-0 text-grey">
                <span class="text-base font-medium">Contacto de emergencia</span>
                <hr class="lg:mb-2 border-black" />
                <div class="grid grid-cols-1 gap-2 lg:gap-4 lg:grid-cols-3 mt-2">


                    <div class="w-full mx-auto space-y-1">
                        <p class="text-xs font-medium text-grey invisible hidden lg:block">Nombre</p>
                        <input 
                            type="text" 
                            class="w-full rounded-lg border p-2 text-xs"
                            placeholder="Nombre" 
                            formControlName="emergencyContactName"
                            [ngClass]="{
                                'border-red text-red focus-visible:outline-red': passengerForm.get('emergencyContactName').invalid && passengerForm.get('emergencyContactName').touched,
                                'border-grey3' : passengerForm.get('emergencyContactName').valid
                            }"
                            letterOnly
                        />
                        <span *ngIf="passengerForm.get('emergencyContactName').hasError('required') && passengerForm.get('emergencyContactName').touched" class="text-rose-600 dark:text-rose-500 text-xs">
                            Debe ingresar un nombre.
                        </span>
                        <span *ngIf="passengerForm.get('emergencyContactName').hasError('pattern') && (passengerForm.get('emergencyContactName').touched || passengerForm.get('emergencyContactName').dirty)" class="text-rose-600 dark:text-rose-500 text-xs">
                            Debe ingresar un nombre válido.
                        </span>
                    </div>


                    <div class="w-full mx-auto space-y-1">
                        <p class="text-xs font-medium text-grey invisible hidden lg:block">Apellido</p>
                        <input 
                            type="text" 
                            class="w-full rounded-lg border border-grey3 p-2 text-xs"
                            placeholder="Apellido" 
                            formControlName="emergencyContactLastName"
                            [ngClass]="{
                                'border-red text-red focus-visible:outline-red': passengerForm.get('emergencyContactLastName').invalid && passengerForm.get('emergencyContactLastName').touched,
                                'border-grey3' : !passengerForm.get('emergencyContactLastName').valid
                            }"
                            letterOnly
                        />

                        <span *ngIf="passengerForm.get('emergencyContactLastName').hasError('required') && passengerForm.get('emergencyContactLastName').touched" class="text-rose-600 dark:text-rose-500 text-xs">
                            Debe ingresar un apellido.
                        </span>
                        <span *ngIf="passengerForm.get('emergencyContactLastName').hasError('pattern') && (passengerForm.get('emergencyContactLastName').touched || passengerForm.get('emergencyContactLastName').dirty)" class="text-rose-600 dark:text-rose-500 text-xs">
                            Debe ingresar un apellido válido.
                        </span>
                    </div>


                    <div class="w-full mx-auto space-y-1">
                        <p class="text-xs font-medium">Teléfono</p>
                        <div class="flex rounded-lg">
                            <prefix-selector class="relative block w-[80px]"
                                [showError]="passengerForm.get('emergencyContactPhone').invalid && passengerForm.get('emergencyContactPhone').touched"
                                [selectedCode]="selectedEmergencyCodeNumbers[i]"
                                (prefixSelected)="onEmergencyPrefixSelected($event, i)"
                            >                     
                            </prefix-selector>
                            <input 
                                type="text"
                                class="block w-full text-xs rounded-e-lg border-y border-r border-grey3 p-2 focus-visible:border-y focus-visible:border-l focus-visible:border-r-0"
                                placeholder="+{{selectedEmergencyCodeNumbers[i]}}" 
                                formControlName="emergencyContactPhone"
                                [ngClass]="{
                                    'border-red text-red focus-visible:outline-red': passengerForm.get('emergencyContactPhone').invalid && passengerForm.get('emergencyContactPhone').touched,
                                    'border-grey3' : !passengerForm.get('emergencyContactPhone').hasError('required')
                                }"
                                numberOnly
                            />                           
                        </div>
                        <span *ngIf="passengerForm.get('emergencyContactPhone').hasError('required') && (passengerForm.get('emergencyContactPhone').touched || passengerForm.get('emergencyContactPhone').dirty)" class="text-rose-600 dark:text-rose-500 text-xs">
                            Debe ingresar un teléfono.
                        </span>
                        <span *ngIf="
                            ((passengerForm.get('emergencyContactPhone').hasError('pattern')
                            || passengerForm.get('emergencyContactPhone').hasError('minlength')
                            || passengerForm.get('emergencyContactPhone').hasError('maxlength')) && !passengerForm.get('emergencyContactPhone').hasError('required'))
                            && (passengerForm.get('emergencyContactPhone').touched || passengerForm.get('emergencyContactPhone').dirty)" class="text-rose-600 dark:text-rose-500 text-xs">
                            Debe ingresar un teléfono válido.
                        </span>
                    </div>


                </div>
                <div *ngIf="passengersAges.length > 1" class="mt-4 lg:mt-8 font-medium text-grey text-xs" >
                    <label *ngIf="i == 0"><input type="checkbox" 
                            name="uniqueEmergencyContact" class="mr-2" 
                            (change)="setUniqueEmergencyContact($event)"
                            [checked]="uniqueEmergencyContact"
                            />Utilizar este contacto de emergencia
                        para todos los pasajeros</label>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hidden lg:block mt-8 text-center lg:text-right">
    <button 
      class="rounded-lg bg-blue px-8 py-4 font-bold text-white disabled:bg-[#D5D5D5] hover:bg-[#1D1191] transition-colors hover:easy-in duration-300"
      type="submit"
      [disabled]="!paxForm.valid"
        >
        CONTINUAR
    </button>
  </div>
</form>



<ng-template #flagTooltip>
    <div class="bg-white font-medium w-[38px]">
      <div *ngFor="let code of codes.callingCodes" class="py-2 cursor-pointer" (click)="selectFlag(flag)">
        <img src="../../../../../assets/img/flags/{{code.countryIso}}.svg" [alt]="code.countryIso" class="w-[15px] h-[15px] mx-auto" />
      </div>
    </div>
  </ng-template>
