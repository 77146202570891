export interface IRoute{
    spanishName: string;
    englishName: string;
    portugueseName: string;
}

export class QuoteRoute implements IRoute{
    spanishName: string = 'cotizador';
    englishName: string = 'quote';
    portugueseName: string = 'cotador';
}

export class ProductRoute implements IRoute{
    spanishName: string = 'precios';
    englishName: string = 'prices';
    portugueseName: string = 'precos';
}

export class PassengersRoute implements IRoute{
    spanishName: string = 'pasajeros';
    englishName: string = 'passengers';
    portugueseName: string = 'passageiros';
}

export class PaymentRoute implements IRoute{
    spanishName: string = 'pago';
    englishName: string = 'payment';
    portugueseName: string = 'pagamento';
}

export class VoucherRoute implements IRoute{
    spanishName: string = 'compra-exitosa';
    englishName: string = 'successfull-purchase';
    portugueseName: string = 'compra-bem-sucedida';
}



//statics
export class FaqsRoute implements IRoute{
    spanishName: string = 'faq';
    englishName: string = 'faq';
    portugueseName: string = 'faq';
}

export class AboutUsRoute implements IRoute{
    spanishName: string = 'quienes-somos';
    englishName: string = 'about-us';
    portugueseName: string = 'quem-somos';
}

export class ContactRoute implements IRoute{
    spanishName: string = 'contacto';
    englishName: string = 'contact';
    portugueseName: string = 'contato';
}

export class PrivacyRoute implements IRoute{
    spanishName: string = 'privacidad';
    englishName: string = 'privacy';
    portugueseName: string = 'privacidade';
}

export class TermsRoute implements IRoute{
    spanishName: string = 'condiciones';
    englishName: string = 'terms';
    portugueseName: string = 'condicoes';
}




export class QuoteSubdirectoryRoute implements IRoute{
    spanishName: string = 'cotizador';
    englishName: string = 'quote';
    portugueseName: string = 'cotador';
}

export class CompaniesSubdirectoryRoute implements IRoute{
    spanishName: string = 'empresas';
    englishName: string = 'companies';
    portugueseName: string = 'empresas';
}

