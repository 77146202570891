import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { LocalStorageService } from "../../services/local-storage.service";

export const CleanDataResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) => {
    const localStorageService = inject(LocalStorageService); 
    localStorageService.removeDiscountCode();
  };