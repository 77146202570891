<div *ngIf="dataIsReady">
  <app-header 
    [showQuoteButton]="false" 
    [showLogin]="false" 
    [showLangs]="false" 
  ></app-header>
  <div class="w-full justify-between bg-blue py-8 lg:py-12 xl:px-60">
    <p class="text-center text-3xl font-bold text-white px-4">
      ¡Tu compra fue exitosa!
    </p>
  </div>
  <div class="md:2/3 mx-4 md:mx-8 lg:mx-auto my-8 rounded-lg shadow-md xl:w-1/3">
    <div class="rounded-t-lg bg-grey p-4 text-white xl:p-8">
      <p class="text-center text-xl">
        Número de voucher: <span class="text-xl font-bold">{{ voucherNumber }}</span>
      </p>
    </div>
    <div class="rounded-b-lg p-4 lg:p-8 ">
      <p class="mb-2 font-bold text-blue">DETALLES</p>
      <p class="mb-2 text-sm text-blue">
        <span class="text-grey font-regular">Plan:</span> <b>&nbsp;{{ purchaseDetails.product.name }}</b>
      </p>
      <p class="mb-2 text-sm text-blue">
        <span class="text-grey font-regular">Viaje a:</span> <b>&nbsp;{{detailCountries}}</b>
      </p>
      <p class="mb-2 text-sm text-blue">
        <span class="text-grey font-regular">Vigencia del servicio:</span> <b>&nbsp;{{purchaseDetails.quote.starting | date: "longDate"}} - {{ purchaseDetails.quote.ending | date: "longDate"}}</b>
      </p>
      <p class="mb-2 text-sm text-blue">
        <span class="text-grey font-regular">Pasajeros:</span> <b>&nbsp;{{ paxAmount }}</b>
      </p>
      <hr class="my-4 border-black" />
      <div *ngFor="let pax of passengers">
        <div class="flex justify-between font-bold text-blue">
          <span>{{pax.name}} {{pax.lastName}}</span>
            <div>
              <fa-icon *ngIf="!pax.downloadingVoucher" [icon]="faDownload" class="ml-2 inline-block cursor-pointer text-blue lg:ml-4" ngxTippy="Descargar Voucher"
              [tippyProps]="{
                  placement: 'top',
                  theme: 'light-border',
              }" (click)="downloadVoucher(pax.paxId)"></fa-icon>   
              <mat-icon *ngIf="pax.downloadingVoucher" class="ml-2 inline-block cursor-pointer text-blue lg:ml-4 visible-overflow" viewBox="16 16 16 16">
                <mat-spinner color="blue" diameter="20">
                </mat-spinner>
              </mat-icon> 
              <fa-icon *ngIf="!pax.sendingByEmail" [icon]="faMail" class="ml-2 inline-block cursor-pointer text-blue lg:ml-4" ngxTippy="Enviar por mail"
              [tippyProps]="{
                  placement: 'top',
                  theme: 'light-border',
              }" (click)="sendVoucherByEmail(pax.paxId)"></fa-icon>     
              <mat-icon *ngIf="pax.sendingByEmail" class="ml-2 inline-block cursor-pointer text-blue lg:ml-4 visible-overflow" viewBox="16 16 16 16">
                <mat-spinner color="blue" diameter="20">
                </mat-spinner>
              </mat-icon> 
              <!-- <fa-icon [icon]="faWp" class="ml-2 inline-block cursor-pointer text-blue lg:ml-4" ngxTippy="Compartir por Whatsapp"
              [tippyProps]="{
                  placement: 'top',
                  theme: 'light-border',
              }"></fa-icon> -->
            </div>                
        </div>
        <hr *ngIf="passengers.length > 1" class="my-2" />  
      </div>
      <div *ngIf="error" class="pt-2">
        <p class="bg-red/20 text-red px-8 py-2 text-center">{{ errorMessage }}</p>
      </div>  
    </div>   
  </div>
  <app-footer [showAirplane]="false"></app-footer>
</div>

<app-loader *ngIf="!dataIsReady"></app-loader>

