import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../../../../shared/components/header/header.component';
import { FooterComponent } from '../../../../shared/components/footer/footer.component';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  standalone: true,
  imports: [HeaderComponent, FooterComponent],
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {

  openModal() {
    const dialogRef = this.dialog.open(PrivacyModalComponent,
      {
        width: '500px',
        maxWidth: '90vw'
      }
    );
  }
  constructor(private dialog: MatDialog) {}

}
