import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { HeaderComponent } from '../../../../shared/components/header/header.component';
import { FooterComponent } from '../../../../shared/components/footer/footer.component';
import { PurchaseResumeComponent } from '../../../../shared/components/purchase-resume/purchase-resume.component';
import { ProgressBarComponent } from '../../../../shared/components/progress-bar/progress-bar.component';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { PaymentFormComponent } from '../../components/payment-form/payment-form.component';
import { PaymentService } from '../../services/payment.service';
import { Subject, catchError, of, switchMap, takeUntil } from 'rxjs';
import { RouteService } from '../../../../core/route/route.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    ProgressBarComponent,
    PurchaseResumeComponent,
    LoaderComponent,
    PaymentFormComponent
  ]
})
export class PaymentComponent implements OnInit, OnDestroy {

  private paymentService = inject(PaymentService);
  private routeService = inject(RouteService);
  private destroy$ = new Subject<void>();

  dataIsReady = false;

  constructor() { }

  ngOnInit() {
    this.paymentService.createPaymentRequest()
    .pipe(
      takeUntil(this.destroy$),
      switchMap((createPaymentResponse: any) => {
        this.paymentService.savePaymentId(createPaymentResponse);
        return of(createPaymentResponse);
      }),
      catchError((err) => {
        this.routeService.refreshPage();       
        return of(null);
      })
    )
    .subscribe((createPaymentResponse: any) => {
      if(!createPaymentResponse) return;
      this.dataIsReady = true;
    });
  }

  ngOnDestroy(){
    this.destroy$.next();
    this.destroy$.complete();
  }
}
