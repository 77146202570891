export const gtmScript = (tagManagerId: string) : string => {
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', ${'"' + tagManagerId + '"'});`;
};

export const bodyGmtScript = (tagManagerId: string) => {
    const iFrame = document.createElement('iframe');

    iFrame.src = `https://www.googletagmanager.com/ns.html?id=${tagManagerId}`;
    iFrame.height = '0';
    iFrame.width = '0';
    iFrame.style.display = 'none';
    iFrame.style.visibility = 'hidden';

    return iFrame;
}