import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, signal } from '@angular/core';
import { FooterComponent } from '../../../../shared/components/footer/footer.component';
import { HeaderComponent } from '../../../../shared/components/header/header.component';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
register();

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [HeaderComponent, FooterComponent],
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AboutUsComponent implements OnInit {

  swiperElement = signal<SwiperContainer | null>(null);

  ngOnInit(): void {
      const swiperElemConstructor = document.querySelector('swiper-container');
      const swiperOptions: SwiperOptions = {
        slidesPerView:1,
        pagination: {
          enabled: true,
          clickable: true,
          bulletActiveClass: 'bullet-active'
        },
        spaceBetween: '20px',
        autoplay: true,
        loop: true
      }
      Object.assign(swiperElemConstructor!, swiperOptions);
      this.swiperElement.set(swiperElemConstructor as SwiperContainer);
      this.swiperElement()?.initialize();
  }

}
