import { Injectable, inject } from '@angular/core';
import { firstValueFrom, retry, catchError, of } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { QuoteModel } from '../models/quote.model';
import { QuoteRequest, QuoteResponse } from '../models/quote';
import { RouteService } from '../../../core/route/route.service';
import { Routes } from '../../../core/enums/routes';
import { LocalStorageSources } from '../../../core/enums/storage-sources';
import { ProductRoute } from '../../../core/route/routes/route-parser';

@Injectable({
    providedIn: 'root'
})
export class QuoteService {

    // INJECT SERVICES
    private apiService = inject(ApiService);
    private localService = inject(LocalStorageService);
    private routeService = inject(RouteService);

    constructor() { }

    public validateClient(){
        const client = this.routeService.getClient();
        const request = this.apiService.post('ValidateClient', {client: client});
        return request;
    }

    public getEnabledCountries(){
        return this.apiService.get('GetEnabledCountries');
    }

    public quoteProducts(quoteModel: QuoteModel, lang: string = 'es'){
        this.storeQuote(quoteModel);
        const quoteRequest = this.convertToQuoteRequest(quoteModel);
        return this.apiService.post('GenerateQuote', quoteRequest);
    }   

    public redirectToProductsView(){
        this.routeService.resolveRedirection(new ProductRoute());
    }

    //LOCAL STORAGE METHODS
    public storeQuote(quote: QuoteModel){
        this.localService.setItem(JSON.stringify(quote), LocalStorageSources.QUOTE);
    }

    public getStoredQuote(){
        return this.localService.getItemByKey(LocalStorageSources.QUOTE);
    }

    public storeQuotedProducts(products: any){
        this.localService.setItem(JSON.stringify(products), LocalStorageSources.QUOTED_PRODUCTS);
    }

    public storeQuoteId(quoteId: number){
        this.localService.setItem(quoteId.toString(), LocalStorageSources.QUOTE_ID);
    }

    public getStoredDestinations(){
        return this.localService.getItemByKey(LocalStorageSources.DESTINATIONS);
    }

    public convertToQuoteModel(quoteRequest: QuoteRequest){
        return new QuoteModel(
            quoteRequest.origin,
            [],//quoteRequest.destination,
            quoteRequest.starting,
            quoteRequest.ending,
            quoteRequest.passengers, 
            quoteRequest.contact.name,
            quoteRequest.contact.email,
            quoteRequest.contact.phone
        );
    }

    public convertToQuoteRequest(quoteModel: QuoteModel){
        const paxAges = quoteModel.passengerAges.filter(pax => pax !== '' && pax !== null);
        const client = this.routeService.getClient();

        return new QuoteRequest(
            client,
            quoteModel.starting,
            quoteModel.ending,
            quoteModel.origin,
            quoteModel.destinations,
            paxAges,
            {
                name: quoteModel.contactName,
                email: quoteModel.contactEmail,
                phone: quoteModel.contactPhone
            }
            
        );
    }

}
