import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { IRoute } from '../route/routes/route-parser';
import { MetaTag, Tags } from './tags/tags';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    private canonicalLink: HTMLLinkElement | null = null;

    constructor(private meta: Meta, private title : Title, @Inject( DOCUMENT ) private document: Document) { }

    setMetaTags(page : IRoute | null){



        let tag = Tags.find(t => t.page?.constructor == page?.constructor);
        if(!tag){
            tag = Tags.find(t => t.page == null);
        }

        if(tag){
            if(!this.canonicalLink){
                // Create the tag and add the rel and href attributes
                this.canonicalLink = this.document.createElement( 'link' );
                this.canonicalLink.setAttribute( 'rel', 'canonical' );
                this.canonicalLink.setAttribute( 'href', tag.canonical );

                // Now we can add it to the DOM
                this.document.head.append( this.canonicalLink );
            }
            else{
                this.canonicalLink.setAttribute( 'href', tag.canonical );
            }

            tag.tags.forEach(tag => {
                if(this.getTag(tag)){
                    this.updateTag(tag);
                }
                else{
                    this.addMetaTag(tag);
                }
            });
        }
    }

    getTag(tag : MetaTag){
        if(tag.title){
            return this.title.getTitle();
        }else if(tag.name){
            return this.meta.getTag(`name='${tag.name}'`);
        }
        else if (tag.property){
            return this.meta.getTag(`property='${tag.property}'`);
        }
        else return null;
    }

    updateTag(tag : MetaTag) {
        if(tag.title){
            this.title.setTitle(tag.title);
        }else if(tag.name){
            this.meta.updateTag({ name: tag.name, content: tag.content });
        }
        else if (tag.property){
            this.meta.updateTag({ property: tag.property, content: tag.content });
        }
    }

    addMetaTag(tag : MetaTag) {
        if(tag.title){
            this.title.setTitle(tag.title);
        }else if(tag.name){
            this.meta.addTag({ name: tag.name, content: tag.content });
        }
        else if (tag.property){
            this.meta.addTag({ property: tag.property, content: tag.content });
        }
    }

    removeMetaTag(name: string) {
        this.meta.removeTag(`name='${name}'`);
    }
}