<app-header
    [showQuoteButton]="false" 
    [showLogin]="false" 
    [showLangs]="false" 
></app-header>
<div class="relative px-4 py-8 lg:px-32 flex gap-8 overflow-x-hidden min-h-[65vh]">
    <div class="flex-1">
        <h1 class="text-blue text-3xl mb-4">Recorré nuestros <b>#trendingtopics</b></h1>
        <p class="text-grey text-xl mb-4">Preguntas Frecuentes</p>

        @for(faqSection of faqs; track $index){
            <div class="my-4 text-[#4B4B4B]">
                <button (click)="toggleSection(faqSection)"
                    class="font-bold flex w-full lg:px-4 justify-between border-b pb-4 border-[#4B4B4B]">
                    <span class="text-left">{{ faqSection.section }}</span>
                    <p class="text-xl"> {{ faqSection.toggle ? '-' : '+' }}</p>
                </button>
                <div *ngIf="faqSection.toggle" class="mt-4 ml-2 lg:ml-4">
                    @for(faq of faqSection.faqs; track $index) {
                        <div class="flex gap-2 items-center mb-2">
                            <button (click)="toggleQuestion(faq)" class="text-left px-2 py-1 bg-skyblue text-white font-bold">{{ faq.question }} 
                                <b>{{ faq.toggle ? '-' : '+ '}}</b>
                            </button>
                        </div>
                        <p *ngIf="faq.toggle" class="ml-4 mb-4" [innerHTML]="faq.answer"></p>
                    }
                </div>
            </div>
        }
    </div>
    <div class="hidden xl:block self-start">
        <img src="../../assets/img/faqs/faqs.png" alt="" class="xl:pt-16">
    </div>
    <img src="../../assets/img/faqs/faqs.png" alt="" class="absolute block xl:hidden translate-x-[70%] top-0 right-0 xl:pt-16">
</div>
<app-footer></app-footer>