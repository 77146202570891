import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { MetaService } from "../meta.service";
import { AboutUsRoute, ContactRoute, FaqsRoute, PrivacyRoute, TermsRoute } from "../../route/routes/route-parser";

export const MetaResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const metaService = inject(MetaService);

    const url = state.url.split('/');
    const page = url[url.length - 1];

    switch(page){
        case 'faq':
            metaService.setMetaTags(new FaqsRoute());
            break;
        case 'about-us': case 'quienes-somos': case 'quem-somos':
            metaService.setMetaTags(new AboutUsRoute());
            break;
        case 'contact': case 'contacto': case 'contato':
            metaService.setMetaTags(new ContactRoute());
            break;
        case 'privacy': case 'privacidad': case 'privacidade':
            metaService.setMetaTags(new PrivacyRoute());
            break;
        case 'terms': case 'condiciones': case 'condicoes':
            metaService.setMetaTags(new TermsRoute());
            break;
        default:
            metaService.setMetaTags(null);            
    }
    
};