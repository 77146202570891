import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../../../../../shared/components/footer/footer.component';
import { HeaderComponent } from '../../../../../shared/components/header/header.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-contact-v2',
  standalone: true,
  imports: [CommonModule, HeaderComponent, FooterComponent],
  templateUrl: './contact-v2.component.html',
  styleUrls: ['./contact-v2.component.scss']
})
export class ContactV2Component {

}
