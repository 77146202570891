<div class="relative lg:w-[70vw]">
  <span class="absolute right-5 top-2 cursor-pointer text-white" (click)="closeModal(false)">x</span>
  <div class="bg-grey px-2 md:px-8 lg:px-16 py-4 text-white">
    <p class="font-bold">now <span class="text-3xl">{{ productName }}</span></p>
  </div>
  <div class="max-h-[400px] overflow-y-scroll px-2 md:px-8 lg:px-16 py-4 text-sm md:text-base">
    <div class="flex justify-between text-lg md:text-xl font-bold border-b border-black mb-2">
      <p class="w-4/5">Cobertura</p>
      <p class="w-1/5 text-center">Valor</p>
    </div>
    <div 
      class="py-0.5 flex text-xs md:text-base px-2 font-medium"
      *ngFor="let attribute of productAttributes; let i = index"
      ngClass="{{ i % 2 == 0 ? 'bg-[#FAF7F7]' : '' }}"
    >
      <p class="w-4/5">{{ attribute.name }}</p>
      <p class="w-1/5 text-center">{{ attribute.value }}</p>
    </div>
  </div>
  <div class="flex items-center justify-between bg-grey3 px-2 md:px-8 lg:px-16 py-4 text-blue" *ngIf="showButton">
    <p>Precio Final <b>{{ product.currency }}{{ product.price }}</b></p>
    <button class="rounded-lg bg-blue px-4 py-2 font-bold text-white hover:bg-[#1D1191] transition-colors hover:easy-in duration-300"
      (click)="closeModal(true)"
    >
      CONTRATAR
    </button>
  </div>
</div>