import { Injectable, inject } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { LocalStorageSources } from "../enums/storage-sources";
import { ApiService } from "./api.service";
import { LocalizationService } from "./localization.service";
import { catchError, of } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private apiService = inject(ApiService);
    private localService = inject(LocalStorageService);
    private localizationService = inject(LocalizationService);

    getPurchaseDetails(){
        const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
        const queryParams = '?purchaseId=' + purchaseId;
        return this.apiService.get('GetPurchaseDetails' + queryParams);
    }

    public getEnabledDocuments(){
        const country = this.localizationService.getCountry().toUpperCase();
        return this.apiService.get('GetDocumentTypes?countryIso2=' + country);
    }

    public getCallingCodes(){
        return this.apiService.get('GetCallingCodes')
        .pipe(
            catchError(error => {
                return of(null);
            })
        )       
    }
}