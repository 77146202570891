import { Injectable } from '@angular/core';
import { Purchase } from '../models/purchase';
import { LocalStorageSources } from '../enums/storage-sources';
import { RouteParams } from '../route/models/route-params';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly PURCHASE_STATE_KEY = 'purchaseState';
  private readonly CURRENT_PATH = 'currentPath';
  private readonly CURRENT_PAGE = 'currentPage';
  private readonly DISCOUNT_CODE = 'discountCode';
  
  constructor() { 
  }

  savePath(params: RouteParams): void {
    localStorage.setItem(this.CURRENT_PATH, JSON.stringify(params));
  }

  getPath() {
    const path = localStorage.getItem(this.CURRENT_PATH);
    if(path){
      return JSON.parse(path);
    }
  }

  savePage(page: string): void {
    localStorage.setItem(this.CURRENT_PAGE, page);
  }

  getPage() {
    return localStorage.getItem(this.CURRENT_PAGE);
  }

  saveDiscountCode(code: string): void {
    localStorage.setItem(this.DISCOUNT_CODE, code);
  }

  getDiscountCode() {
    return localStorage.getItem(this.DISCOUNT_CODE);
  }

  removeDiscountCode(): void {
    localStorage.removeItem(this.DISCOUNT_CODE);
  }

  // Set a value in local storage
  setItem(value: string, source: string): void {
    const currentValue = this.getItem();
  
    switch(source){
      case LocalStorageSources.PAX_DATA:
        currentValue.paxData = JSON.parse(value);
        break;
      case LocalStorageSources.DESTINATIONS:
        currentValue.destinations = JSON.parse(value);
        break;
      case LocalStorageSources.COUNTRIES:
        currentValue.countries = JSON.parse(value);
        break;
      case LocalStorageSources.QUOTE:
        currentValue.quoteRequest = JSON.parse(value);
        break;
      case LocalStorageSources.QUOTE_ID:
        currentValue.quoteId = JSON.parse(value);
        break;
      case LocalStorageSources.PURCHASE_ID:
        currentValue.purchaseId = JSON.parse(value);
        break;
      case LocalStorageSources.PAYMENT_ID:
        currentValue.paymentId = JSON.parse(value);
        break;
      case LocalStorageSources.QUOTED_PRODUCTS:
        currentValue.quotedProducts = JSON.parse(value);
        break;
      case LocalStorageSources.SELECTED_PRODUCT:
        currentValue.selectedProduct = JSON.parse(value);
        break;
    }

    this.removeItem(this.PURCHASE_STATE_KEY);
    localStorage.setItem(this.PURCHASE_STATE_KEY, JSON.stringify(currentValue));
    return;
  }

  getItemByKey(key: string): any {
    const localStorageValue = localStorage.getItem(this.PURCHASE_STATE_KEY);
    
    if(localStorageValue){
      const parsedValue = JSON.parse(localStorageValue);
      switch(key){
        case LocalStorageSources.PAX_DATA:
          return parsedValue.paxData;
        case LocalStorageSources.DESTINATIONS:
          return parsedValue.destinations;
        case LocalStorageSources.COUNTRIES:
          return parsedValue.countries;
        case LocalStorageSources.QUOTE:
          return parsedValue.quoteRequest;
        case LocalStorageSources.QUOTE_ID:
          return parsedValue.quoteId;
        case LocalStorageSources.PURCHASE_ID:
          return parsedValue.purchaseId;
        case LocalStorageSources.PAYMENT_ID:
          return parsedValue.paymentId;
        case LocalStorageSources.QUOTED_PRODUCTS:
          return parsedValue.quotedProducts;
        case LocalStorageSources.SELECTED_PRODUCT:
          return parsedValue.selectedProduct;
      }
    }
    return null;
  }

  // Get a value from local storage
  getItem(): Purchase {   
    const localStorageValue = localStorage.getItem(this.PURCHASE_STATE_KEY);
    if(localStorageValue){
      return JSON.parse(localStorageValue);
    }
    return Purchase.createPurchase();
  }

  // Remove a value from local storage
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all items from local storage
  clear(): void {
    localStorage.clear();
  }
}

