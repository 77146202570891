<div class="lg:-mt-8">
  <div *ngIf="!cotizadorOpened || mobile"
      class="flex justify-between rounded-lg bg-grey2 p-4 font-bold md:px-8 lg:py-2 lg:mx-24 2xl:mx-40">
      <p class="mr-8 hidden text-blue text-xs xl:block my-auto">DETALLES DE TU VIAJE</p>
      <p class="text-grey mr-0 xl:mr-8 flex items-start my-auto max-w-[30%]">
          <img src="../../../../assets/img/icons/location.png" alt="" class="w-[20px] mr-2 xl:mr-4">
          <span class="hidden md:inline text-sm">{{ countriesLabel }}</span>
          <span class="inline md:hidden text-xs xl:text-sm">{{ countriesLabelMobile }}</span>
      </p>
      <p class="text-grey md:mr-8 flex items-start my-auto text-sm">
          <img src="../../../../assets/img/icons/calendar.png" alt="" class="w-[20px] mr-2 xl:mr-4">
          <span class="hidden xl:inline">
            {{ previousQuote.starting | date: "longDate" }} - {{ previousQuote.ending | date: "longDate" }} <span>| {{days}} días</span>
          </span>

          <span class="inline text-xs xl:hidden"> {{ previousQuote.starting | noYearDate }}  - <br class="block lg:hidden"> {{ previousQuote.ending | noYearDate }}</span>
      </p>
      <p class="flex items-start my-auto text-grey text-sm">
          <img src="../../../../assets/img/icons/pasajero.png" alt="" class="w-[20px] mr-2 xl:mr-4"> {{ viajeros }}
          <span class="hidden xl:inline">&nbsp;{{ viajeroLabel }}</span>
      </p>
      <fa-icon [icon]="faPen" class="cursor-pointer text-sm xl:text-xl text-blue content-center"
          (click)="toggleCotizador()"></fa-icon>
  </div>
  <div class="fixed right-0 top-0 bottom-0 z-50 h-full w-full block md:hidden" *ngIf="cotizadorOpened">
    <app-quote  
      buttonText="VOLVER A COTIZAR" 
      [home]="false" 
      [mobile]="true" 
      (close)="cotizadorOpened = !cotizadorOpened"
      (quoteRequest) = "onQuoteRequest($event)"
    ></app-quote>  
  </div>
  <div class="hidden md:block">
      <app-quote *ngIf="cotizadorOpened"
          buttonText="VOLVER A COTIZAR" 
          [home]="false"
          [mobile]="false"
          (close)="cotizadorOpened = !cotizadorOpened"
          (quoteRequest) = "onQuoteRequest($event)"
        ></app-quote>
  </div>
</div>