<div [ngClass]="{'hidden' : !dataIsReady}">
  <app-header
    [showQuoteButton]="false" 
    [showLogin]="false" 
    [showLangs]="false" 
  ></app-header>
  <app-progress-bar
      activeStep = 2
  ></app-progress-bar>
  <div class="mx-4 md:mx-8 lg:mx-24 2xl:mx-40">
      <div class="my-4 grid grid-cols-1 gap-2 lg:gap-4 lg:grid-cols-4">
        <h1 class="text-2xl text-center lg:text-left text-blue lg:col-span-3">
          ¡Ya casi estamos! <b>Cargá los datos de pago y completá la compra</b>
        </h1>
        @if(dataIsReady){
          <payment-form
            class="lg:col-span-3"
          ></payment-form>
          <app-purchase-resume 
              class="h-min rounded-lg shadow border border-grey3 lg:order-last mb-4">
          </app-purchase-resume>
        }
      </div>
  </div>
  <app-footer></app-footer>
</div>
<app-loader [ngClass]="{'hidden' : dataIsReady}"></app-loader>