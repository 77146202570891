import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, NgForm, ReactiveFormsModule, ValidatorFn, Validators  } from '@angular/forms';
import { PassengerData } from '../../models/passenger-data.model';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LetterOnlyDirective } from '../../../../core/directives/letter-only.directive';
import { NumberOnlyDirective } from '../../../../core/directives/number-only.directive';
import { CallingCode } from '../../../../core/models/calling-codes';
import { PrefixSelectorComponent } from '../prefix-selector/prefix-selector.component';


@Component({
    selector: 'passenger-data-form',
    templateUrl: './passenger-data.component.html',
    styleUrls: ['./passenger-data.component.scss'],
    standalone: true,
    imports: [
    CommonModule,
    ReactiveFormsModule,
    LetterOnlyDirective,
    NumberOnlyDirective,
    PrefixSelectorComponent
],
    
})
export class PassengerDataComponent implements OnInit, OnChanges {

    @Input() passengersAges: number[] = [];
    @Output() passengerDataSubmited = new EventEmitter<PassengersComponentOutput>();
    @ViewChild('passengerForm') passengerForm!: NgForm;

    private formBuilder = inject(FormBuilder)

    private activatedRoute = inject(ActivatedRoute);

    private destroy$ = new Subject<void>();
    private paxAgesValue = new BehaviorSubject<number[]>([]);

    private enableDocuments : any[] = [];

    paxAges$ : Observable<number[]> = this.paxAgesValue.asObservable();

    uniqueEmergencyContact : boolean = false;

    tripDate : Date | string = '';

    previousPassengers : any[] | null = null;

    codes : CallingCode[] | undefined;

    selectedCodeNumbers : string[] = [];

    selectedEmergencyCodeNumbers : string[] = [];

    showFlags: boolean = false;

    defaultCodeIso = 'AR';

    defaultPrefix : string = '54';

    constructor() { }

    ngOnInit() {    
        this.activatedRoute.data.pipe(
          takeUntil(this.destroy$),   
          switchMap(
                ({ purchaseDetails, documents }) => this.paxAges$.pipe
                    (            
                        switchMap(ages => of({ purchaseDetails, documents, ages })) 
                    )
            ) 
        )
        .subscribe(({ purchaseDetails, documents, ages }) => {
            this.tripDate = purchaseDetails.quote.starting;
            this.previousPassengers = purchaseDetails.passengers.length > 0 ? purchaseDetails.passengers : null;
            
            if(this.previousPassengers){
                this.validateEmergencyContact();
            }

            this.enableDocuments = documents;

            this.selectedCodeNumbers = new Array(this.passengers.length);
            this.selectedEmergencyCodeNumbers = new Array(this.passengers.length);

            this.passengersAges.forEach((age, index) => {
                
                const passenger = purchaseDetails.passengers[index];
                
                if(!passenger){
                    this.selectedCodeNumbers[index] = this.defaultPrefix;
                    this.selectedEmergencyCodeNumbers[index] = this.defaultPrefix;
                    this.addPax(age, null);
                }
                else {
                    this.selectedCodeNumbers[index] = passenger.phonePrefix ?? this.defaultPrefix;
                    this.selectedEmergencyCodeNumbers[index] = passenger.emergencyContactPhonePrefix ?? this.defaultPrefix;
                    this.addPax(age, passenger);
                }
                    
            });       
            
            this.passengers.controls.forEach(formGroup => {
                formGroup.get('documentType')?.valueChanges.subscribe(documentType => {
                    formGroup.get('documentNumber')?.updateValueAndValidity();
                })
            })

        });       
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['passengersAges']){
            this.paxAgesValue.next(this.passengersAges);
        }
    }

    onPrefixSelected(code: string | undefined, index: number){
        if(code){
            this.passengers.controls[index].get('phonePrefix')?.setValue(code);
            this.selectedCodeNumbers[index] = code;
        }
    }

    onEmergencyPrefixSelected(code: string | undefined, index: number){
        if(code){
            this.passengers.controls[index].get('emergencyContactPhonePrefix')?.setValue(code);
            this.selectedEmergencyCodeNumbers[index] = code;
        }       
    }

    toggleDropdown(){
        this.showFlags = !this.showFlags;
    }

    //FORM METHODS
    paxForm = this.formBuilder.group({
        passengers: this.formBuilder.array([])
    });

    get passengers(){
        return this.paxForm.controls['passengers'] as FormArray;
    }

    addPax(age: number, passenger: any | null){
        const index = this.passengers.length;

        const passengerForm = this.formBuilder.group({
            name: [passenger?.name ?? '', [Validators.required] ],
            lastName: [passenger?.lastName ?? '', [Validators.required]],
            birthDate: [this.formatDateFromBackend(passenger?.birthDate) ?? '', [this.dateValidator(age)]],
            documentType: [passenger?.documentType ?? this.enableDocuments[0].code, Validators.required],
            documentNumber: [passenger?.documentNumber ?? '', [Validators.required, Validators.minLength(7), Validators.maxLength(10), this.documentNumberValidator(this.passengers)]],
            email: [passenger?.email ?? '', [Validators.required, this.validateEmail()]],
            phonePrefix: [passenger?.phonePrefix ?? this.selectedCodeNumbers[index], Validators.required],
            phone: [passenger?.phone ?? '', [Validators.required, Validators.minLength(4), Validators.maxLength(12)]],
            emergencyContactName: [
                this.uniqueEmergencyContact && index > 0 ? '' : passenger?.emergencyContactName ?? '', 
                [this.emergencyContactValidator(index == 0)]
            ],
            emergencyContactLastName: [
                this.uniqueEmergencyContact && index > 0 ? '' : passenger?.emergencyContactLastName ?? '', 
                [this.emergencyContactValidator(index == 0)]
            ],
            emergencyContactPhonePrefix: [passenger?.emergencyContactPhonePrefix ?? this.selectedEmergencyCodeNumbers[index], Validators.required],
            emergencyContactPhone: [
                this.uniqueEmergencyContact && index > 0 ? '' : passenger?.emergencyContactPhone ?? '', 
                [this.emergencyContactValidator(index == 0), Validators.minLength(4), Validators.maxLength(12)]
            ],
            visible: [true]
        });

        this.passengers.push(passengerForm);
    }

    validateEmergencyContact(){

        if(!this.previousPassengers)
            return;

        if(this.previousPassengers.length == 1)
            return;

        const firstPax = this.previousPassengers[0];
        for(let i = 1; i < this.previousPassengers.length; i++){
            const passenger = this.previousPassengers[i];
            if(passenger.emergencyContactName == firstPax?.emergencyContactName &&
                passenger.emergencyContactLastName == firstPax?.emergencyContactLastName &&
                passenger.emergencyContactPhone == firstPax?.emergencyContactPhone){
                    this.uniqueEmergencyContact = true;
                }
                else{
                    this.uniqueEmergencyContact = false;
                    break;
                }
        }

        return;
    }

    setUniqueEmergencyContact(event$: any){
        this.uniqueEmergencyContact = event$.target.checked;
        if(this.uniqueEmergencyContact){
            this.passengers.controls.forEach((passenger, index) => {
                if(index > 0){
                    
                    passenger.get('emergencyContactName')?.setErrors(null);
                    passenger.get('emergencyContactLastName')?.setErrors(null);
                    passenger.get('emergencyContactPhone')?.setErrors(null);

                    passenger.get('emergencyContactName')?.setValue('');
                    passenger.get('emergencyContactLastName')?.setValue('');
                    passenger.get('emergencyContactPhone')?.setValue('');

                    passenger.get('emergencyContactName')?.markAsPristine({onlySelf: true});
                    passenger.get('emergencyContactLastName')?.markAsPristine({onlySelf: true});
                    passenger.get('emergencyContactPhone')?.markAsPristine({onlySelf: true});
                }
            });
        }
    }

    formatDateFromBackend(date: any) : string | null{

        if(!date){
            return null;
        }

        const dateToParse = new Date(date);

        let day = dateToParse.getDate().toString();
        let month = (dateToParse.getMonth() + 1).toString();
        let year = dateToParse.getFullYear().toString();

        const formattedDate = `${day.length == 1 ? '0' + day : day}/${month.length == 1 ? '0' + month : month}/${year}`;
        return formattedDate;
    }

    formatDateForBackend(date: string) : string {
        if(!date){
            return '';
        }

        const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        const match = datePattern.exec(date);

        if (!match) {
            return '';
        }

        const day = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1;
        const year = parseInt(match[3], 10);

        const formattedDate = new Date(year, month, day).toISOString();
        return formattedDate;
    }

    formatDate(event$: any){
        const input = event$.target as HTMLInputElement;
        let value = input.value.replace(/\D/g, ''); 
    
        if (value.length >= 8) {
            value = value.slice(0, 8); 
        }
    
        if (value.length > 2) {
            value = value.slice(0, 2) + '/' + value.slice(2);
        }
        if (value.length > 5) {
            value = value.slice(0, 5) + '/' + value.slice(5);
        }
    
        input.value = value;
    }


    submitForm() {
        const form = document.getElementById('passengerForm') as HTMLFormElement;

        if (form.checkValidity()) {
            form.requestSubmit();
        }
    }

    public onSubmit() {
        if(this.paxForm.invalid){
            this.paxForm.markAllAsTouched();
            return;
        }       

        const passengers = this.convertDataForEmit();
        this.passengerDataSubmited.emit(
            {
                paxData: passengers,
                uniqueEmergencyContact: this.uniqueEmergencyContact
            }
        );
    }

    private convertDataForEmit() : PassengerData[]{
        let passengers: PassengerData[] = [];

        this.passengers.controls.forEach(passenger => {
            let raw = passenger.getRawValue();
            let passengerData : PassengerData = {
                name: raw.name,
                surname: raw.lastName,
                nationality: "AR",
                documentType: raw.documentType,
                documentNumber: raw.documentNumber,
                birthDate: this.formatDateForBackend(raw.birthDate),
                email: raw.email,
                phonePrefix: raw.phonePrefix,
                phone: raw.phone,
                emergencyContact : {
                    name: raw.emergencyContactName,
                    surname: raw.emergencyContactLastName,
                    email: "",
                    phonePrefix: raw.emergencyContactPhonePrefix,
                    phone: raw.emergencyContactPhone
                }
            };

            passengers.push(passengerData);
        });

        return passengers;
    }

    validateEmail(): ValidatorFn {
        return (control:AbstractControl): {[key: string]: any} | null => {
            if (!control.value) {
                return {'required': true}
            }

            const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            const email = control.value.toLowerCase()
            const isValid = pattern.test(email);

            return isValid ? null : {'invalidEmail': true };
        }
    };

    dateValidator(age: number): ValidatorFn { 
        return (control: AbstractControl): { [s: string]: boolean } | null => {

            if (!control.value) {
                return { 'required': true };
            }

            const dateValue = control.value;
            const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
            const match = datePattern.exec(dateValue);

            if (!match) {
                return { invalidPattern: true };
            }

            const day = parseInt(match[1], 10);
            const month = parseInt(match[2], 10) - 1;
            const year = parseInt(match[3], 10);

            const birthDate = new Date(year, month, day);
            const tripDate = new Date(this.tripDate);
            let years = tripDate.getFullYear() - birthDate.getFullYear();
            birthDate.setFullYear(tripDate.getFullYear());
            if (tripDate < birthDate) {
                years--;
            }

            return years == age ? null : { wrongDate: true };
        };
    }
    
    documentNumberValidator(formArray: FormArray): ValidatorFn {
        return (control: AbstractControl): { [s: string]: boolean } | null => {
            if (!control.value) {
                return { 'required': true };
            }
    
            const documentGroup = control.parent;
            if (!documentGroup) {
                return null;
            }
    
            const selectElement = documentGroup.get('documentType');
            if (!selectElement) {
                return null;
            }
    
            if (selectElement.value === 'DNI') {
                const pattern = /^\d+$/;
                if(!pattern.test(control.value)){
                    control.setValue(control.value.replace(/\D/g, ''));
                }
            }
    
            const documentNumber = control.value;
            const controls = formArray.controls;
            const isDuplicate = controls.some(formGroup => {
                const num = formGroup.get('documentNumber')?.value;
                return num === documentNumber && formGroup !== documentGroup;
            });
    
            if (isDuplicate) {
                return { 'duplicateDocument': true };
            }
    
            return control.value == '' ? { 'required': true } : null;
        };
    }
    
    emergencyContactValidator(firstPax: boolean){
        return (control: AbstractControl): { [s: string]: boolean } | null => {

            if(!firstPax && this.uniqueEmergencyContact){
                return null;
            }
            
            if (!control.value) {
                return { 'required': true };
            }                                           

            return null;
        }
    }
}

interface PassengersComponentOutput{
    paxData: PassengerData[];
    uniqueEmergencyContact: boolean;
}
