<div class="p-8 md:p-12 relative">
  <p class="absolute right-5 top-2 cursor-pointer text-xl" (click)="closeModal()">&times;</p>
  <div class="grid grid-cols-1 gap-3 lg:gap-4">
      <p class="col-span-1 font-medium text-2xl text-center text-blue mb-2">Anular suscripción</p>
      <p class="col-span-1 font-medium text-lg text-center text-grey mb-2">¿Cuál es el correo electrónico que deseas dar de baja?*</p>
      <textarea rows="3" class="w-full"></textarea>
      <div class="col-span-1 text-center">
          <button class="rounded-lg bg-blue mt-4 lg:mt-0 px-8 py-2 lg:py-4 font-bold text-white disabled:bg-disabledGrey hover:bg-[#1D1191] transition-colors hover:easy-in duration-300"
              (click)="closeModal()">
              ENVIAR
          </button>
      </div>
  </div>
</div>