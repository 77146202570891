import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from "@angular/router";
import { LocalizationService } from "../../services/localization.service";
import { QuoteRoute } from "../routes/route-parser";
import { ApiService } from "../../services/api.service";
import { RouteService } from "../route.service";
import { Subdirectory } from "../../enums/subdirectory.enum";

export const RouteResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) => {

    const currentUrlParams = state.url.split('/');
    const page = currentUrlParams[currentUrlParams.length - 1];

    const router = inject(Router);
    const routeService = inject(RouteService);

    routeService.setCurrentUrl(state.url);

    var redirection = false;
    var redirectionRoute = '';

    const localizationsService = inject(LocalizationService);

    var country = route.params['country'];
    if(!country){
      country = localizationsService.getCountry();
      redirection = true;
      redirectionRoute = `/${country}`   
      router.navigate([redirectionRoute]);
    }
    else{
      redirectionRoute = `/${country}`    
    }

    //aca ya hay un pais setteado en la ruta
    if(country != localizationsService.getCountry()){
      redirection = true;
      redirectionRoute = `/${localizationsService.getCountry()}`
    }

    var settedLang = localizationsService.getLanguage();
    var defaultLang = localizationsService.getDefaultLanguageByCountry(country);
    var routeLang = route.params['lang'];

    if(routeLang){
      if(routeLang != settedLang){
        redirection = true;

        if(settedLang !== defaultLang){
          redirectionRoute += `/${settedLang}`
        }

      }
      else{
        if(settedLang === defaultLang){
          redirection = true;
        }
        else{
          redirectionRoute += `/${routeLang}`
        }
      }
      
    }
    
    if(state.url.includes('cotizador')){
      routeService.setSubdirectory(Subdirectory.QUOTE);
      const quoteRoute = localizationsService.returnRouteByLanguage(new QuoteRoute());
      if(quoteRoute != 'cotizador'){
        redirection = true;
        redirectionRoute += `/${quoteRoute}`
      }
    }

    if(state.url.includes('cotador')){
      routeService.setSubdirectory(Subdirectory.QUOTE);
      const quoteRoute = localizationsService.returnRouteByLanguage(new QuoteRoute());
      if(quoteRoute != 'cotador'){
        redirection = true;
        redirectionRoute += `/${quoteRoute}`
      }
    }

    if(state.url.includes('quote')){
      routeService.setSubdirectory(Subdirectory.QUOTE);
      const quoteRoute = localizationsService.returnRouteByLanguage(new QuoteRoute());
      if(quoteRoute != 'quote'){
        redirection = true;
        redirectionRoute += `/${quoteRoute}`
      }
    }

    if(redirection){
      router.navigate([redirectionRoute]);
    }              
};