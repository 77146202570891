import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { Theme } from "../enums/theme";

@Injectable({
    providedIn: 'root'
})
export class ThemeService{
    private style!: HTMLLinkElement;
    private cssFile: string = '';
    private themeCSSID: string = 'themeCSS';
    private renderer2!: Renderer2;

    constructor(@Inject(DOCUMENT) private document: Document){
    }

    setRenderer(renderer2: Renderer2){
        this.renderer2 = renderer2;
    }

    setTheme(theme: string) {

      if(this.cssFile === `${theme}.css`) return;
      if(!theme){
        theme = Theme.NOW;
      }  

      this.cssFile = `${theme}.css`;
      this.removeExistingThemeStyle(this.themeCSSID);
      
      // Create a link element via Angular's renderer to avoid SSR troubles
      this.style = this.renderer2.createElement('link') as HTMLLinkElement;
  
      // Set type of the link item and path to the css file
      this.renderer2.setProperty(this.style, 'rel', 'stylesheet');
      this.renderer2.setProperty(this.style, 'href', this.cssFile);
      this.renderer2.setProperty(this.style, 'id', this.themeCSSID);
  
      // Add the style to the head section
      this.renderer2.appendChild(this.document.head, this.style);
    }
  
    removeExistingThemeStyle(themeCSSID: string) {
      const themeIDHTMlElem = this.document.getElementById(themeCSSID);
      if (themeIDHTMlElem) {
        this.renderer2.removeChild(this.document.head, themeIDHTMlElem);
      }
    }
}