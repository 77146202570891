import { Component, inject, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxTippyModule, NgxTippyService } from 'ngx-tippy-wrapper';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { CommonModule } from '@angular/common';
import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { RouteService } from '../../../core/route/route.service';
import { AboutUsRoute, ContactRoute, FaqsRoute, PrivacyRoute, TermsRoute } from '../../../core/route/routes/route-parser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [CommonModule,FontAwesomeModule, NgxTippyModule],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('true => false', animate(300 + 'ms ease-in')),
      transition('false => true', animate(300 + 'ms ease-out'))
    ])
  ],
  standalone: true
})
export class HeaderComponent implements OnInit{

  @Input() home: boolean = false;
  
  @Input() showMenu: boolean = true;
  @Input() showQuoteButton: boolean = true;
  @Input() showLogin: boolean = true;
  @Input() showLangs: boolean = true;

  private routeService = inject(RouteService);

  faBars = faBars;
  prd = true;

  flags= [
    {
      text: 'AR',
      img: '../../../assets/img/r-arg.png'
    }
  // ,
  // {
  //   text: 'BR',
  //   img: '../../../assets/img/r-bra.png'
  // }
  ];

  selectedFlag = this.flags[0];

  isMobileMenuOpen = false;

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  constructor(private tippyService: NgxTippyService) {}

  ngOnInit() {
  }

  selectFlag(flag:any) {
    this.selectedFlag = flag;
    this.tippyService.hideAll();
  }

  redirectToStatic(route: string){

    this.routeService.setSubdirectory(null);

    switch(route){
      case 'faq':
          return this.routeService.resolveRedirection(new FaqsRoute());
      case 'about-us':
          return this.routeService.resolveRedirection(new AboutUsRoute());
      case 'contact':
          return this.routeService.resolveRedirection(new ContactRoute());
      case 'privacy':
          return this.routeService.resolveRedirection(new PrivacyRoute());
      case 'terms':
          return this.routeService.resolveRedirection(new TermsRoute());
    }
  }
}
