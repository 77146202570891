import { AboutUsRoute, ContactRoute, FaqsRoute, IRoute, PrivacyRoute, TermsRoute } from "../../route/routes/route-parser";

export const Tags : MetaPage[] = [
    {
        page: null,
        tags: [
            { title: 'Now Assistance - Asistencia al Viajero y Seguro de Viaje Internacional', name: null, property: null, content: '' },
            { title: null, name: 'description', property: null, content: 'Contratá la mejor asistencia al viajero con Now Assistance. Seguros de salud en el extranjero, seguro de viaje internacional y asistencia al viajero barata. ¡Hay promociones y descuentos!' },
            { title: null, name: 'keywords', property: null, content: 'Asistencia al viajero, cobertura de salud en el extranjero, seguro viaje, seguro de viaje internacional, seguro de viajero, seguro médico para viajar, promo seguro viaje, descuento seguro viaje, seguro viaje de trabajo, mejor asistencia al viajero, seguro al viajero barato, seguro médico internacional, seguro médico internacional precio, asistencia al viajero barata, asistencia al viajero ofertas, seguro de salud, seguro de salud para viajar' },
            { title: null, name: 'author', property: null, content: 'Now Assistance' },
            { title: null, name: 'viewport', property: null, content: 'width=device-width, initial-scale=1.0' },
            { title: null, name: null, property: 'og:type', content: 'website' },
            { title: null, name: null, property: 'og:title', content: 'Now Assistance - Asistencia al Viajero y Seguro de Viaje Internacional' },
            { title: null, name: null, property: 'og:description', content: 'Contratá la mejor asistencia al viajero con Now Assistance. Seguros de salud en el extranjero, seguro de viaje internacional y asistencia al viajero barata. ¡Con promociones y descuentos!' },
            { title: null, name: null, property: 'og:url', content: 'https://nowassistance.com/ar/' },
            { title: null, name: null, property: 'og:image', content: 'https://www.nowassistance.com/ar/images/og-image.jpg' },
            { title: null, name: null, property: 'og:site_name', content: 'Now Assistance' },
            { title: null, name:'twitter:card', property: null, content: 'summary_large_image' },
            { title: null, name:'twitter:title', property: null, content: 'Now Assistance - Asistencia al Viajero y Seguro de Viaje Internacional' },
            { title: null, name:'twitter:description', property: null, content: 'Contratá la mejor asistencia al viajero con Now Assistance. Seguros de salud en el extranjero, seguro de viaje internacional y asistencia al viajero barata. ¡Con promociones y descuentos!' },
            { title: null, name:'twitter:image', property: null, content: 'https://www.nowassistance.com/ar/images/twitter-image.jpg' },
        ],
        canonical: 'https://nowassistance.com/ar/'
    },
    {
        page: new FaqsRoute,
        tags: [
            { title: 'Preguntas Frecuentes - Now Assistance: resolvé tus dudas sobre asistencia al viajero', name: null, property: null, content: '' },
            { title: null, name: 'description', property: null, content: 'Encontrá respuestas a las preguntas más frecuentes sobre la asistencia al viajero, seguros de viaje internacionales, y cobertura médica con Now Assistance.' },
            { title: null, name: 'keywords', property: null, content: 'Preguntas frecuentes, FAQ, asistencia al viajero, seguro de viaje, cobertura médica, seguro internacional, dudas sobre seguro de viaje, asistencia al viajero internacional, preguntas sobre seguro médico para viajar' },
            { title: null, name: 'author', property: null, content: 'Now Assistance' },
            { title: null, name: 'viewport', property: null, content: 'width=device-width, initial-scale=1.0' },
            { title: null, name: null, property: 'og:type', content: 'website' },
            { title: null, name: null, property: 'og:title', content: 'Preguntas Frecuentes - Now Assistance: resolvé tus dudas sobre asistencia al viajero' },
            { title: null, name: null, property: 'og:description', content: 'Encontrá respuestas a las preguntas más frecuentes sobre la asistencia al viajero, seguros de viaje internacionales, y cobertura médica con Now Assistance.' },
            { title: null, name: null, property: 'og:url', content: 'https://nowassistance.com/ar/' },
            { title: null, name: null, property: 'og:image', content: 'https://www.nowassistance.com/ar/images/og-image.jpg' },
            { title: null, name: null, property: 'og:site_name', content: 'Now Assistance' },
            { title: null, name:'twitter:card', property: null, content: 'summary_large_image' },
            { title: null, name:'twitter:title', property: null, content: 'Preguntas Frecuentes - Now Assistance: resolvé tus dudas sobre asistencia al viajero' },
            { title: null, name:'twitter:description', property: null, content: 'Encontrá respuestas a las preguntas más frecuentes sobre la asistencia al viajero, seguros de viaje internacionales, y cobertura médica con Now Assistance.' },
            { title: null, name:'twitter:image', property: null, content: 'https://www.nowassistance.com/ar/images/twitter-image.jpg' },
        ],
        canonical: 'https://nowassistance.com/ar/faq'
    },
    {
        page: new AboutUsRoute,
        tags: [
            { title: 'Quiénes Somos - Now Assistance: Expertos en Asistencia al Viajero', name: null, property: null, content: '' },
            { title: null, name: 'description', property: null, content: 'Conocé más sobre Now Assistance, líderes en asistencia al viajero. Nuestro compromiso es ofrecerte el mejor seguro de viaje internacional y cobertura médica en el extranjero.' },
            { title: null, name: 'keywords', property: null, content: 'Quiénes somos, Now Assistance, empresa de asistencia al viajero, cobertura de salud en el extranjero, seguro internacional, seguro médico, asistencia al viajero, seguro de viaje internacional, seguro médico para viajar, mejor seguro de viaje' },
            { title: null, name: 'author', property: null, content: 'Now Assistance' },
            { title: null, name: 'viewport', property: null, content: 'width=device-width, initial-scale=1.0' },
            { title: null, name: null, property: 'og:type', content: 'website' },
            { title: null, name: null, property: 'og:title', content: 'Quiénes Somos - Now Assistance: Expertos en Asistencia al Viajero' },
            { title: null, name: null, property: 'og:description', content: 'Conocé más sobre Now Assistance, líderes en asistencia al viajero. Nuestro compromiso es ofrecerte el mejor seguro de viaje internacional y cobertura médica en el extranjero.' },
            { title: null, name: null, property: 'og:url', content: 'https://nowassistance.com/ar/' },
            { title: null, name: null, property: 'og:image', content: 'https://www.nowassistance.com/ar/images/og-image.jpg' },
            { title: null, name: null, property: 'og:site_name', content: 'Now Assistance' },
            { title: null, name:'twitter:card', property: null, content: 'summary_large_image' },
            { title: null, name:'twitter:title', property: null, content: 'Quiénes Somos - Now Assistance: Expertos en Asistencia al Viajero' },
            { title: null, name:'twitter:description', property: null, content: 'Conocé más sobre Now Assistance, líderes en asistencia al viajero. Nuestro compromiso es ofrecerte el mejor seguro de viaje internacional y cobertura médica en el extranjero.' },
            { title: null, name:'twitter:image', property: null, content: 'https://www.nowassistance.com/ar/images/twitter-image.jpg' },
        ],
        canonical: 'https://nowassistance.com/ar/quienes-somos/'
    },
    {
        page: new ContactRoute,
        tags: [
            { title: 'Contacto - Now Assistance: estamos para ayudarte en asistencia al viajero', name: null, property: null, content: '' },
            { title: null, name: 'description', property: null, content: 'Contactá a Now Assistance para resolver tus dudas sobre asistencia al viajero, seguros internacionales y coberturas médicas en el extranjero. ¡Estamos disponibles para vos!' },
            { title: null, name: 'keywords', property: null, content: 'Contacto, asistencia al viajero, contacto seguro de viaje, contacto Now Assistance, asistencia médica internacional, consulta seguro de viaje, cobertura de salud en el extranjero' },
            { title: null, name: 'author', property: null, content: 'Now Assistance' },
            { title: null, name: 'viewport', property: null, content: 'width=device-width, initial-scale=1.0' },
            { title: null, name: null, property: 'og:type', content: 'website' },
            { title: null, name: null, property: 'og:title', content: 'Contacto - Now Assistance: estamos para ayudarte en asistencia al viajero' },
            { title: null, name: null, property: 'og:description', content: 'Contactá a Now Assistance para resolver tus dudas sobre asistencia al viajero, seguros internacionales y coberturas médicas en el extranjero. ¡Estamos disponibles para vos!' },
            { title: null, name: null, property: 'og:url', content: 'https://nowassistance.com/ar/' },
            { title: null, name: null, property: 'og:image', content: 'https://www.nowassistance.com/ar/images/og-image.jpg' },
            { title: null, name: null, property: 'og:site_name', content: 'Now Assistance' },
            { title: null, name:'twitter:card', property: null, content: 'summary_large_image' },
            { title: null, name:'twitter:title', property: null, content: 'Contacto - Now Assistance: estamos para ayudarte en asistencia al viajero' },
            { title: null, name:'twitter:description', property: null, content: 'Contactá a Now Assistance para resolver tus dudas sobre asistencia al viajero, seguros internacionales y coberturas médicas en el extranjero. ¡Estamos disponibles para vos!' },
            { title: null, name:'twitter:image', property: null, content: 'https://www.nowassistance.com/ar/images/twitter-image.jpg' },
        ],
        canonical: 'https://nowassistance.com/ar/contacto/'
    },
    {
        page: new PrivacyRoute,
        tags: [
            { title: 'Política de Privacidad - Now Assistance: protección de tus datos personales', name: null, property: null, content: '' },
            { title: null, name: 'description', property: null, content: 'Conocé la Política de Privacidad de Now Assistance. Garantizamos la protección y el uso adecuado de tus datos personales conforme a la normativa vigente.' },
            { title: null, name: 'keywords', property: null, content: 'Política de privacidad, protección de datos, privacidad de datos, uso de datos personales, normativa de privacidad, Now Assistance, política de privacidad Now Assistance' },
            { title: null, name: 'author', property: null, content: 'Now Assistance' },
            { title: null, name: 'viewport', property: null, content: 'width=device-width, initial-scale=1.0' },
            { title: null, name: null, property: 'og:type', content: 'website' },
            { title: null, name: null, property: 'og:title', content: 'Now Assistance - Asistencia al Viajero y Seguro de Viaje Internacional' },
            { title: null, name: null, property: 'og:description', content: 'Conocé la Política de Privacidad de Now Assistance. Garantizamos la protección y el uso adecuado de tus datos personales conforme a la normativa vigente.' },
            { title: null, name: null, property: 'og:url', content: 'https://nowassistance.com/ar/' },
            { title: null, name: null, property: 'og:image', content: 'https://www.nowassistance.com/ar/images/og-image.jpg' },
            { title: null, name: null, property: 'og:site_name', content: 'Now Assistance' },
            { title: null, name:'twitter:card', property: null, content: 'summary_large_image' },
            { title: null, name:'twitter:title', property: null, content: 'Now Assistance - Asistencia al Viajero y Seguro de Viaje Internacional' },
            { title: null, name:'twitter:description', property: null, content: 'Conocé la Política de Privacidad de Now Assistance. Garantizamos la protección y el uso adecuado de tus datos personales conforme a la normativa vigente.' },
            { title: null, name:'twitter:image', property: null, content: 'https://www.nowassistance.com/ar/images/twitter-image.jpg' },
        ],
        canonical: 'https://nowassistance.com/ar/privacidad/'
    },
    {
        page: new TermsRoute,
        tags: [
            { title: 'Términos y Condiciones - Now Assistance: uso de servicios de asistencia al viajero', name: null, property: null, content: '' },
            { title: null, name: 'description', property: null, content: 'Leé los Términos y Condiciones de Now Assistance para conocer las normativas de uso de nuestros servicios de asistencia al viajero y seguros de viaje internacionales.' },
            { title: null, name: 'keywords', property: null, content: 'Términos y condiciones, normativa de servicios, términos de uso, condiciones de uso, Now Assistance, seguro de viaje, asistencia al viajero, políticas de servicio' },
            { title: null, name: 'author', property: null, content: 'Now Assistance' },
            { title: null, name: 'viewport', property: null, content: 'width=device-width, initial-scale=1.0' },
            { title: null, name: null, property: 'og:type', content: 'website' },
            { title: null, name: null, property: 'og:title', content: 'Términos y Condiciones - Now Assistance: uso de servicios de asistencia al viajero' },
            { title: null, name: null, property: 'og:description', content: 'Leé los Términos y Condiciones de Now Assistance para conocer las normativas de uso de nuestros servicios de asistencia al viajero y seguros de viaje internacionales.' },
            { title: null, name: null, property: 'og:url', content: 'https://nowassistance.com/ar/' },
            { title: null, name: null, property: 'og:image', content: 'https://www.nowassistance.com/ar/images/og-image.jpg' },
            { title: null, name: null, property: 'og:site_name', content: 'Now Assistance' },
            { title: null, name:'twitter:card', property: null, content: 'summary_large_image' },
            { title: null, name:'twitter:title', property: null, content: 'Términos y Condiciones - Now Assistance: uso de servicios de asistencia al viajero' },
            { title: null, name:'twitter:description', property: null, content: 'Leé los Términos y Condiciones de Now Assistance para conocer las normativas de uso de nuestros servicios de asistencia al viajero y seguros de viaje internacionales.' },
            { title: null, name:'twitter:image', property: null, content: 'https://www.nowassistance.com/ar/images/twitter-image.jpg' },
        ],
        canonical: 'https://nowassistance.com/ar/condiciones/'
    }
]


export interface MetaTag {
    title: string | null;
    name: string | null;
    property: string | null;
    content: string;
}

export interface MetaPage {
    page: IRoute | null;
    tags: MetaTag[];
    canonical: string;
}
