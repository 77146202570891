import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noYearDate',
  standalone: true
})
export class NoYearDatePipe implements PipeTransform {

  transform(value: Date | string | number): string {
    if (!value) return '';

    const date = new Date(value);

    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('es-AR', options).format(date);
  }

}