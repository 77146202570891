import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VendeNowModel } from '../../../feature/quote/models/vende-now.model';
import { ApiService } from '../../../core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class VendeNowService {
  private apiService = inject(ApiService);

  constructor() { }

  sendVendeNowRequest(request: VendeNowModel): Observable<any> {
    return this.apiService.post('VendeNow', request);
  }
}
