import { Component, OnInit } from '@angular/core';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../../../shared/components/header/header.component';
import { FooterComponent } from '../../../../shared/components/footer/footer.component';

@Component({
  selector: 'app-clean-home',
  standalone: true,
  templateUrl: './clean-home.component.html',
  styleUrls: ['./clean-home.component.scss'],
  imports: [CommonModule, LoaderComponent, HeaderComponent, FooterComponent]
})
export class CleanHomeComponent implements OnInit {

  protected dataIsReady: boolean = false;

  constructor() { }

  ngOnInit() {
    this.dataIsReady = true;
  }

}
