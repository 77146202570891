import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[numberOnly]',
})
export class NumberOnlyDirective {

  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home' ];

  constructor(private el: ElementRef) {  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const regex = /^\d+$/;

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (!regex.test(key)) {
      event.preventDefault();    
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedInput = clipboardData.getData('text');

    const regex = /^\d+$/;
    if (!regex.test(pastedInput)) {
      event.preventDefault();
    }
  }
}