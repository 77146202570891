<aside *ngIf="purchaseDetails">
    <div class="rounded-t-lg bg-grey p-4 lg:p-4 text-lg lg:text-2xl font-bold text-white">
    <p>now <br class="hidden lg:block" /> {{ selectedProduct.name | productName }}</p>
    </div>
    <div class="p-4 lg:p-4 text-grey">
    <p class="mb-4 text-xs font-medium text-blue flex justify-between" (click)="showResumeDetail()">DETALLES DE TU VIAJE
        <img src="../../../../assets/img/icons/caret-down.svg" *ngIf="!resumeDetails" alt=""
            class="block lg:hidden w-[20px] my-auto">
        <img src="../../../../assets/img/icons/caret-up.svg" *ngIf="resumeDetails" alt=""
            class="block lg:hidden w-[20px] my-auto">
    </p>
    <div *ngIf="resumeDetails" class="block lg:hidden text-sm">
        <div class="mb-4 font-medium">
            <img src="../../../../assets/img/icons/location.png" alt=""
                class="w-[15px] my-auto inline-block mr-1">
            {{countriesLabel}}
        </div>
        <div class="mb-4 font-medium">
            <img src="../../../../assets/img/icons/calendar.png" alt=""
                class="w-[15px] my-auto inline-block mr-1">
                {{ quote.starting | date: "longDate" }} - {{ quote.ending | date: "longDate" }}
            <span class="font-normal">| {{days}} días</span>
        </div>
        <div class="mb-4 font-medium">
            <img src="../../../../assets/img/icons/pasajero.png" alt=""
                class="w-[15px] my-auto inline-block mr-1">
            {{viajeros}} {{viajeroLabel}}
            <p class="float-right cursor-pointer font-bold text-grey underline underline-offset-4 text-sm lg:text-base lg:underline-offset-8"
                (click)="showDetails(false)">
                Ver detalle</p>
        </div>
    </div>
    <div class="hidden lg:block text-sm">
        <div class="mb-4 font-medium">
            <img src="../../../../assets/img/icons/location.png" alt=""
                class="w-[15px] my-auto inline-block mr-1">
            {{countriesLabel}}
        </div>
        <div class="mb-4 font-medium">
            <img src="../../../../assets/img/icons/calendar.png" alt=""
                class="w-[15px] my-auto inline-block mr-1">
                {{ quote.starting | date: "longDate" }} - {{ quote.ending | date: "longDate" }}
            <span class="font-normal">| {{days}} días</span>
        </div>
        <div class="mb-4 font-medium">

                <img src="../../../../assets/img/icons/pasajero.png" alt="" class="w-[15px] my-auto inline-block mr-1">
                {{viajeros}} {{viajeroLabel}}

                <p class="lg:block xl:float-right lg:mt-4 xl:mt-0 cursor-pointer font-bold text-grey underline underline-offset-4 text-sm lg:text-base lg:underline-offset-8"
                    (click)="showDetails(false)">
                    Ver detalle
                </p>
            
        </div>
    </div>
    <hr class="my-2 border-black" [ngClass]="{'hidden' : paxPage}"/>
    <div [ngClass]="{'hidden' : !paxPage}">
        <p class="font-medium text-sm text-blue">Cupón de descuento</p>
            <div class="flex rounded-lg">
                <input type="text"
                        class="block w-full text-xs rounded-s-lg border-y border-l border-grey px-2 py-2 focus-visible:border-y focus-visible:border-l focus-visible:border-r-0"
                        placeholder="Agregar Código" 
                        [(ngModel)]="cupon"
                        [disabled]="discountValid"
                    />
                <button type="button"
                    class="inline-flex items-center justify-center gap-x-2 rounded-e-md border bg-blue disabled:bg-[#D5D5D5] disabled:border-[#D5D5D5] hover:bg-[#1D1191] transition-colors hover:easy-in duration-300 px-2 py-2 text-sm font-medium text-white lg:border-blue"
                        (click)="validateDiscount()" [disabled]="discountValid || !cupon"  
                    >
                    Verificar
                </button>
            </div>
            <p *ngIf="!discountValid && discountApplied" class="text-xs text-red mt-2">
                Código incorrecto
            </p>
            <p *ngIf="discountValid && discountApplied" class="text-xs text-[#5CA570] mt-2">
                Código aplicado
            </p>
    </div>
    <!-- <hr class="my-2 border-black" /> -->
    <div class="hidden lg:flex xl:hidden items-end justify-between text-blue mt-2 ">
        <div>
            <p *ngIf="discountValid && discountApplied" class="text-sm">Subtotal</p>
            <p *ngIf="discountValid && discountApplied" class="text-sm">Dto {{ discount }}%</p>
            <p class="font-bold text-sm">FINAL</p>
        </div>
        <div class="text-right">
            <p *ngIf="discountValid && discountApplied" class="text-sm">${{selectedProduct.price | number : '1.2-2'}}</p>
            <p *ngIf="discountValid && discountApplied" class="text-sm">-${{ discountPrice | number : '1.2-2'}}</p>
            <p class="font-bold text-sm">${{ finalPrice | number : '1.2-2'}}</p>
        </div>
    </div>

    <div class="flex lg:hidden xl:flex items-end justify-between text-blue mt-2">
        <div>
            <p *ngIf="discountValid && discountApplied" class="text-xs">Subtotal</p>
            <p *ngIf="discountValid && discountApplied" class="text-xs">Descuento {{ discount }}%</p>
            <p class="font-bold text-base">PRECIO FINAL</p>
        </div>
        <div class="text-right">
            <p *ngIf="discountValid && discountApplied" class="text-xs">{{selectedProduct.currency}} {{selectedProduct.price | number : '1.2-2'}}</p>
            <p *ngIf="discountValid && discountApplied" class="text-xs">- {{ selectedProduct.currency }} {{ discountPrice | number : '1.2-2'}}</p>
            <p class="font-bold">{{ selectedProduct.currency }} {{ finalPrice | number : '1.2-2'}}</p>
        </div>
    </div>
    </div>
</aside>