<div class="p-4 w-full relative">
  <p class="absolute right-5 top-2 cursor-pointer z-10" (click)="closeModal()">x</p>
  <div class="md:flex md:justify-center">
    <div #datepicker hidden></div>
  </div>
  <div class="md:mt-4 grid grid-cols-1 gap-2 text-xs md:grid-cols-2 px-[10px]">
    <div class="grid grid-cols-2 gap-2">
      <div class="mb-2 col-span-1">
        <p>Inicio</p>
        @if(startDate) {
        <p class="font-bold">{{ startDate | date: "longDate" }}</p>
        } @else {
        <p>-</p>
        }
      </div>
      <div class="mb-2 col-span-1">
        <p>Fin</p>
        @if(endDate) {
        <p class="font-bold">{{ endDate | date: "longDate" }}</p>
        } @else {
        <p>-</p>
        }
      </div>
      <div class="text-blue mb-2  col-span-2">
        <p>Cobertura</p>
        @if(totalDays) {
        <p class="font-bold">{{ totalDays }} días</p>
        } @else {
        <p>-</p>
        }
      </div>
    </div>
  
    <div class="flex justify-end items-center gap-2">
      <button class="w-1/2 rounded-lg border-2 border-blue px-8 py-2 font-bold text-blue md:w-auto"
        (click)="deleteDates()">

        BORRAR
      </button>
      <button class="w-1/2 rounded-lg bg-blue px-8 py-2 font-bold text-white md:w-auto disabled:bg-[#D5D5D5]" (click)="closeModal()" [disabled]="buttonDisabled">
        APLICAR
      </button>
    </div>
  </div>
</div>
