<app-header 
    [showQuoteButton]="false" 
    [showLogin]="false" 
    [showLangs]="false" 
>
</app-header>
<div class="relative px-4 py-8 lg:px-24 2xl:px-40 text-left lg:text-justify text-grey lg:min-h-[550px] 2xl:min-h-[700px]">
    <img src="../../assets/img/politicas/misc-i.png" class="hidden lg:block absolute left-0 top-10">
    <img src="../../assets/img/politicas/misc-d.png" class="hidden lg:block absolute right-0 top-10">
    <h1 class="text-blue text-3xl mb-4 font-bold">Políticas de Privacidad </h1>
    <p class="mb-4 font-bold">La seguridad de nuestros navegantes siempre es nuestra prioridad. Nos comprometemos a proteger tu información personal y tu privacidad.</p>
    
    <p class="mb-4">
        A través de la presente le informamos que el responsable de tratamiento de los Datos Personales de los Usuarios es <b>NOW ASSISTANCE</b> S.A, CUIT 30-71854697-0, con domicilio en Olazábal 5470, 7°B - Ciudad Autónoma de Buenos Aires, República Argentina.
    </p>
    <p class="mb-4">
        Ponemos a disposición a los Usuarios y Clientes del Sitio Web, acerca de la “<b>Política de Privacidad</b>”, que aplicará en el tratamiento de los datos personales del Usuario y/o Cliente al acceder y utilizar el Sitio Web de <b>NOW ASSISTANCE</b> y/o adquirir y utilizar los servicios de asistencia al viajero.
    </p>
    <p class="mb-4">
        A fin de cumplir con la prestación de los servicios, <b>NOW ASSISTANCE</b> recolecta y procesa información sobre “<b>Usuarios</b>”. 
    </p>
    <p class="mb-4">
        <b>NOW ASSISTANCE</b> toma las debidas precauciones y recaudos para proteger la información proporcionada por los Usuarios, adoptando las medidas técnicas y organizativas que resulten necesarias para garantizar la seguridad y confidencialidad de sus datos personales.
    </p>
    <p class="mb-4">
        Los Usuarios que ingresen al sitio web de <b>NOW ASSISTANCE,</b> podrán y/o deberán  proporcionar Datos Personales dependiendo de las herramientas y/o servicios que utilicen o adquieran.
    </p>
    <p class="mb-4">
        Los Datos Personales brindados deben ser completos, actualizados y exactos. Estos datos serán destruidos y/o eliminados cuando estos hayan dejado de ser necesarios y/o pertinentes para los fines declarados en la presente, de acuerdo con lo dispuesto por la Ley 25.326 de Protección de Datos Personales.
    </p>
    <div class="mb-4">
        Los datos que los Usuarios proporcionan directamente a <b>NOW ASSISTANCE</b> tendrán el fin de:<br/>
            <p class="ml-4"><span class="w-8">(i)</span> Suscribirse al Newsletter informativo de marketing, novedades y promociones; </p>
            <p class="ml-4"><span class="w-8">(ii)</span> Adquirir un Voucher de asistencia al viajero;</p> 
            <p class="ml-4"><span class="w-8">(iii)</span> Solicitar asistencia;</p>
            <p class="ml-4"><span class="w-8">(iv)</span> Postularse para trabajar en <b>NOW ASSISTANCE</b>;</p>
            <p class="ml-4"><span class="w-8">(v)</span> Solicitar registro de proveedores y prestadores; y</p>
            <p class="ml-4"><span class="w-8">(vi)</span> Solicitar cotizaciones o asesoramiento de nuestros productos. Al hacerlo podemos solicitar los siguientes Datos Personales, como nombre, apellido, dirección de correo electrónico, código postal, nacionalidad, teléfono, contacto de emergencia.</p>

    </div>
    <p class="mb-4">
        Los Usuarios facilitan indirectamente la siguiente información: 
    </p>
    <p class="mb-4 ml-4">
        • Datos del dispositivo o información del ordenador: <b>NOW ASSISTANCE</b> almacena los datos del dispositivo o del ordenador que el Usuario y/o el Cliente utiliza para acceder a los Servicios.<br/>
        • Datos derivados de las “cookies”: las Cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del usuario y al navegar en una página web específica, que permiten recordar al servidor de Internet, algunos datos sobre este usuario, entre ellos, sus preferencias para la visualización de las páginas en ese servidor.
    </p>
    <p class="mb-4 font-bold underline text-blue">Cookies</p>
    <p class="mb-4">
        La mayoría de los navegadores de internet admiten cookies; pero los usuarios pueden configurarlos para que no admitan determinados tipos de cookies o cookies específicas. Los usuarios también pueden eliminar las cookies en cualquier momento.<br/>
        <b>NOW ASSISTANCE</b> utiliza Cookies en su sitio web, para ofrecer y optimizar sus servicios y recopilar información y para asegurar el correcto funcionamiento del Sitio Web, almacenar sus preferencias, conocer su experiencia de navegación, recopilar información sobre los servicios ofrecidos, entre otras cosas.<br/>
        El Usuario y/o Cliente dispone de herramientas para controlar las cookies, como controles de su explorador o servicios de terceros, que le permitirán detectar las cookies y gestionar la desactivación. El Usuario y/o Cliente puede elegir navegar en nuestro sitio web sin aceptar las cookies. No obstante, al desactivarlas, es posible que no pueda utilizar algunos servicios o características del sitio.<br/>
        <b>NOW ASSISTANCE</b> podrá recolectar datos sensibles, en el desarrollo de sus Servicios. Se entiende por Datos Sensibles, los datos que revelan origen racial y étnico, opiniones políticas, convicciones religiosas, filosóficas o morales, afiliación sindical e información referente a la salud o a la vida sexual.<br/>
        Se le hace saber expresamente que entre los Datos Personales que se le requerirá al Usuario para la prestación de los servicios de asistencia al viajero podremos requerir Datos Sensibles, como datos de salud, síntomas, afecciones, clínicos, médicos, personales y demás, los cuales serán tratados conforme con los principios señalados en la Ley de Protección de Datos Personales, guardando la confidencialidad y la seguridad de los datos. Los Usuarios no están obligados a suministrar datos de esta naturaleza. En este sentido, ninguna actividad podrá estar sujeta a que otorgue su autorización para el tratamiento de datos sensibles. No obstante, elegir no autorizar su tratamiento podrá traer inconvenientes en el funcionamiento de ciertos tramites y procedimientos y/o servicios contratados.
    </p>
    <p class="mb-4">
        Los Usuarios se comprometen a introducir datos reales, actualizados y veraces. A su vez, será el único responsable de los daños y perjuicios que <b>NOW ASSISTANCE</b> y/o terceras partes pudieran sufrir como consecuencia de la falta de veracidad, inexactitud, falta de vigencia y/o autenticidad de cualquiera de los datos provistos.<br/>
        <b>NOW ASSISTANCE</b> utiliza los datos que recolecta de los Usuarios para prestar los Servicios que soliciten a través de su Sitio Web, sólo para fines específicos y limitados. Esta información podrá ser procesada luego de obtenido el <b>consentimiento explícito</b> de los Titulares de Datos. Durante la ejecución del Servicio. Los Datos Personales podrán tratarse para los siguientes fines:
    </p>
    <p class="mb-4 ml-4">
        • Para brindarle al Cliente los servicios de asistencia al viajero.<br/>
        • Para tratar y responder consultas de los Usuarios y Clientes<br/>
        • Para comunicarle información, suscripción al Newsletter u otras comunicaciones de marketing.<br/>
        • Para autenticar la identidad de los individuos que se ponen en contacto con <b>NOW ASSISTANCE</b> por teléfono u otras vías.
    </p>
    <!-- <p class="mb-4">
        <b>NOW ASSISTANCE</b> podrá enviar, al correo electrónico u otros medios de comunicación provistos como contacto de los Usuarios y Clientes, mensajes promocionales y/u ofertas relativas a los Servicios que ofrece y que pudieran interesar al Usuario y Cliente. <b>NOW ASSISTANCE</b> podrá medir y personalizar dicha publicidad en función de las preferencias de los Usuarios y Clientes de <b>NOW ASSISTANCE</b>. Si el Usuario y/o Cliente desea no recibir o dejar de recibir la citada información y/o comunicaciones comerciales, podrá solicitarlo <button class="text-blue hover:underline" (click)="openModal()">aquí</button>, y consecuentemente, <b>NOW ASSISTANCE</b> cesará en el envío de la información citada en cuanto se entienda notificado.
    </p> -->
    <p class="mb-4">
        El tratamiento de estos datos por parte de <b>NOW ASSISTANCE</b> es necesario para poder cumplir con la relación contractual que se establece entre las partes. Si el Titular del Dato no los facilita, los Servicios solicitados podrán verse obstaculizados y/o no estar disponibles, generando que <b>NOW ASSISTANCE</b> no pueda prestarlos correctamente. <b>NOW ASSISTANCE,</b> en ningún momento se excederá de la finalidad y ámbito para los cuales los datos fueron recolectados.
    </p>
    <p class="mb-4">
        <b>NOW ASSISTANCE</b> podrá compartir los Datos Personales entre las distintas entidades del mismo grupo y/o controladas o vinculadas a <b>NOW ASSISTANCE</b>. Asimismo, los datos podrán ser transferidos internacionalmente siempre que dichos datos sean enviados a países con niveles de protección adecuados y/o se incluyan cláusulas correspondientes de protección de los datos en la relación de <b>NOW ASSISTANCE</b> con dicho tercero, según lo dispuesto en la Ley de Protección de Datos Personales y la legislación aplicable en materia de protección de datos y transferencia internacional de datos personales.
    </p>
    <p class="mb-4">
        <b>NOW ASSISTANCE</b> podrá transferir los Datos Personales de los Usuarios y/o Clientes a terceros proveedores. Los datos podrán ser compartidos con proveedores externos vinculados con <b>NOW ASSISTANCE,</b> para que actúen en nombre de <b>NOW ASSISTANCE,</b> a fin de que presten un servicio determinado siguiendo nuestra Política de Privacidad, como ser: 
    </p>
    <div class="ml-4 mb-4">
        <p><span class="w-8">(i)</span> Proveedores de servicios en la nube, y proveedores de servicios tecnológicos en general,</p>
        <p><span class="w-8">(ii)</span> Proveedores de central operativa o centros de atención al cliente,</p>
        <p><span class="w-8">(iii)</span> Agencias de publicidad o marketing,</p> 
        <p><span class="w-8">(iv)</span> Prestadores médicos,</p>
        <p><span class="w-8">(v)</span> Proveedores para el procesamiento de pagos.</p>
    </div>
    <p class="mb-4">
        Los proveedores externos de servicios sólo acceden a la información estrictamente necesaria para prestar los servicios acordados y no pueden usar los Datos Personales para finalidades distintas a las que les indique <b>NOW ASSISTANCE</b> en el debido contrato de procesamiento.
    </p>
    <p class="mb-4 font-bold underline text-blue">Derechos de los Usuarios</p>
    <p class="mb-4">
        Conforme se establece en la Ley de Protección de Datos Personales, <b>NOW ASSISTANCE</b> reconoce los derechos de acceso, actualización, cancelación, rectificación y eliminación de los Datos Personales proporcionados por los Usuarios. De esta manera, los Usuarios que hubieran brindado sus datos, podrán, en cualquier momento:
    </p>
    <div class="ml-4 mb-4">
        <p><span class="w-8">i)</span> Acceder a sus datos personales;</p>
        <p><span class="w-8">ii)</span> Solicitar la rectificación de datos inexactos;</p>
        <p><span class="w-8">iii)</span> Solicitar su supresión; </p>
    </div>
    <p class="mb-4">
        <b>NOW ASSISTANCE</b> respeta su derecho a la privacidad. Esto significa que usted como Titular de los Datos Personales podrá ejercer los siguientes derechos.
    </p>
    <p class="mb-4">
        En caso de retirar el consentimiento u oponerse al tratamiento, los datos se bloquearán y dejarán de tratarse. <b>NOW ASSISTANCE,</b> conservará los datos por el tiempo que sea necesario para cumplir con los fines descriptos en la presente. El periodo durante el cual conservamos los datos de los Usuarios, está determinado por el tipo de dato, los fines para los cuales fueron recolectados y por los requisitos legales y reglamentarios.
    </p>
    <p class="mb-4">
        Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de la información personal facilitada, y se comprometen a mantenerla debidamente actualizada. <b>NOW ASSISTANCE</b> no garantiza a los Usuarios la certeza de los datos consignados por el resto de los Usuarios. Esto no obsta a la obligación de <b>NOW ASSISTANCE</b> de rectificar, actualizar o eliminar los datos incorrectos. Tampoco exime a <b>NOW ASSISTANCE</b> del cumplimiento de las demás obligaciones dispuestas en la Ley de Protección de  Datos Personales  y lo dispuesto en la presente Política de Privacidad cuando fuera requerido.
    </p>
    <p class="mb-4">
        Se le hace saber que, en el supuesto caso de ser objeto de decisiones tomadas únicamente por medios automatizados, incluyendo las decisiones relacionadas a la elaboración de perfiles personales, profesionales, de consumo y de crédito que puedan afectar sus intereses, usted como Titular de Datos puede solicitar una revisión de dichas decisiones.
    </p>
    <p class="mb-4">
        <b>NOW ASSISTANCE</b> adopta y lleva a cabo las medidas administrativas, físicas y técnicas que considera necesarias y suficientes para respetar los términos y condiciones de la presente Política de Privacidad y mantener la confidencialidad de los Datos Personales por sí mismo o por terceros con los que guarde alguna relación contractual para prestar los Servicios solicitados por el Titular de los Datos.
    </p>
    <p class="mb-4 font-bold underline text-blue">Modificaciones</p>
    <p class="mb-4">
        En ningún caso <b>NOW ASSISTANCE</b> modificará las políticas ni prácticas para hacerlas menos eficaces en la protección de los datos personales de los Usuarios y/o Clientes
    </p>
    <p class="mb-4">
        Actualizaremos la presente Política de Privacidad cuando sea necesario para reflejar los cambios en nuestros Servicios. <b>NOW ASSISTANCE</b> enviará a los Usuarios y/o Clientes los avisos sobre los cambios y modificaciones sustanciales de dicho documento a través del correo electrónico o de otro medio que asegure la notificación correspondiente, y en caso de ser necesario, recolectará nuevamente el consentimiento de los Titulares de Datos para el procesamiento de sus Datos Personales.
    </p>
</div>
<app-footer></app-footer>