import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { SharedService } from "../../services/shared.service";

export const CallingCodeResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const sharedService = inject(SharedService);
    return sharedService.getCallingCodes();
}