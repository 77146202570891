import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { VendeNowModel } from '../../../feature/quote/models/vende-now.model';
import { FormsModule } from '@angular/forms';
import { VendeNowService } from './vende-now.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { CaptchaService } from '../../../core/services/captcha.service';
import { LetterOnlyDirective } from '../../../core/directives/letter-only.directive';
import { NumberOnlyDirective } from '../../../core/directives/number-only.directive';


@Component({
  selector: 'app-vende-now',
  standalone: true,
  imports: [
    FormsModule, 
    CommonModule, 
    RecaptchaV3Module,    
    LetterOnlyDirective,
    NumberOnlyDirective
  ],
  templateUrl: './vende-now.component.html',
  styleUrl: './vende-now.component.scss'
})
export class VendeNowComponent {
  private dialogRef = inject(MatDialogRef)
  private snackBar = inject(MatSnackBar)
  private vendeNowService = inject(VendeNowService)
  private recaptchaV3Service = inject(ReCaptchaV3Service);
  private internalCaptchaService = inject(CaptchaService);

  vendeNow: VendeNowModel = {
    companyName: '',
    cuit: '',
    fullName: '',
    phone: '',
    email: '',
    comment: ''
  };

  validCompanyName: boolean = true;
  validCuit: boolean = true;
  validFullName: boolean = true;
  validPhone: boolean = true;
  validEmail: boolean = true;

  buttonDisabled: boolean = true;

  constructor() { }

  closeModal(showSnackBar: boolean) {
    if (showSnackBar) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: 'Solicitud enviada',
        duration: 3000
      });
    }
    this.dialogRef.close();
  }

  onSubmit() {
    if(!this.validateQuoteRequest()){
      return;
    }

    const vendeNowRequest = this.vendeNowService.sendVendeNowRequest(this.vendeNow)
    .pipe(       
      catchError((error) => {
        return of(null)
      })
    )

    this.recaptchaV3Service.execute('vendeNowSubmit')
    .pipe(
      map(token => token),
      switchMap(token => this.internalCaptchaService.verifyCaptchaToken(token).pipe(map(response => response.success))),
      catchError((error) => {
        return of(null)
      })
    )
    .subscribe(captchaValid => {
      if(!captchaValid) {
        this.submitError();
        return;
      }
      
      vendeNowRequest.subscribe(response => {
        if (response) {
          this.closeModal(true);
        } else {
          this.submitError();
        }
      })
    });
  }     
  
  submitError(){
    this.snackBar.open('Error al enviar la solicitud', '', {
      duration: 3000,
      panelClass: 'custom-snackbar'
    });
  }

  validateQuoteRequest() {
    this.validateCompanyName();
    this.validateCuit();
    this.validateFullName();
    this.validatePhone();
    this.validateEmail();

    this.buttonState()
    
    return this.validCompanyName && this.validCuit && this.validFullName && this.validPhone && this.validEmail
  }

  validateCompanyName() {
    this.validCompanyName = this.vendeNow.companyName.trim() !== '';

    // if (this.vendeNow.companyName.trim() === '') {
    //   this.validCompanyName = false;
    // } else {
    //   const hasNumbers = /\d/.test(this.vendeNow.companyName);
    //   this.validCompanyName = !hasNumbers;
    // }
    this.buttonState();
  }

  validateCuit(){
    if (this.vendeNow.cuit.trim() === '') {
      this.validCuit = false;
    } else {
      this.validCuit = this.vendeNow.cuit.length === 11;
    }

    this.buttonState();
  }

  validateFullName() {
    this.validFullName = this.vendeNow.fullName.trim() !== '';

    // if (this.vendeNow.fullName.trim() === '') {
    //   this.validFullName = false;
    // } else {
    //   const hasNumbers = /\d/.test(this.vendeNow.fullName);
    //   this.validFullName = !hasNumbers;
    // }

    this.buttonState();
  }

  validatePhone() {
    if (this.vendeNow.phone.trim() === '') {
      this.validPhone = false;
    } else {
      // const onlyDigits = /^\d+$/;
      this.validPhone = this.vendeNow.phone.length === 10; //&& onlyDigits.test(this.vendeNow.phone);
    }
    this.buttonState();
  }

  validateEmail() {
    if (this.vendeNow.email.trim() === '') {
      this.validEmail = false;
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.validEmail = emailPattern.test(this.vendeNow.email);
    }
    this.buttonState();
  }

  buttonState() {
    this.buttonDisabled = !(this.validCompanyName && this.validCuit && this.validFullName && this.validPhone && this.validEmail);
  }
  
  
}
