<div class="flex justify-center text-xs my-3 items-center lg:text-sm lg:justify-end">
    <span class="inline-block mr-2">Ordenar por</span>
    <select 
        name="sort" 
        id="sort" 
        class="rounded-full border-2 border-[F0F0F0] pl-4 pr-8 py-1 text-blue bg-white"
        [(ngModel)]="productsOrder"
        (change)="sortProducts()">
        >
        <option value="2" selected>Menor precio</option>
        <option value="1">Mayor precio</option>
    </select>
</div>

<div
    class="mb-4 grid grid-cols-1 lg:grid-cols-9" 
    *ngFor="let product of cardProducts; let i = index" 
    [ngClass]="{'relative' : ((product.type == 1 && product.outstanding) || (product.type !== 1))}">

    <!-- primera parte del card -->

        
    <!-- producto standard -->
    @if(product.type == 1 && !product.outstanding){
        <div class="flex justify-center lg:justify-normal rounded-t-[2rem] bg-grey font-bold text-white px-8 py-4 lg:col-span-2 lg:rounded-l-full lg:p-4 2xl:p-8">
            <div class="lg:ml-8 self-center ">
                <p class="text-lg">now <br class="hidden lg:block" />
                    <span class="text-[1.7rem]">{{ product.name | productName }}</span>
            </div>
        </div>
    }

    <!-- producto mas elegido -->
    @if(product.type == 1 && product.outstanding){
        <span class="absolute left-5 -top-2 lg:-top-5 lg:left-0 lg:top-0 rounded-xl text-xs bg-blue px-2 py-1 lg:p-2 font-bold text-green">EL MÁS ELEGIDO</span>
        <div class="flex justify-center lg:justify-normal bg-skyblue font-bold text-blue lg:col-span-2 rounded-t-[2rem] lg:rounded-l-full px-8 pt-6 pb-4 lg:p-4 2xl:p-8">
            <div class="lg:ml-8 self-center ">
                <p class="text-lg">now <br class="hidden lg:block" />
                    <span class="text-[1.7rem]">{{ product.name | productName }}</span>
            </div>
        </div>
    }

    <!-- producto multiviajes -->
    @if(product.type !== 1){
        <img class="absolute right-0 -top-5 lg:-left-[10px] lg:top-[10%] w-[45px]" src="../../assets/img/icons/cucarda.png" />
        <div class="flex justify-center lg:justify-normal bg-blue text-white font-bold lg:col-span-2 rounded-t-[2rem] lg:rounded-l-full px-8 py-4 lg:p-4 2xl:p-8">
            <div class="lg:ml-8 self-center ">
                <p class="text-lg">now <br class="hidden lg:block" />
                    <span class="mb-4 text-[1.7rem]">{{ product.name | productName }}</span>
                </p>
                <p class="text-xs font-medium">Todos tus viajes en un año</p>
                <p class="text-xs font-medium">Hasta 30 días por viaje
                    <fa-icon [icon]="faInfo" class="cursor-pointer" [ngxTippy]="multiviajeTooltip" 
                    [tippyProps]="{
                        placement: 'bottom',
                        theme: 'light-border',
                    }">
                    </fa-icon>
                </p>
            </div>
        </div>
    }
    

    <!-- PARTE DE ATTRIBUTES -->

    <div class="border-r bg-grey3 px-4 pt-4 lg:px-8 lg:py-4 col-span-4 lg:border-grey">
        <div class="h-full my-auto">
            <ul class="ml-2 mb-2 list-disc text-grey font-medium text-sm lg:text-base">
                <li class="lg:leading-5" *ngFor="let attribute of product.attributes | slice:0:4;">
                    <span class="relative -left-1">
                        {{attribute.name}} 
                        <b class="mr-2">{{attribute.value}}</b>
                        <fa-icon 
                            *ngIf="attribute.description !== ''" 
                            class="cursor-pointer text-grey text-xs lg:text-sm" 
                            (mouseover)="setTooltip(attribute.name, attribute.description)"
                            [icon]="faInfo" 
                            [ngxTippy]="attributeTooltip"
                            [tippyProps]="{
                                placement: 'top',
                                theme: 'light-border',
                            }">
                        </fa-icon>
                    </span>
                </li>
            </ul>
            <p class="ml-2 lg:ml-0 cursor-pointer font-bold text-grey underline underline-offset-4 text-sm lg:text-base lg:underline-offset-8"
                (click)="showDetails(true, product.id)">
                Ver detalle
            </p>
        </div>
    </div>

    <!-- PARTE DE PRECIOS -->
    @switch (product.priceType) {
        <!-- standard price -->
        @case(1){
            <div class="content-center bg-grey3 pt-2 px-6 pb-4 rounded-b-[2rem] lg:px-8 lg:py-4 lg:col-span-3 lg:rounded-r-full lg:border-l lg:border-grey">
                <div class="grid grid-cols-2 lg:grid-cols-1">
                    <div>
                        <!-- <p class="text-xs line-through text-blue">ARS200.000</p> -->
                        <p class="mb-2 text-base font-medium text-blue"><br class="block lg:hidden"/>Precio Final {{ product.currency }} {{ product.price | number : '1.2-2' }}</p>
                    </div>
                    <div class="text-right lg:text-left my-auto">
                        <button class="rounded-lg text-white px-4 py-2 text-sm font-bold bg-blue hover:bg-[#1D1191] transition-colors hover:easy-in duration-300"
                            (click)="purchaseProduct(product.id)">
                            CONTRATAR
                        </button>
                    </div>
                </div>
            </div>
        }

        <!-- price with installments -->
        @case(2){
            <div class="content-center bg-grey3 px-6 pb-4 rounded-b-[2rem] lg:px-8 lg:py-4 lg:col-span-3 lg:rounded-r-full lg:border-l lg:border-grey">
                <p class="mb-1 text-xs font-bold text-grey">Hasta 6 cuotas sin interés <br class="hidden lg:block" />
                    <span class="text-xl font-bold text-grey">
                        <span class="text-sm">ARS</span> $25.833
                    </span>
                </p>
                <div class="grid grid-cols-2 lg:grid-cols-1">
                    <div>
                        <p class="text-xs line-through text-blue">ARS200.000</p>
                        <p class="mb-2 text-base font-medium text-blue">Precio Final <br class="block lg:hidden"/>{{ product.currency }} {{ product.price | number : '1.2-2' }}</p>
                    </div>
                    <div class="text-right lg:text-left my-auto">
                        <button class="rounded-lg text-white px-4 py-2 text-sm font-bold bg-blue hover:bg-[#1D1191] transition-colors hover:easy-in duration-300"
                            (click)="purchaseProduct(product.id)">
                            CONTRATAR
                        </button>
                    </div>
                </div>
            </div>
        }

        @case(3){
            <div class="content-center bg-grey3 pt-2 px-6 pb-4 rounded-b-[2rem] lg:px-8 lg:py-4 lg:col-span-3 lg:rounded-r-full lg:border-l lg:border-grey">
                <div class="grid grid-cols-2 lg:grid-cols-1">
                    <div>
                        <p class="text-xs line-through text-blue">ARS200.000</p>
                        <p class="mb-2 text-base font-medium text-blue"><br class="block lg:hidden"/>{{ product.currency }} {{ product.price | number : '1.2-2' }}</p>
                    </div>
                    <div class="text-right lg:text-left my-auto">
                        <button class="rounded-lg text-white px-4 py-2 text-sm font-bold bg-blue hover:bg-[#1D1191] transition-colors hover:easy-in duration-300"
                            (click)="purchaseProduct(product.id)">
                            CONTRATAR
                        </button>
                    </div>
                </div>
            </div>
        }
    }
</div>


<ng-template #medicalAsistanceTooltip>
    <div class="p-4">
        <p class="font-medium">
            Servicio de asistencia médica disponible ante enfermedad y accidente,
        </p>
        <p>
            incluye cobertura frente a Covid-19 y servicio de Teleasistencia las 24hs.
            A través de nuestro servicio de autogestión médica mobile realiza tu
            consulta médica 100% online desde nuestra App.
        </p>
    </div>
</ng-template>

<ng-template #attributeTooltip>
    <div class="p-4">
        <p class="font-medium">
            {{tooltipName}}
        </p>
        <p>
            {{tooltipDescription}}
        </p>
    </div>
</ng-template>

<ng-template #multiviajeTooltip>
    <div class="p-4">
        <p class="mb-4 font-medium">¿Viajás seguido?</p>
        <p>
            <span class="font-medium">Esta cobertura te protege en todos los viajes que hagas durante un año.
                Pagas una vez ¡y listo!</span>
                Viajá tranquilo cuando quieras. (Válido para viajes de hasta 30 ó 60 días
                cada uno).
        </p>
    </div>
</ng-template>