<button type="button"
    class="inline-flex w-full items-center justify-center gap-x-2 rounded-s-md border h-full p-2 text-sm font-medium text-white"
    [ngClass]="{
      'border-red text-red focus-visible:outline-red': showError,
      'border-grey3' : !showError
      }"
    (click)="toggleFlags()"
    (blur)="onBlur()"
  >                                                   
  <img src="../../../../../assets/img/flags/{{selectedPrefix.countryIso}}.svg" class="w-[15px]" alt="Country Flag" (error)="onFlagError($event)"/>
  <img src="../../../../assets/img/icons/caret-down.svg" alt="Caret" class="w-[10px] inline">
</button>

<!-- Dropdown items -->
<div *ngIf="showFlags" class="absolute z-30 mt-1 w-[125px] bg-white border border-grey3 rounded-md shadow-lg max-h-48 overflow-y-auto overflow-hidden">
  <div 
    id="prefix-dropdown"
    class="dropdown-item flex items-center gap-2 p-2 hover:bg-gray-200 cursor-pointer" 
    *ngFor="let code of codes" 
    (click)="selectPhonePrefix(code)"
    >
    <img src="../../../../../assets/img/flags/{{code.countryIso}}.svg" class="w-[15px]" alt="{{ code.countryIso }} flag" (error)="onFlagError($event)"/>
    <p class="text-xs">{{code.countryName}} (+{{ code.callingCode }})</p>
  </div>
</div>