import { Component, OnDestroy, OnInit, Renderer2, ViewChild, inject } from '@angular/core';
import { PassengersService } from '../services/passengers.service';
import { ThemeService } from '../../../core/services/theme.service';
import { RouteService } from '../../../core/route/route.service';
import { PurchaseDetails } from '../../../core/models/purchase-details';
import { Subject, catchError, of, switchMap, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { ProgressBarComponent } from '../../../shared/components/progress-bar/progress-bar.component';
import { PassengerDataComponent } from '../components/passenger-data-form/passenger-data.component';
import { PurchaseResumeComponent } from '../../../shared/components/purchase-resume/purchase-resume.component';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { FooterComponent } from '../../../shared/components/footer/footer.component';
import { ActivatedRoute } from '@angular/router';
import { PaymentRoute } from '../../../core/route/routes/route-parser';

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrl: './passengers.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    ProgressBarComponent,
    PassengerDataComponent,
    PurchaseResumeComponent,
    LoaderComponent,
    FooterComponent
  ]
})
export class PassengersComponent implements OnInit, OnDestroy{

  @ViewChild(PassengerDataComponent) childComponent!: PassengerDataComponent;

  private destroy$ = new Subject<void>();

  //INJECT SERVICES
  private passengersService = inject(PassengersService);
  private routeService = inject(RouteService);
  private themeService = inject(ThemeService);    
  private renderer2 = inject(Renderer2);
  private activatedRoute = inject(ActivatedRoute);

  //CHILD COMPONENTS INPUTS
  protected purchaseDetails!: PurchaseDetails;
  protected passengerAges: number[] = [];

  //LOCAL VARIABLES
  protected dataIsReady: boolean = false; 

  constructor() { 
    this.themeService.setRenderer(this.renderer2);
    this.themeService.setTheme(this.routeService.getClient());
  }

  ngOnInit() {
    this.activatedRoute.data
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(({purchaseDetails}) => {
      this.purchaseDetails = purchaseDetails;
      this.passengerAges = this.purchaseDetails.quote.passengersAges;
      this.dataIsReady = true;
    });   
  }

  ngOnDestroy(){
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitPaxForm(){
    this.childComponent.onSubmit();
  }

  protected onPassengerDataSubmited(dataSubmited: any){
    const {paxData, uniqueEmergencyContact} = dataSubmited;
    this.dataIsReady = false;
    this.passengersService.savePassengersData(paxData, uniqueEmergencyContact)
    .pipe(
      takeUntil(this.destroy$),
      catchError((error) => {
        this.dataIsReady = true;
        return of(error);
      })
    )
    .subscribe((paxResponse) => {
      if(paxResponse) return;
      this.routeService.resolveRedirection(new PaymentRoute());
    });
  }
}
