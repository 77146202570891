import { Routes } from "@angular/router";
import { FaqsComponent } from "../../../feature/statics/pages/FaqsComponent/FaqsComponent.component";
import { LanguageResolver } from "../resolvers/language.resolver";
import { CountryResolver } from "../resolvers/country.resolver";
import { ServerStatusResolver } from "../resolvers/server-status.resolver";
import { authGuard } from "../guards/auth.guard";
import { AboutUsComponent } from "../../../feature/statics/pages/about-us/about-us.component";
import { PrivacyComponent } from "../../../feature/statics/pages/privacy/privacy.component";
import { TermsComponent } from "../../../feature/statics/pages/terms/terms.component";
import { StaticRouteResolver } from "../resolvers/static-route.resolver";
import { ContactV2Component } from "../../../feature/statics/pages/contact/v2/contact-v2.component";
import { MetaResolver } from "../../meta/resolver/meta.resolver";

export const staticsEsRoutesNoLang: Routes = [
    { path: ':country/faq', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/quienes-somos', component: AboutUsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/contacto', component: ContactV2Component, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver , MetaResolver} },
    { path: ':country/privacidad', component: PrivacyComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/condiciones', component: TermsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    // { path: ':country/boton-arrepentimiento', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver } }
]

export const staticsEnRoutesNoLang: Routes = [
    { path: ':country/faq', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/about-us', component: AboutUsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/contact', component: ContactV2Component, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/privacy', component: PrivacyComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/terms', component: TermsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    // { path: ':country/cancelation-button', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver } }
];

export const staticsPtRoutesNoLang: Routes = [
    { path: ':country/faq', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/quem-somos', component: AboutUsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/contato', component: ContactV2Component, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/privacidade', component: PrivacyComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    { path: ':country/condicoes', component: TermsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, StaticRouteResolver, MetaResolver } },
    // { path: ':country/botao-arrependimento', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver } }
]

export const staticsEsRoutesWithLang: Routes = [
    { path: ':country/:lang/faq', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver } },
    { path: ':country/:lang/quienes-somos', component: AboutUsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/contacto', component: ContactV2Component, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/privacidad', component: PrivacyComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/condiciones', component: TermsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    // { path: ':country/:lang/boton-arrepentimiento', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver }  }
]

export const staticsEnRoutesWithLang: Routes = [
    { path: ':country/:lang/faq', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/about-us', component: AboutUsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/contact', component: ContactV2Component, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver } },
    { path: ':country/:lang/privacy', component: PrivacyComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/terms', component: TermsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    // { path: ':country/:lang/cancelation-button', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver }  }
];

export const staticsPtRoutesWithLang: Routes = [
    { path: ':country/:lang/faq', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/quem-somos', component: AboutUsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/contato', component: ContactV2Component, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/privacidade', component: PrivacyComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    { path: ':country/:lang/condicoes', component: TermsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, StaticRouteResolver , MetaResolver }  },
    // { path: ':country/:lang/botao-arrependimento', component: FaqsComponent, canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver }  }
]
