<section
  class="relative z-20 grid grid-cols-1 gap-4 bg-skyblue p-4 md:mx-16 xl:mx-24 2xl:mx-40"
  [ngClass]="{
    'mx-4 -mb-32 xl:-mb-12': home,
    'mt-[74px] content-start pt-12' : mobile,
    'rounded-lg' : !mobile,
    'lg:grid-cols-3 xl:grid-cols-12': extendedQuote,
    'lg:grid-cols-2 xl:grid-cols-7': !extendedQuote
  }">
  <div
    class="out multiselect-container col-span-1 cursor-pointer rounded-lg bg-white px-3 py-2 2xl:p-4 text-grey xl:col-span-2"
    #multiselect
    (click)="openMultiselect($event)"
    [ngClass]="{
      'border-2 border-red text-red focus-visible:outline-red': !validDestination,
      'xl:col-span-4': extendedQuote
    }">
    <div class="out flex justify-between">
      <div class="out flex items-start grow">
        <img src="../../../../assets/img/icons/location.png" alt="" class="w-[20px] my-auto mr-4"
          [ngClass]="{'out': extendedQuote}">
        <div class="out grow">
          <p class="out text-base 2xl:text-lg font-bold">¿A dónde viajás?</p>
          <ng-multiselect-dropdown 
            class="container"
            [placeholder]="'Indicar los países por donde vas a pasar, incluyendo las conexiones'"
            [settings]="dropdownSettings" 
            [data]="dropdownList" 
            (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            [(ngModel)]="selectedItems"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
      <img src="../../../../assets/img/icons/caret-down.svg" alt="" class="out w-[10px] lg:w-[20px] my-auto ml-2"
        [ngClass]="{'hidden': showCountries, 'block': !showCountries}">
      <img src="../../../../assets/img/icons/caret-up.svg" alt="" class="out w-[10px] lg:w-[20px] my-auto ml-2"
        [ngClass]="{'hidden': !showCountries, 'block': showCountries}">
    </div>
  </div>

  <div class="col-span-1 cursor-pointer rounded-lg bg-white px-3 py-2 2xl:p-4 text-grey xl:col-span-2"
    (click)="openCalendar()"
    [ngClass]="{
      'border-2 border-red text-red focus-visible:outline-red': !validDate,
      'xl:col-span-4': extendedQuote
    }">
    <div class="flex justify-between"
      [ngClass]="(!startDate || !endDate) ? 'h-full': ''">
      <div class="flex items-start my-auto">
        <img src="../../../../assets/img/icons/calendar.png" alt="" class="w-[20px] mr-4">
        <p class="text-base 2xl:text-lg font-bold">Fecha de viaje</p>
      </div>
      <img src="../../../../assets/img/icons/caret-down.svg" alt="" class="out w-[10px] lg:w-[20px] my-auto ml-2"
        [ngClass]="{'hidden': showDates, 'block': !showDates}">
      <img src="../../../../assets/img/icons/caret-up.svg" alt="" class="out w-[10px] lg:w-[20px] my-auto ml-2"
        [ngClass]="{'hidden': !showDates, 'block': showDates}">
    </div>
    <p *ngIf="startDate && endDate" class="text-xs font-medium text-blue mt-2">
      {{ startDate | date: "longDate" }} - {{ endDate | date: "longDate" }}
    </p>
  </div>

  <div
    class="col-span-1 flex cursor-pointer justify-between rounded-lg bg-white px-3 py-2 2xl:p-4 text-grey xl:col-span-2"
    [ngClass]="{
      'rounded-b-none': showPassengers,
      'border-2 border-red text-red focus-visible:outline-red' : !validPassenger,
      'xl:col-span-4': extendedQuote
    }"
    #passengersButton 
    (click)="togglePassengerCaret()"
    [ngxTippy]="viajerosTooltip"
    [tippyProps]="tippyPropsContent">
    <div class="flex items-start">
      <img src="../../../../assets/img/icons/pasajero.png" alt="" class="w-[20px] my-auto mr-4">
      <div class="my-auto">
        <p class="hidden lg:block text-base 2xl:text-lg font-bold">Cantidad de viajeros</p>
        <p class="block lg:hidden text-base 2xl:text-lg font-bold">Viajeros</p>
      </div>
    </div>
    <div class="my-auto flex">
      <span 
        class="mx-4 text-base 2xl:text-lg font-bold" 
        *ngIf="passengers.length > 0"
        [ngClass]="{
          'text-blue': validPassenger,
          'text-red': !validPassenger
        }"
        >
        {{ passengers.length }}
      </span>
      <img src="../../../../assets/img/icons/caret-down.svg" alt="" class="out w-[10px] lg:w-[20px] my-auto ml-2"
        [ngClass]="{'hidden': showPassengers, 'block': !showPassengers}">
      <img src="../../../../assets/img/icons/caret-up.svg" alt="" class="out w-[10px] lg:w-[20px] my-auto ml-2"
        [ngClass]="{'hidden': !showPassengers, 'block': showPassengers}">
    </div>
  </div>
  <div *ngIf="extendedQuote"
    (click)="focusInput(nameInput)"
    [ngClass]="{'border-2 border-red text-red focus-visible:outline-red' : !validName}"
    class="col-span-1 cursor-pointer rounded-lg bg-white px-3 py-2 2xl:p-4 text-grey xl:col-span-3">
    <div class="flex items-center h-full">
        <img src="../../../../assets/img/icons/name.png" alt="" class="w-[20px] mr-4">
        <input type="text" #nameInput placeholder="Nombre" [(ngModel)]="name" (ngModelChange)="validateName()"
        class="w-full font-bold focus:outline-none cursor-pointer text-blue invalid:border-red ng-untouched ng-pristine ng-valid">
    </div>
  </div>
  <div *ngIf="extendedQuote"
    (click)="focusInput(emailInput)"
    [ngClass]="{'border-2 border-red text-red focus-visible:outline-red' : !validEmail}"
    class="col-span-1 cursor-pointer rounded-lg bg-white px-3 py-2 2xl:p-4 text-grey xl:col-span-3">
    <div class="flex items-center h-full">
      <img src="../../../../assets/img/icons/mail.png" alt="" class="w-[20px] mr-4">
      <input type="email" #emailInput placeholder="Email" [(ngModel)]="email" (ngModelChange)="validateEmail()"
        class="w-full font-bold focus:outline-none cursor-pointer text-blue invalid:border-red ng-untouched ng-pristine ng-valid">
    </div>
  </div>
  <div 
    *ngIf="extendedQuote" 
    class="col-span-1 cursor-pointer rounded-lg bg-white px-3 2xl:px-4 text-grey xl:col-span-3"
    (click)="focusInput(phoneInput)"
    [ngClass]="{'border-2 border-red text-red focus-visible:outline-red' : !validPhone}"
    >
    <div class="flex items-center min-h-[40px] h-full">
      <img src="../../../../assets/img/icons/phone.png" alt class="w-[20px] mr-4">
      <span #flagButton
        class="inline-flex w-[80px] h-full items-center justify-center gap-x-2 border border-grey3 bg-grey3 py-2 px-2 text-sm font-medium text-white cursor-pointer"
        aria-expanded="false" 
        [ngxTippy]="flagTooltip" 
        [tippyProps]="phoneTippyPops">
        <img [src]="selectedFlag.img" [alt]="selectedFlag.text" class="w-[15px]">
        <img src="../../../../assets/img/icons/caret-down.svg" *ngIf="!showFlags" alt="" class="w-[10px] inline">
        <img src="../../../../assets/img/icons/caret-up.svg" *ngIf="showFlags" alt="" class="w-[10px] inline">
      </span>

      <p class="font-bold text-blue px-2">{{selectedFlag.phonePrefix}}</p>
      <input type="text" #phoneInput placeholder="Teléfono" [(ngModel)]="phone" (ngModelChange)="validatePhone()"
        class="w-full font-bold focus:outline-none cursor-pointer text-blue px-2">
    </div>
  </div>
  <button
    (click)="quote()"
    [disabled]="disabledSubmitButton"
    class="rounded-lg bg-blue mt-4 lg:mt-0 px-8 py-2 lg:py-4 font-bold text-white transition-colors duration-300 hover:bg-[#1D1191] disabled:bg-[#D5D5D5]"
    [ngClass]="{'lg:col-span-3': extendedQuote}"
    >
    {{ buttonText }}
  </button>
  <img *ngIf="!home && !mobile" src="../../../../assets/img/icons/arrow-up.png"
    class="w-[25px] bg-blue rounded-full my-auto absolute text-white font-medium text-lg bottom-2 right-2 cursor-pointer"
    (click)="closeCotizador()">
  <p *ngIf="!home && mobile" class="absolute right-5 top-2 cursor-pointer text-lg text-white"
    (click)="closeCotizador()">x</p>
</section>

<ng-template #viajerosTooltip>
  <div class="w-[full] bg-white font-medium">
    <div class="flex justify-between bg-grey2 p-2">
      <p class="my-auto mr-16">Cantidad de Pasajeros</p>
      <p class="text-lg font-bold text-blue">
        <span class="cursor-pointer" (click)="editPassengers(false)">-</span>
        <span class="mx-4">{{ passengers.length }}</span>
        <span class="cursor-pointer" (click)="editPassengers(true)">+</span>
      </p>
    </div>
    <div *ngFor="let passenger of passengers; let i = index" class="flex justify-between p-2">
      <p class="my-auto">Edad</p>
      <div class="w-full text-right">
        <input 
          type="text" 
          [(ngModel)]="passenger.age" 
          (ngModelChange)="onAgeChange(i, $event)"
          class="w-1/4 border bg-grey2 p-2 invalid:border-red focus-visible:outline-blue focus-visible:outline-1"
          [ngClass]="{'border-red text-red focus-visible:outline-red': (passenger.age && passenger.age > MAXIMUM_AGE) || !passenger.validAge,
          'focus-visible:outline-grey': passenger.age == MINIMUM_AGE,
          'border-blue': passenger.age && (passenger.age > MINIMUM_AGE) && (passenger.age <= MAXIMUM_AGE)
        }"
        maxlength="2"
        numberOnly
        />
        <p *ngIf="passenger.age && (passenger.age > MAXIMUM_AGE || passenger.age < MINIMUM_AGE)" class="text-xs text-red mt-1">
          La edad permitida es entre {{ MINIMUM_AGE }} y {{ MAXIMUM_AGE }} años
        </p>        
      </div>
    </div>
    <p class="text-xs text-center mb-1 text-blue">Edad al inicio del viaje</p>
    <button id="tooltip-apply" *ngIf="passengers.length > 0" class="w-full bg-blue py-2 text-center font-medium text-white rounded-lg disabled:bg-[#D5D5D5]"
      (click)="hideTippy()"
      [disabled]="disabledPassengerButton">
      APLICAR
    </button>
  </div>
</ng-template>

<ng-template #flagTooltip>
  <div class="bg-white font-medium w-[38px]">
    <div *ngFor="let flag of flags" class="py-2 cursor-pointer" (click)="selectFlag(flag)">
      <img [src]="flag.img" [alt]="flag.text" class="w-[15px] h-[15px] mx-auto" />
    </div>
  </div>
</ng-template>