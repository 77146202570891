import { PassengerData } from "../../feature/passengers/models/passenger-data.model";
import { Product } from "../../feature/products/models/products";
import { AvailableDestinations } from "../../feature/quote/models/destinations";
import { QuoteModel } from "../../feature/quote/models/quote.model";

export class Purchase {
    constructor(
        public client: string,
        public destinations: AvailableDestinations[],
        public countries: any[],
        public quoteId: number,
        public purchaseId: number,
        public paymentId: number,
        public quoteRequest: QuoteModel,
        public quotedProducts: Product[],
        public selectedProduct: Product,
        public paxData: PassengerData[]
    ){}

    static createPurchase(): Purchase {
        return new Purchase('', [], [], 0, 0, 0, QuoteModel.createModel(), [], Product.createProduct(), []);
    }
}