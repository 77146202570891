import { Injectable, inject } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { RouteService } from '../../../core/route/route.service';
import { Routes } from '../../../core/enums/routes';
import { LocalStorageSources } from '../../../core/enums/storage-sources';
import { Product } from '../models/products';
import { PassengersRoute } from '../../../core/route/routes/route-parser';

@Injectable({
    providedIn: 'root'
})
export class ProductService{

    // INJECT SERVICES
    private apiService = inject(ApiService);
    private localService = inject(LocalStorageService);
    private routeService = inject(RouteService);

    constructor() { }

    // METHODS
    public getProducts(){
        const products = this.localService.getItemByKey(LocalStorageSources.QUOTED_PRODUCTS);//await this.messageService.getMessage(Sources.PRODUCTS);
        if(!products){
            return [];
        }
        return products;    
    }

    public redirectToQuoteView(){ 
        this.routeService.resolveRedirection(null);
    }

    public redirectToPassengersView(){
        this.routeService.resolveRedirection(new PassengersRoute());
    }

    public puchaseProduct(product: Product, lang: string = 'en'){
        const quoteId = this.localService.getItemByKey(LocalStorageSources.QUOTE_ID);

        const request = {
            "quoteId": quoteId,
            "selectedProductId": product.id
        }

        return this.apiService.post('GeneratePreSale', request);
    }

    public savePurchaseId(purchaseId: number){
        this.localService.setItem(purchaseId.toString(), LocalStorageSources.PURCHASE_ID);
    }

    public saveSelectedProduct(product: Product){
        this.localService.setItem(JSON.stringify(product), LocalStorageSources.SELECTED_PRODUCT);
    }

    public getSelectedProduct(){
        return this.localService.getItemByKey(LocalStorageSources.SELECTED_PRODUCT);
    }
}
