import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from '../services/localization.service';
import { AboutUsRoute, CompaniesSubdirectoryRoute, ContactRoute, FaqsRoute, IRoute, PrivacyRoute, QuoteSubdirectoryRoute, TermsRoute } from './routes/route-parser';
import { Subdirectory } from '../enums/subdirectory.enum';

@Injectable({
    providedIn: 'root'
})
export class RouteService {

    private readonly DEFAULT_CLIENT = 'now'
    private readonly DEFAULT_CAMPAIGN = 'default';
    
    private showLang : boolean = false;

    private localizationService = inject(LocalizationService);
    private router = inject(Router);

    public errorMessage: string = '';

    private lastPage!: IRoute;
    private currentPage!: IRoute;
    private subdirectory! : Subdirectory | null;

    private currentUrl!: string;

    constructor() { 
    }

    public getClient(){
        return this.DEFAULT_CLIENT;
    }

    public setCurrentPage(page: IRoute){
        this.lastPage = this.currentPage;
        this.currentPage = page;
    }

    public getCurrentPage(){
        return this.currentPage;
    }

    public getLastPage(){
        return this.lastPage;
    }

    public setSubdirectory(subdirectory: Subdirectory | null){
        this.subdirectory = subdirectory;
    }

    public getSubdirectory(){
        return this.subdirectory;
    }

    public setCurrentUrl(url: string){
        this.currentUrl = url;
    }

    public setShowLang(showLang: boolean){
        this.showLang = showLang;
    }

    public resolveRedirection(route: IRoute | null){
        var redirectionRoute = '';

        redirectionRoute += `/${this.localizationService.getCountry()}`;

        if(this.showLang){
            redirectionRoute += `/${this.localizationService.getLanguage()}`;
        }

        switch(this.subdirectory){
            case Subdirectory.QUOTE:
                redirectionRoute += `/${this.localizationService.returnRouteByLanguage(new QuoteSubdirectoryRoute())}`;
                break;
            case Subdirectory.COMPANIES:
                redirectionRoute += `/${this.localizationService.returnRouteByLanguage(new CompaniesSubdirectoryRoute())}`;
                break;
        }

        if(route){
            redirectionRoute += `/${this.localizationService.returnRouteByLanguage(route)}`;
            this.setCurrentPage(route);
        }

        this.router.navigate([redirectionRoute]);
    }

    public refreshPage(){
        this.router.navigate(['/']);
    }  

    public getStaticRoute(route: string){
        switch(route){
            case 'faq':
                return this.localizationService.returnRouteByLanguage(new FaqsRoute());
            case 'about-us': case 'quienes-somos': case 'quem-somos':
                return this.localizationService.returnRouteByLanguage(new AboutUsRoute());
            case 'contact': case 'contacto': case 'contato':
                return this.localizationService.returnRouteByLanguage(new ContactRoute());
            case 'privacy': case 'privacidad': case 'privacidade':
                return this.localizationService.returnRouteByLanguage(new PrivacyRoute());
            case 'terms': case 'condiciones': case 'condicoes':
                return this.localizationService.returnRouteByLanguage(new TermsRoute());
            default:
                return '';
        }
    }
}
