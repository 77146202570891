import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, catchError, of, switchMap, takeUntil } from 'rxjs';
import { ProductNamePipe } from '../../../core/pipes/product-name.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ProductDetailModalComponent } from '../../../feature/products/components/product-detail-modal/product-detail-modal.component';
import { FormsModule } from '@angular/forms';
import { PaymentService } from '../../../feature/payment/services/payment.service';
import { PurchaseDetails } from '../../../core/models/purchase-details';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../../core/services/local-storage.service';

@Component({
  selector: 'app-purchase-resume',
  templateUrl: './purchase-resume.component.html',
  styleUrls: ['./purchase-resume.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ProductNamePipe,
    FormsModule
  ]
})
export class PurchaseResumeComponent implements OnInit, OnDestroy {

  @Input() paxPage: boolean = false;

  private activatedRoute = inject(ActivatedRoute);
  private localStorageService = inject(LocalStorageService);

  constructor(private dialog: MatDialog) { }

  private destroy$ = new Subject<void>();
  private paymentService = inject(PaymentService);

  purchaseDetails!: PurchaseDetails;
  selectedProduct!: any;
  quote!: any;

  resumeDetails= false;

  countriesLabel: string = '';
  countriesLabelMobile: string = '';
  days = 0;
  viajeros = 0;
  viajeroLabel = '';

  discountValid = false;
  discountApplied = false;

  discount: number = 0;
  discountPrice: number = 0;
  finalPrice = 0;

  cupon: string = '';

  showResumeDetail(){
    this.resumeDetails = !this.resumeDetails;
  }

  showDetails(algo: boolean){
    const dialogRef = this.dialog.open(ProductDetailModalComponent, {
      data: { 
        showButton: false,
        product: this.purchaseDetails.product
      }
  });
  }

  ngOnInit() {
    this.activatedRoute.data
    .pipe(
      takeUntil(this.destroy$),     
    )
    .subscribe(({ purchaseDetails }) => {
      this.purchaseDetails = purchaseDetails; 
      this.viajeros = purchaseDetails.quote.passengersAges.length;
      this.selectedProduct = purchaseDetails.product;
      this.quote = purchaseDetails.quote;
      this.finalPrice = this.purchaseDetails.product.price;
      this.generateTravelersLabel();
      this.generateCountriesLabel(purchaseDetails.quote.destination);
      this.generateDaysLabel();
      this.restoreDiscount(purchaseDetails.appliedDiscount);
    });  
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  generateCountriesLabel(countries: string[]){    
    if(countries.length > 0){
      this.countriesLabel = countries.slice(0, 3).join(', ');
      if(countries && (countries.length > 3)){
        this.countriesLabel += '+' + (countries.length - 3);
      }

      this.countriesLabelMobile = countries.slice(0, 1).join(', ');
      if(countries && countries.length > 1){
        this.countriesLabelMobile += ' +' + (countries.length - 1);
      }
    }
  }

  generateTravelersLabel(){
    if(this.viajeros > 1){
      this.viajeroLabel = 'viajeros';
    }
    else{
      this.viajeroLabel = 'viajero';
    }
  }

  generateDaysLabel(){
    if(this.purchaseDetails.quote.starting && this.purchaseDetails.quote.ending){
      const startDate = new Date(this.purchaseDetails.quote.starting);
      const endDate = new Date(this.purchaseDetails.quote.ending);
      const days = Math.abs((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
      this.days = days;
      return;
    }
    this.days = 0;
  }

  restoreDiscount(discount: number){
    if(discount > 0){
      this.discountApplied = true;
      this.discountValid = true;
      this.discount = discount;
      this.refreshPrice(discount);
    }
  }

  validateDiscount(){
    this.paymentService.validateDiscount(this.cupon)
    .pipe(
      takeUntil(this.destroy$),
      catchError((error) => {
        if(error.status === 500){
          return of(null);
        }

        return of(error);
      })
    )
    .subscribe((response) => {
      if(!response) return;

      this.discountApplied = true;

      if(response.validPromo){
        const {description, discount} = response;
        this.discountValid = true;
        this.discount = discount;
        this.refreshPrice(discount);
        this.localStorageService.saveDiscountCode(this.cupon);
      }
      else{
        this.discountValid = false;
      }
    })
  }

  refreshPrice(discount: number){
    this.discountPrice = this.purchaseDetails.product.price * discount / 100;
    this.finalPrice = this.purchaseDetails.product.price - this.discountPrice;
  }

}
