import { Routes } from "../enums/routes";

export const RouteParser = (route: string): Routes => {
    switch(route){
        case 'quote':
            return Routes.HOME;
        case 'products':
            return Routes.PRODUCTS;
        case 'passengers-data':
            return Routes.PASSENGERS_DATA;
        case 'payment':
            return Routes.PAYMENT;
        case 'voucher':
            return Routes.VOUCHER;
        case 'mercadopago':
            return Routes.MERCADOPAGO;
        default:
            return Routes.HOME;
    }
}