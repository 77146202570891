import { CommonModule } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input, inject, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CallingCode } from '../../../../core/models/calling-codes';

@Component({
  selector: 'prefix-selector',
  standalone: true,
  templateUrl: './prefix-selector.component.html',
  styleUrls: ['./prefix-selector.component.scss'],
  imports: [ CommonModule ]
})
export class PrefixSelectorComponent implements OnInit, OnChanges {

  @Input() selectedCode : string = '54';
  @Input() showError : boolean = false;
  @Output() prefixSelected = new EventEmitter<string>();

  codes: CallingCode[] = [];
  showFlags : boolean = false;
  selectedPrefix : CallingCode = { countryIso: 'AR', countryName: 'Argentina', callingCode: '54' };

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(( {prefixes} ) => {
      this.codes = prefixes;
      this.setPrefix(this.selectedCode);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedCode']){
      this.setPrefix(this.selectedCode);
    }
  }

  setPrefix(prefix: string){
    this.selectedPrefix = this.codes.find(c => c.callingCode === prefix) || this.selectedPrefix;
  }

  toggleFlags(){
    this.showFlags = !this.showFlags;
  }

  selectPhonePrefix(code: CallingCode){
    this.showFlags = false;
    this.selectedPrefix = code;
    this.prefixSelected.emit(code.callingCode);
  }

  onFlagError(event: any) {
    event.target.src = `../../../../../assets/img/flags/EARTH.svg`;
    event.target.alt = 'Earth Flag';
  }

  onBlur() {
    setTimeout(() => {
      this.showFlags = false;
    }, 200);
  }
}
