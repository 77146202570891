<app-header 
    [showQuoteButton]="false" 
    [showLogin]="false" 
    [showLangs]="false" 
></app-header>
<div class="py-8 lg:py-4">
    <div class="relative z-10 overflow-x-hidden">
        <img src="../../../assets/img/home/misc-01-i.png" class="hidden lg:block absolute left-0 bottom-0 w-8 md:w-12">
        <img src="../../../assets/img/quienes/misc-derecha-elementos.png" class="hidden lg:block absolute -right-6 top-8 w-[150px]">
        <section class="flex gap-2 mx-8 md:mx-16 md:mb-8 md:grid-cols-2 lg:gap-8 lg:mt-8 lg:mx-24 2xl:mx-40 2xl:max-w-screen-2xl 2xl:grid-cols-3">
            <div class="relative my-auto">
                <p class="mb-2 text-2xl leading-none text-blue md:mr-0 lg:text-4xl 2xl:text-5xl">
                    Somos
                </p>
                <p class="mb-5 md:mr-0 lg:mb-4 text-3xl font-bold leading-none text-blue lg:text-4xl 2xl:text-5xl">
                    NOW ASSISTANCE
                </p>
                <p class="font-medium text-grey text-xl lg:text-2xl mb-8">
                    El aliado que necesitás para tus viajes seguros.
                </p>
                <p class="mb-8">
                    <span class="font-bold text-white text-3xl bg-skyblue p-2">¿Quiénes somos?</span>
                </p>
                <p class="block mb-4 font-bold text-[#4B4B4B] lg:text-lg">
                    Somos una compañía que ofrece Asistencia al Viajero mediante soluciones digitales.
                </p>
                <p class="mb-4 lg:font-bold text-[#4B4B4B] lg:text-base">
                    <b>Tenemos visión 360° del negocio.</b> Nuestra amplia trayectoria en el mercado asegurador y en la asistencia
                    al viajero nos permite desarrollar soluciones únicas para cada cliente.
                </p>
            </div>
            <div class="hidden lg:block w-1/3">
                <img class="mx-auto hidden lg:block" src="../../../assets/img/quienes/quienes-somos-01.png"
                    alt="Quienes Somos" />
                <!-- <img class="mr-0 hidden md:block lg:hidden" src="../../../assets/img/home/01-mobile.png" alt="Home" /> -->
            </div>
        </section>
    </div>
    <div class="relative bg-blue px-4 lg:px-16">    
        <img src="../../../assets/img/quienes/misc-izq-elementos.png" class="hidden lg:block absolute -left-6 top-5 w-[90px]">
        <img src="../../../assets/img/home/misc-01-d.png" class="absolute top-0 right-0 hidden w-12 lg:block" />
        <p class="text-green text-center py-8 text-lg"><b>Innovamos y nos adaptamos a las necesidades</b> cambiantes del mercado para garantizar experiencias memorables en cualquier momento y lugar.</p>
    </div>
    <div class="bg-grey2 p-4 lg:px-32 lg:py-16 grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div class="rounded-lg bg-white flex p-4">
            <img src="../../../assets/img/quienes/02-mundo.png" class="self-center mr-6">
            <p><span class="text-blue font-bold">Tenemos una red global de colaboradores a disposición</span>; desde servicios médicos de emergencia hasta gestiones por pérdida de equipaje o cancelación de vuelos.</p>
        </div>
        <div class="rounded-lg bg-white flex p-4">
            <img src="../../../assets/img/quienes/02-personas.png" class="self-center mr-6">
            <p><span class="text-blue font-bold">Ofrecemos experiencias de viaje sin preocupaciones</span>; Tenemos el mejor <b>#team</b> de profesionales que resuelve eficientemente cualquier imprevisto.</p>
        </div>
    </div>
    <div class="px-8 py-8 lg:px-16">
        <p class="text-blue font-bold text-center text-xl mb-16 lg:text-3xl">¿Por qué elegirnos?</p>
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-4">
            <div class="bg-blue text-white text-center rounded-xl mt-8 px-8 pb-8">
                <img src="../../../assets/img/quienes/03-01.png" class="bg-blue rounded-full p-4 mx-auto -mt-[40px] lg:-mt-[45px]">
                <p class="font-bold text-2xl">Atención Now</p>
                <p class="text-xs font-bold lg:font-medium lg:text-sm">Ofrecemos tranquilidad y cobertura ante cualquier eventualidad</p>
            </div>
            <div class="bg-blue text-white text-center rounded-xl mt-8 px-8 pb-8">
                <img src="../../../assets/img/quienes/03-02.png" class="bg-blue rounded-full p-4 mx-auto -mt-[40px] lg:-mt-[45px]">
                <p class="font-bold text-2xl">Know how</p>
                <p class="text-xs font-bold lg:font-medium lg:text-sm">Comprendemos las necesidades y expectativas de los viajeros, diseñamos planes a medida para cada uno de ellos.</p>
            </div>
            <div class="bg-blue text-white text-center rounded-xl mt-8 px-8 pb-8">
                <img src="../../../assets/img/quienes/03-03.png" class="bg-blue rounded-full p-4 mx-auto -mt-[40px] lg:-mt-[45px]">
                <p class="font-bold text-2xl">Partners</p>
                <p class="text-xs font-bold lg:font-medium lg:text-sm">Nuestras alianzas con socios estratégicos nos permiten ofrecer un servicio de excelencia a cada viajero</p>
            </div>
            <div class="bg-blue text-white text-center rounded-xl mt-8 px-8 pb-8">
                <img src="../../../assets/img/quienes/03-04.png" class="bg-blue rounded-full p-4 mx-auto -mt-[40px] lg:-mt-[45px]">
                <p class="font-bold text-2xl">Tecnología</p>
                <p class="text-xs font-bold lg:font-medium lg:text-sm">Nuestras herramientas y aplicaciones tecnológicas son flexibles y funcionales a cada necesidad.</p>
            </div>
        </div>    
    </div>
    <div class="px-8 py-8 lg:px-16 grid grid-cols-1 gap-8 lg:grid-cols-4">
        <div class="relative flex">
            <p class="mt-4">
                <span class="p-2 font-bold text-white bg-blue text-2xl lg:text-3xl lg:leading-[3rem]">Nuestros aliados&nbsp;<br/> &nbsp;indiscutibles</span>
            </p>
            <img src="../../assets/img/quienes/avion.png" class="hidden lg:block absolute bottom-0">
            <img src="../../assets/img/quienes/avion-mobile.png" class="lg:hidden absolute right-0">
        </div>
        <div class="lg:col-span-3">
            <swiper-container>
                <swiper-slide>
                    <div class="bg-[#0BB3A9] rounded-lg text-white flex flex-col sm:flex-row sm:items-center min-h-[350px] sm:min-h-[350px] p-4 lg:py-8 gap-4 lg:px-16 lg:gap-24">
                        <img src="../../assets/img/quienes/slider-01.png" class="max-w-[40%] xl:max-w-full lg:-pb-8">
                        <div class="self-center">
                            <p class="font-bold text-lg lg:text-xl">Customer Centricity</p>
                            <p class="text-sm lg:text-base">A través de una escucha activa y efectiva, identificamos y diseñamos planes de acción para mejorar y potenciar la experiencia de nuestros clientes, partners y stakeholders.</p>
    
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="bg-[#19BF46] rounded-lg text-white flex flex-col sm:flex-row sm:items-center min-h-[350px] sm:min-h-[350px] px-4 py-8 gap-4 lg:px-16 lg:gap-24">
                        <img src="../../assets/img/quienes/slider-02.png" class="max-w-[40%] xl:max-w-full lg:-pb-8">
                        <div class="self-center">
                            <p class="font-bold text-lg lg:text-xl">Trabajo Colaborativo</p>
                            <p class="text-sm lg:text-base">Tiramos para el mismo lado. Trabajamos alineados para superar los desafíos que tenemos como organización y conseguir resultados extraordinarios.</p>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="bg-[#9EBF19] rounded-lg text-white flex flex-col sm:flex-row sm:items-center min-h-[350px] sm:min-h-[350px] px-4 py-8 gap-4 lg:px-16 lg:gap-24">
                        <img src="../../assets/img/quienes/slider-03.png" class="max-w-[40%] xl:max-w-full lg:-pb-8">
                        <div class="self-center">
                            <p class="font-bold text-lg lg:text-xl">Movimiento Constante</p>
                            <p class="text-sm lg:text-base">No quedarnos quietos está en nuestra genética, buscamos desafiarnos constantemente y adaptarnos a los cambios del mercado de manera asertiva.</p>
                        </div>
                    </div>
                </swiper-slide>
              </swiper-container>
        </div>
    
    </div>
</div>
<app-footer></app-footer>