import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { LocalizationService } from "../../services/localization.service";
import { RouteService } from "../route.service";

export const LanguageResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const localizationService = inject(LocalizationService);
    const routeService = inject(RouteService);

    const currentLang = route.params['lang'];
    localizationService.setLanguage(currentLang);

    if(currentLang == localizationService.getDefaultLanguageByCountry(localizationService.getCountry())){
        routeService.setShowLang(false);
    }
    else{
        routeService.setShowLang(true);
    }
}