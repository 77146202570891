export class LocalStorageSources {
    public static readonly CLIENT = 'client';
    public static readonly DESTINATIONS = 'destinations';
    public static readonly COUNTRIES = 'countries';
    public static readonly QUOTE_ID = 'quoteId';
    public static readonly PURCHASE_ID = 'purchaseId';
    public static readonly PAYMENT_ID = 'paymentId';
    public static readonly PAX_DATA = 'paxData';
    public static readonly QUOTE = 'quote';
    public static readonly QUOTED_PRODUCTS = 'products';
    public static readonly SELECTED_PRODUCT = 'selectedProduct';
  }