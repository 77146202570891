<footer class="relative bg-blackFooter text-center text-white md:text-left">
  <img *ngIf="showAirplane" src="../../../assets/img/avion.png" alt="" class="absolute w-[50px] right-5 lg:right-10 -top-12 cursor-pointer" (click)="goUp()">
  <div class="p-4 md:px-16 md:py-8 lg:px-24 lg:py-6 2xl:px-40">
    <div class="grid grid-cols-1 md:grid-cols-5">
      <div class="col-span-1 md:col-span-2">
        <img src="../../../assets/img/footerLogo.png" alt="now assistance" class="w-[120px] mx-auto mb-4 md:mx-0" />
        <p class="my-4 text-sm" *ngIf="showButtons">
          <span class="mb-8 font-bold ">ARGENTINA</span> | BRASIL
        </p>
        <p class="mb-2 flex justify-center md:justify-start gap-4 text-center" *ngIf="showButtons">
          <a href="https://www.instagram.com/nowassistance/" target="_blank" class="flex h-[40px] w-[40px] items-center rounded-full bg-grey text-center">
            <fa-icon [icon]="faIg" class="m-auto"></fa-icon>
          </a>
          <a href="https://www.linkedin.com/company/104396984/admin/dashboard/" target="_blank" class="flex h-[40px] w-[40px] items-center rounded-full bg-grey text-center">
            <fa-icon [icon]="faLk" class="m-auto"></fa-icon>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61565010174859&mibextid=LQQJ4d" target="_blank" class="flex h-[40px] w-[40px] items-center rounded-full bg-grey text-center">
            <fa-icon [icon]="faFb" class="m-auto"></fa-icon>
          </a>
        </p>
      </div>
      <div class="col-span-1 md:col-span-3">
        <div class="grid grid-cols-1 lg:grid-cols-3 text-sm">
          <div class="flex flex-col">
            <div>
              <button class="inline-block w-auto text-left mb-2 hover:underline hover:underline-offset-4" *ngIf="showButtons" (click)="redirectToStatic('about-us')">Quiénes somos</button>
            </div>
            <div>
              <button class="inline-block text-left mb-2 hover:underline hover:underline-offset-4" *ngIf="showButtons" (click)="redirectToStatic('faq')">Preguntas Frecuentes</button>
            </div>
            <div>
              <button class="inline-block text-left mb-2 hover:underline hover:underline-offset-4" *ngIf="showButtons" (click)="redirectToStatic('contact')">Contacto</button>
            </div>
          </div>
          <div class="flex flex-col">
            <div>
              <button button class="inline-block text-left mb-2 hover:underline hover:underline-offset-4" *ngIf="showButtons" (click)="redirectToStatic('privacy')">Políticas de Privacidad</button>
            </div>
            <div>
              <button class="inline-block text-left mb-2 hover:underline hover:underline-offset-4" *ngIf="showButtons" (click)="redirectToStatic('terms')">Condiciones Generales</button>
            </div>
            <!-- <p class="mb-2" *ngIf="showButtons">Botón de arrepentimiento</p> -->
          </div>
          <!-- <div>
            <img src="../../../assets/img/footerQR.png" alt="QR" class="mx-auto mb-8 md:mx-0" />
          </div> -->
        </div>
      </div>
    </div>
    <hr class="my-4" />
    <p class="text-grey">2024 &#64; nowassistance. All rights reserved.</p>
  </div>
</footer>