export const environment = {
    production: true,
    token: '',
    mercadoPagoPublicKey: window["env"]["MERCADO_PAGO_PUBLIC_KEY"] || "default",
    bffBaseUrl: window["env"]["API_URL"] || "default",
    siteKey: window["env"]["CAPTCHA_SITE_KEY"] || "default",
    tagManagerId: window["env"]["TAG_MANAGER_ID"] || "default",
    DEFAULT_LANG: window["env"]["DEFAULT_LANG"] || "default",
    ENABLE_LANGUAGES: window["env"]["ENABLE_LANGUAGES"] || "default",
    default_client: window["env"]["DEFAULT_CLIENT"] || "default"
}; 