import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { LocalizationService } from "../../services/localization.service";

export const CountryResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const localizationService = inject(LocalizationService);
    const currentCountry = route.params['country'];
    localizationService.setCountry(currentCountry, true);   
}