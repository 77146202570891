import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { LocalStorageSources } from '../../core/enums/storage-sources';
import { ApiService } from '../../core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor() { }

  private localService = inject(LocalStorageService);
  private apiService = inject(ApiService);

  public getVoucherNumber(){
    const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
    const queryParams = '?purchaseId=' + purchaseId;
    return this.apiService.get('GetVoucher' + queryParams);
  }

  public getPurchaseDetails(){
    const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
    const queryParams = '?purchaseId=' + purchaseId;
    return this.apiService.get('GetPurchaseDetails' + queryParams);
  }

  getVoucherBuffer(){
    const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
    const queryParams = '?purchaseId=' + purchaseId;
    return this.apiService.get('GenerateVoucherPdf' + queryParams) 
  }

  downloadPdf(paxId: number){
    const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
    const request = {
      purchaseId,
      paxId
    }
    return this.apiService.post('DownloadVoucher', request);
  }

  sendVoucherByEmail(paxId: number){
    const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
    const request = {
      purchaseId,
      paxId
    }
    return this.apiService.post('SendVoucherByEmail', request);
  }
}
