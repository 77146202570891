import { Product } from "../../products/models/products";

export class QuoteRequest {
    constructor(
        public client: string,
        public starting: Date,
        public ending: Date,
        public origin: string[],
        public destination: string[],
        public passengers: number[],
        public contact: {
            name: string,
            email: string,
            phone: string
        }
    ){}

    static createQuoteRequest(): QuoteRequest {
        return new QuoteRequest('', new Date(), new Date(), [], [], [], {name: '', email: '', phone: ''});
    }
}

export class QuoteResponse{
    constructor(
        public quoteId: number,
        public products: Product[]
    ){}
}