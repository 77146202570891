import { Component, OnInit, Renderer2, inject } from '@angular/core';
import { VoucherService } from './voucher.service';
import { Purchase } from '../../core/models/purchase';
import { RouteService } from '../../core/route/route.service';
import { PurchaseDetails, QuoteDetails } from '../../core/models/purchase-details';
import { DownloadService } from '../../core/services/download.service';
import { catchError, of, Subject, takeUntil} from 'rxjs';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faDownload, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ActivatedRoute } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule} from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent, 
    NgxTippyModule, 
    FontAwesomeModule,
    LoaderComponent,
    MatProgressSpinnerModule,
    MatIconModule
  ]
})
export class VoucherComponent implements OnInit {

  private destroy$ = new Subject<void>();

  faWp = faWhatsapp;
  faDownload = faDownload;
  faMail = faEnvelope;

  private purchase!: Purchase;
  private voucherNumber!: string;

  private downloadService = inject(DownloadService);
  private voucherService = inject(VoucherService);
  private routeService = inject(RouteService);
  private snackBar = inject(MatSnackBar);
  private activatedRoute = inject(ActivatedRoute);

  passengers: VoucherPassengers[] = [];
  quoteDetails!: QuoteDetails;
  paxAmount: number = 0;
  protected purchaseDetails!: PurchaseDetails;
  protected dataIsReady = false;
  protected detailCountries: string = '';
  protected error = false;
  protected errorMessage = '';

  constructor() {
  }

  ngOnInit() {
    this.activatedRoute.data
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(({ purchaseDetails }) => {
      this.purchaseDetails = purchaseDetails;
      this.voucherNumber = purchaseDetails.voucherNumber;
      this.detailCountries = purchaseDetails.quote.destination.join(', ');
      this.paxAmount = purchaseDetails.passengers.length;
      this.passengers = purchaseDetails.passengers;
      this.dataIsReady = true;
    });
  }

  setPassengers(passengers : any[]){
    passengers.forEach((pax: any) => {
      this.passengers.push({
        paxId: pax.paxId,
        name: pax.name,
        lastName: pax.lastName,
        downloadingVoucher: false,
        sendingByEmail: false
      });
    })
  }

  downloadVoucher(paxId: number) {
    this.error = false;
    this.passengers.find(pax => pax.paxId === paxId)!.downloadingVoucher = true;
    this.voucherService.downloadPdf(paxId)
    .pipe(
      takeUntil(this.destroy$),
      catchError((err: any) => {
        this.showError('Lo sentimos, algo salió mal con el proceso de descarga.');
        this.passengers.find(pax => pax.paxId === paxId)!.downloadingVoucher = false;
        return of(null);
      })
    )
    .subscribe((response) => {
        if(!response) return;

        this.downloadService.downloadPdf(response.buffer, 'voucher', 'pdf');
        this.passengers.find(pax => pax.paxId === paxId)!.downloadingVoucher = false;
    });
  }

  sendVoucherByEmail(paxId: number){
    this.error = false;
    this.passengers.find(pax => pax.paxId === paxId)!.sendingByEmail = true;
    this.voucherService.sendVoucherByEmail(paxId)
    .pipe(
      takeUntil(this.destroy$),
      catchError((err: any) => {
        this.showError('Lo sentimos, algo salió mal con el proceso de envío.')
        this.passengers.find(pax => pax.paxId === paxId)!.sendingByEmail = false;
        return of(null);
      })
    )
    .subscribe((response) => {
      if(!response) return;

      this.passengers.find(pax => pax.paxId === paxId)!.sendingByEmail = false
      this.snackBar.openFromComponent(SnackbarComponent,
        {
          data: 'Voucher enviado con exito!',
          duration: 3000
        });
    });
  }

  downloadPdf(){
    this.voucherService.getVoucherBuffer()
    .pipe(
      catchError((err: any) => {
        this.error = true;
        return of(null);
      })
    )
    .subscribe((buffer: any) => {

      if(!buffer) return;

      this.error = false;
      this.downloadService.downloadPdf(buffer, 'voucher', 'pdf');
    });
  }

  showError(message: string){
    this.errorMessage = message;
    this.error = true;
    setTimeout(() => {
      this.error = false;
    }, 3000);
  }
}

interface VoucherPassengers{
  paxId: number;
  name: string;
  lastName: string;
  downloadingVoucher: boolean;
  sendingByEmail: boolean;
}