export class QuoteModel {
    constructor(
        public origin: any,
        public destinations: any[],
        public starting: any,
        public ending: any,
        public passengerAges: any[],
        public contactName: any,
        public contactEmail: any,
        public contactPhone: any
    ){}

    static createModel(){
        return new QuoteModel(0, [], new Date(), new Date(), [''], '', '', '');
    }
}