<div *ngIf="dataIsReady">
    <app-header
        [showQuoteButton]="false" 
        [showLogin]="false" 
        [showLangs]="false" 
    ></app-header>
    <app-progress-bar
        activeStep = 1
    ></app-progress-bar>
    <div class="mx-4 md:mx-8 lg:mx-24 2xl:mx-40">
        <div class="my-4 gap-2 grid grid-cols-1 lg:gap-4 lg:grid-cols-4">
            <h1 class="text-2xl text-center lg:text-left font-bold text-blue lg:col-span-3">
                Completá los datos y finalizá tu compra
            </h1>
            <passenger-data-form 
                class="lg:col-span-3"
                [passengersAges] = "passengerAges"
                (passengerDataSubmited)="onPassengerDataSubmited($event)"
            ></passenger-data-form>
            <app-purchase-resume 
                class="h-min rounded-lg shadow border border-grey3 lg:order-last mb-4"
                [paxPage]="true">
            </app-purchase-resume>
            <div class="block lg:hidden mt-2 text-center lg:text-right">
                <button class="rounded-lg bg-blue px-8 py-4 font-bold text-white disabled:bg-[#D5D5D5] hover:bg-[#1D1191] transition-colors hover:easy-in duration-300"
                    (click)="submitPaxForm()">
                    CONTINUAR
                </button>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
<app-loader *ngIf="!dataIsReady"></app-loader>



