import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, inject, Renderer2 } from '@angular/core';
import { AUTO_STYLE, animate, state, style, transition, trigger } from "@angular/animations";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faPenToSquare
} from "@fortawesome/free-solid-svg-icons";
import { QuoteFormComponent } from '../../../../shared/components/quote-form/quote-form.component';
import { QuoteService } from '../../../quote/services/quote.service';
import { QuoteModel } from '../../../quote/models/quote.model';
import { Subject, catchError, of, takeUntil } from 'rxjs';
import { NoYearDatePipe } from '../../../../core/pipes/date-no-year.pipe';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    FontAwesomeModule,
    QuoteFormComponent,
    NoYearDatePipe
  ],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('true => false', animate(300 + 'ms ease-in')),
      transition('false => true', animate(300 + 'ms ease-out'))
    ])
  ]
})
export class QuoteDetailsComponent implements OnInit{

  @Output() quoteRequest = new EventEmitter<QuoteModel>();

  private quoteService = inject(QuoteService);

  private destroy$ = new Subject<void>();

  private renderer = inject(Renderer2);

  mobile: boolean = false;
  previousQuote: QuoteModel = QuoteModel.createModel();
  countries: any[] = [];

  countriesLabel: string = '';
  countriesLabelMobile: string = '';

  viajeros = 0;
  viajeroLabel = '';

  days = 0;

  cotizadorOpened = false;
  faPen = faPenToSquare;

  ngOnInit() {
    if (window.screen.width <= 640) { 
      this.mobile = true;
    }

    const storedQuote : QuoteModel = this.quoteService.getStoredQuote();
    if(storedQuote){
      this.previousQuote = storedQuote;
      this.generateTravelersLabel();
      this.generateDaysLabel();
    }

    this.quoteService.getEnabledCountries()
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe((countriesResponse: any) =>{
        if(countriesResponse){
          this.countries = countriesResponse;
          this.generateCountriesLabel();
        }
      });
  }

  toggleCotizador() {
    this.cotizadorOpened = !this.cotizadorOpened;

    if (this.mobile) {
      this.renderer.addClass(document.body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

  generateCountriesLabel(){
    const countries = this.countries.filter((country: any) => this.previousQuote?.destinations.includes(country.countryCode));
    
    if(countries.length > 0){
      this.countriesLabel = countries.map((country: any) => country.countryName).slice(0, 3).join(', ');
      if(this.previousQuote && (this.previousQuote.destinations.length > 3)){
        this.countriesLabel += '+' + (this.previousQuote.destinations.length - 3);
      }

      this.countriesLabelMobile = countries.map((country: any) => country.countryName).slice(0, 1).join(', ');
      if(this.previousQuote && this.previousQuote.destinations.length > 1){
        this.countriesLabelMobile += ' +' + (this.previousQuote.destinations.length - 1);
      }
    }
  }

  generateTravelersLabel(){
    this.viajeros = this.previousQuote.passengerAges.length;
    if(this.viajeros > 1){
      this.viajeroLabel = 'viajeros';
    }
    else{
      this.viajeroLabel = 'viajero';
    }
  }

  generateDaysLabel(){
    if(this.previousQuote.starting && this.previousQuote.ending){
      const startDate = new Date(this.previousQuote.starting);
      const endDate = new Date(this.previousQuote.ending);
      const days = Math.abs((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
      this.days = days + 1;
      return;
    }
    this.days = 0;
  }

  onQuoteRequest(quoteModel: QuoteModel){
    this.cotizadorOpened = false;
    this.previousQuote = quoteModel;
    this.generateCountriesLabel();
    this.generateTravelersLabel();
    this.generateDaysLabel();
    this.quoteRequest.emit(quoteModel);

    if (this.mobile) {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

}
