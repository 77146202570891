import { Injectable, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { RouteService } from '../../../core/route/route.service';
import { Routes } from '../../../core/enums/routes';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { LocalStorageSources } from '../../../core/enums/storage-sources';
import { PaymentRoute } from '../../../core/route/routes/route-parser';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  

  constructor() { }

  private apiService = inject(ApiService);
  private routeService = inject(RouteService);
  private localService = inject(LocalStorageService);

  getInitializationData() {  
      const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
      let queryParams = '?purchaseId=' + purchaseId;
      return this.apiService.get('GetPurchaseDetails' + queryParams);
  }

  public validateDiscount(cupon: string){
    const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
    const client = this.routeService.getClient();

    const requestBody = {
      client:client,
      code: cupon,
      purchaseId: Number(purchaseId)
    }

    return this.apiService.post('ValidateDiscount', requestBody);
  }

  public createPaymentRequest(){
    const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);

    const paymentRequestModel = {
      purchaseId: purchaseId,
      cupons: ""
    };
    
    return this.apiService.post('CreatePaymentRequest', paymentRequestModel);
  }

  public savePaymentId(paymentRequestId: number){
    this.localService.setItem(paymentRequestId.toString(), LocalStorageSources.PAYMENT_ID);
  }

  public redirectToPayment(){
    this.routeService.resolveRedirection(new PaymentRoute());
  }

  public returnToQuote(){
    this.routeService.resolveRedirection(null);
  }

}
