<div class="flex w-full justify-between bg-blue py-4 px-8 lg:pt-4 lg:px-24 2xl:px-40"
    [ngClass]="activeStep == 0 ? 'lg:pb-12': 'lg:pb-4'">
    <div class="relative flex flex-1 flex-col items-start">
        <div class="z-10 text-xs text-white lg:text-sm">
            <p>Paso 1</p>
            <p class="hidden font-bold md:block" [ngClass]="activeStep >= 0 ? 'text-green' : ''">
                ELEGÍ TU PLAN
            </p>
            <p class="block font-bold md:hidden" [ngClass]="activeStep >= 0 ? 'text-green' : ''">
                TU PLAN
            </p>
        </div>
        <div class="stepper-item-e z-10 flex h-[30px] w-[30px] items-center rounded-full text-center"
            [ngClass]="activeStep >= 0 ? 'bg-green' : 'bg-white'">
            <p class="w-full text-center text-blue">1</p>
        </div>
    </div>
    <div class="relative flex flex-1 flex-col items-center">
        <div class="text-xs text-white lg:text-sm">
            <p>Paso 2</p>
            <p class="hidden font-bold md:block" [ngClass]="activeStep >= 1 ? 'text-green' : ''">
                DATOS DE LOS VIAJEROS
            </p>
            <p class="block font-bold md:hidden" [ngClass]="activeStep >= 1 ? 'text-green' : ''">
                VIAJEROS
            </p>
        </div>
        <div class="stepper-item-c z-10 flex h-[30px] w-[30px] items-center rounded-full text-center"
            [ngClass]="activeStep >= 1 ? 'bg-green' : 'bg-white'">
            <p class="w-full text-center text-blue">2</p>
        </div>
    </div>
    <div class="relative flex flex-1 flex-col items-end">
        <div class="text-xs text-white lg:text-sm">
            <p>Paso 3</p>
            <p class="font-bold" [ngClass]="activeStep >= 2 ? 'text-green' : ''">
                PAGO
            </p>
        </div>
        <div class="stepper-item-s z-10 flex h-[30px] w-[30px] items-center rounded-full text-center"
            [ngClass]="activeStep == 2 ? 'bg-green' : 'bg-white'">
            <p class="w-full text-center text-blue">3</p>
        </div>
    </div>
</div>