import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { PaymentService } from '../../services/payment.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { loadMercadoPago } from '@mercadopago/sdk-js';
import { MercadopagoService } from '../../services/mercadopago.service';
import { Subject, catchError, of, takeUntil } from 'rxjs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatIconModule
  ]
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  private paymentService = inject(PaymentService);
  private formBuilder = inject(FormBuilder)
  private mercadoPagoService = inject(MercadopagoService);

  private destroy$ = new Subject<void>();

  private publicKey = environment.mercadoPagoPublicKey;
  private mp : any;
  private cardForm: any;
  private formMounted: boolean = false;

  errorOnPayment: boolean = false;
  termsAccepted: boolean = false;
  paymentLoading: boolean = false;

  displayedErrorMessage : string = '';
  installmentsErrorMessage : string = 'Lo sentimos, no pudimos encontrar una financiación para tu producto.';
  standardErrorMessage : string = 'Lo sentimos, algo salió mal con el proceso de pago. Por favor, volvé a cargar los datos.';
  
  @Output() formSubmited = new EventEmitter<FormGroup>();

  constructor() { 
  }

  async ngOnInit() {
    await loadMercadoPago();
    this.mp = await new window.MercadoPago(this.publicKey, {locale: 'es-AR'}); 
    this.mercadoPagoService.getPurchaseAmount()
    .pipe(
      takeUntil(this.destroy$),
      catchError((error) => {
        return of(null);
      })
    )
    .subscribe((paymentDetails: any) =>{
      if(!paymentDetails) return;

      this.createMercadoPago(paymentDetails.finalPrice);
    });
  }

  async ngOnDestroy() {
    this.unmountForm();
    this.destroy$.next();
    this.destroy$.complete();
  }

  createMercadoPago(amount: number) {
    this.cardForm = this.mp.cardForm({
      amount: String(amount.toString()),
      iframe: true,
      form: {
        id: "form-checkout",
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Numero de tarjeta",
          style: {
            "font-size": "0.85rem",
            "padding-left": "0.5rem"    
          }
        },
        expirationDate: {
          id: "form-checkout__expirationDate",
          placeholder: "MM/YY",
          style: {
            "font-size": "0.85rem",
            "padding-left": "0.5rem"
          }
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de seguridad",
          style: {
            "font-size": "0.85rem",
            "padding-left": "0.5rem"
          }
        },
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular de la tarjeta",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Banco emisor",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Cuotas",
        },        
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número del documento",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail",
        },
      },
      callbacks: {
        onReady: () => {
        },
        onFormMounted: (error: any) => {
          this.formMounted = true;
        },
        onSubmit: async (event: any) => {
          event.preventDefault();

          if(this.errorOnPayment){
            await this.cardForm.createCardToken();           
          }        

          const formData = this.cardForm.getCardFormData();

          this.mercadoPagoService.createPayment(formData)
          .pipe(
            takeUntil(this.destroy$),
            catchError((error) => {
              this.showError(this.standardErrorMessage);
              return of(null);
            })
          )
          .subscribe((response: any) => {
            if(!response) return;
            
            if(response.status == 'approved'){
              this.paymentLoading = false;
              this.mercadoPagoService.endPayment();
            }
          });           
        },
        onInstallmentsReceived: (error : any, data : any) => {
          if(error){
            this.showError(this.installmentsErrorMessage);
            return;            
          }
        },
        onError: (error: any) => {
          this.showError(this.standardErrorMessage);
          return;
        },
      }
    });
  }

  submitForm(): void {
    this.paymentLoading = true;
    this.cardForm.submit();
  }

  showError(message: string = '') {
    this.displayedErrorMessage = message;
    this.errorOnPayment = true;
    this.paymentLoading = false;
  }

  unmountForm(){
    if(this.formMounted){
      this.cardForm.unmount();
      this.formMounted = false;
    }
  }

  checkTerms($event: any) {
    this.termsAccepted = $event.target.checked;
  }

  openTermsPage(){
    const url = `${window.location.origin}/ar/condiciones`;
    window.open(url, '_blank');
  }

  openPolicyPage(){
    const url = `${window.location.origin}/ar/privacidad`;
    window.open(url, '_blank');
  }
}
