import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation, inject, input } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleUp } from '@fortawesome/free-regular-svg-icons';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxTippyModule, NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';
import { CalendarModalComponent } from '../calendar-modal/calendar-modal.component';
import { QuoteService } from '../../../feature/quote/services/quote.service';
import { QuoteModel } from '../../../feature/quote/models/quote.model';
import { Subject, catchError, of, takeUntil } from 'rxjs';
import { Routes } from '../../../core/enums/routes';
import { RouteService } from '../../../core/route/route.service';
import { ProductRoute } from '../../../core/route/routes/route-parser';
import { NumberOnlyDirective } from '../../../core/directives/number-only.directive';

@Component({
  selector: 'app-quote',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgMultiSelectDropDownModule,
    FontAwesomeModule,
    NgxTippyModule,
    NumberOnlyDirective
  ],
  encapsulation: ViewEncapsulation.None, // Multiselect
})
export class QuoteFormComponent implements OnInit, AfterViewInit {

  @Input() buttonText: string = "COTIZAR";
  @Input() home:boolean = false;
  @Input() mobile:boolean = false;
  @Input() extendedQuote: boolean = false;
  @Output() close = new EventEmitter();
  @Output() quoteRequest = new EventEmitter<QuoteModel>();

  @ViewChild('multiselect', { static: true }) toggleCountriesButton: any;
  @ViewChild('passengersButton', { static: true }) togglePassengersButton: any;
  @ViewChild('flagButton', { static: true }) toggleFlagButton: any;

  private destroy$ = new Subject<void>();
  private quoteService = inject(QuoteService);
  private routeService = inject(RouteService);

  MINIMUM_AGE = 0;
  MAXIMUM_AGE = 99;
  MAX_PASSENGERS = 10;

  showCountries: boolean = false;
  showDates: boolean = false;
  showPassengers: boolean = false;
  showFlags: boolean = false;

  faUpArrow = faCircleUp;

  passengers: Array<Passenger> = [{
    age: null,
    validAge: true
  }];

  validDestination: boolean = true;
  validPassenger: boolean = true;
  validDate: boolean = true;
  validName: boolean = true;
  validEmail: boolean = true;
  validPhone: boolean = true;

  disabledSubmitButton: boolean = true;
  disabledPassengerButton: boolean = true

  startDate: Date | string = "";
  endDate: Date | string = "";

  dropdownList: Array<Object> = [];
  selectedItems: Array<Object> = [];

  dropdownSettings = {
    singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      enableCheckAll: false,
      itemsShowLimit: 9,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      searchPlaceholderText: 'Escribí aquí...',
      noDataAvailablePlaceholderText: 'Sin datos',
      noFilteredDataAvailablePlaceholderText: 'Ningún país coincide con la búsqueda',
  };

  flags= [{
    text: 'AR',
    img: '../../../../assets/img/r-arg.png',
    phonePrefix: '+54'
  },{
    text: 'BR',
    img: '../../../../assets/img/r-bra.png',
    phonePrefix: '+55'
  }];

  selectedFlag = this.flags[0];
  name: string = "";
  email: string = "";
  phone: string = "";

  public tippyPropsContent: NgxTippyProps = {
    arrow: false,
    trigger: 'click',
    interactive: true,
    placement: 'bottom',
    theme: 'light',
    offset: [0, 0],
    appendTo: 'parent', 
    popperOptions: {
      modifiers: [
        {
            name: 'sameWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: [ 'computeStyles' ],
            fn({ state }) {
                state.styles['popper'].width = `${state.rects.reference.width}px`;
            },
            effect({ state }) {
              let ref = state.elements.reference as HTMLElement;
                state.elements.popper.style.width = `${
                    ref.offsetWidth
                }px`;
            }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: []
          }
        }    
      ],
    },
    onShow: (instance) => {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      if((windowHeight < 768 && windowWidth >= 1024) || (windowHeight < 800 && windowWidth < 1024)){
        setTimeout(() => {
          document.getElementById('tooltip-apply')?.scrollIntoView({behavior: 'smooth', block: 'center'});
        }, 50); 
      }   
    },
    onHide : (instance) => { this.showPassengers = false; }
  };

  public phoneTippyPops: NgxTippyProps = {
    placement: 'bottom',
    trigger: 'click',
    interactive: true,
    offset: [0, 8],
    theme: 'light',
    onShow: (instance) => { this.showFlags = true; },
    onHide : (instance) => { this.showFlags = false; }
  }

  constructor(
    private dialog: MatDialog,
    private tippyService: NgxTippyService,
    private elem: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click',(e:Event)=>{
      let target = e.target as HTMLTextAreaElement;
      
      if(this.toggleCountriesButton.nativeElement.contains(e.target)) {
        this.showPassengers = false;
        this.showFlags = false;
        return;
      };

      let tippyElement = this.elem.nativeElement.querySelector('#tippy-2');
      if(this.togglePassengersButton.nativeElement.contains(e.target) ||
        ( tippyElement && tippyElement.contains(e.target) ) ) {
        this.showCountries = false;
        this.showFlags = false;
        return;
      };

      if(this.toggleFlagButton && this.toggleFlagButton.nativeElement.contains(e.target)) {
        this.showCountries = false;
        this.showPassengers = false;
        return;
      }

      let element = this.elem.nativeElement.querySelector('.dropdown-list');
      element.setAttribute('hidden', true);
      this.showCountries = false;
      this.showPassengers = false;
  });
  }

  ngOnInit() { 
    this.disabledSubmitButton = true;
    const storedQuote : QuoteModel = this.quoteService.getStoredQuote();
    let previousCountries : any = null;

    if((storedQuote && this.routeService.getLastPage() instanceof ProductRoute) || this.routeService.getCurrentPage() instanceof ProductRoute){
      this.startDate = storedQuote.starting;
      this.endDate = storedQuote.ending;
      this.passengers = storedQuote.passengerAges.map((age) => {
        return { age: age, validAge: true}
      });
      previousCountries = storedQuote.destinations;
    };

    this.quoteService.getEnabledCountries()
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.routeService.resolveRedirection(null);
          return of([]);
        })
      )
      .subscribe((countriesResponse: any) =>{
        this.dropdownList = countriesResponse.map((country: any) => {
          return {
            item_id: country.countryCode,
            item_text: country.countryName
          }
        });

        if(previousCountries){
          this.selectedItems = this.dropdownList.filter((country: any) => {
            return previousCountries.includes(country.item_id);
          })
        }
        this.updateButtonState();

      });

      this.updateButtonState();
  }

  ngAfterViewInit() {
    this.setPropsForTooltip();
  }

  setPropsForTooltip() {
    this.tippyPropsContent = {
      ...this.tippyPropsContent
    };
  }

  openMultiselect(event: any) {
    let element = this.elem.nativeElement.querySelector('.dropdown-list');
    if(event.target.classList.contains('out')){
      element.toggleAttribute('hidden');
    }
    this.showCountries = !element.hasAttribute('hidden');
    this.elem.nativeElement.querySelector('.filter-textbox').querySelector('input').focus();
    this.elem.nativeElement.querySelector('.filter-textbox').scrollIntoView({behavior: 'smooth', block: 'center'});
    this.updateButtonState();
  }

  onItemSelect(item: any) {
    this.validDestination = true;
    this.validateDestination();
    this.updateButtonState();
  }

  onSelectAll(items: any) {
    
  }

  openCalendar() {
    this.showDates = !this.showDates;
    const dialogRef = this.dialog.open(
      CalendarModalComponent, 
      { 
        data: { 
          previousStartDate: this.startDate,
          previousEndDate: this.endDate
        },
        minWidth: '310px' 
      });
    dialogRef.afterClosed().subscribe((data) => {
      this.showDates = !this.showDates;
      this.startDate = data.startDate ?? this.startDate;
      this.endDate = data.endDate ?? this.endDate;
      if(this.startDate && this.endDate){
        this.validateDate();
      }
      this.updateButtonState();
    });
  }

  hideTippy() {
    this.tippyService.hideAll();
    this.showPassengers = false;
  }

  togglePassengerCaret() {
    this.showPassengers = !this.showPassengers;
  }

  focusInput(input:any){
    input.focus();
  }

  selectFlag(flag:any) {
    this.selectedFlag = flag;
    this.tippyService.hideAll();
    this.showFlags = false;
  }

  quote() {

    if(!this.validateQuoteRequest()){
      return;
    }

    const quoteModel = QuoteModel.createModel();
    quoteModel.origin = "AR"
    quoteModel.starting = this.startDate;
    quoteModel.ending = this.endDate;
    quoteModel.destinations = this.selectedItems.map((item: any) => item.item_id);
    quoteModel.passengerAges = this.passengers.map((passenger) => passenger.age);

    quoteModel.contactName = this.name;
    quoteModel.contactEmail = this.email;
    quoteModel.contactPhone = this.phone;

    this.quoteRequest.emit(quoteModel);
  }

  editPassengers(add: boolean) {
    if (add && this.passengers.length < this.MAX_PASSENGERS) {
      this.passengers.push({ age: null, validAge: true});
    } 
    
    if(!add && this.passengers.length > 1){
      this.passengers.pop();
    }
    this.passengerButtonState();
    this.updateButtonState();
  }

  closeCotizador(){
    this.close.emit();
    if (this.mobile) {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    } 
  }

  onAgeChange(index: number, age: number | null) {
    if (age === null || age < this.MINIMUM_AGE || age > this.MAXIMUM_AGE) {
        this.passengers[index].validAge = false;
    } else {
        this.passengers[index].age = age;
        this.passengers[index].validAge = true;
    }
    
    this.validatePassengers();
    this.updateButtonState();
  }


  validateQuoteRequest() {
    this.validatePassengers();
    this.validateDate();
    this.validateDestination();
  
    if (this.extendedQuote) {
      this.validateName();
      this.validateEmail();
      this.validatePhone();
    }
  
    this.updateButtonState();
  
    return (
      this.validDate &&
      this.validDestination &&
      this.validPassenger &&
      (!this.extendedQuote || (this.validName && this.validEmail && this.validPhone))
    );
  }

  validatePassengers() {
    this.validPassenger = true;

    this.passengers.forEach((passenger) => {
      if(passenger.age == null){
        passenger.validAge = false;
        this.validPassenger = false;
      }
      else{
        if (passenger.age >= this.MINIMUM_AGE && passenger.age <= this.MAXIMUM_AGE) {
          passenger.validAge = true;
          this.validPassenger = true;
        }
        else{
          passenger.validAge = false;
          this.validPassenger = false;
        }
      }
    });

    if(this.passengers.length == 0){
      this.validPassenger = false;
    }
    this.updateButtonState();
  }

  validateDate(){
    this.validDate = true;

    if(!this.startDate || !this.endDate){
      this.validDate = false;
    }

    if(this.startDate <= new Date()){
      this.validDate = false;
    }

    if(this.endDate < this.startDate){
      this.validDate = false;
    }
    this.updateButtonState();
  }

  validateDestination() {
    this.validDestination = this.selectedItems.length > 0;
    this.updateButtonState();
}

  validateName() {
    const namePattern = /^[\p{L}\s]+$/u;
    this.validName = namePattern.test(this.name);
    this.updateButtonState();
  }
  
  validateEmail() {
    const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    this.validEmail = emailPattern.test(this.email);
    this.updateButtonState();
  }

  validatePhone() {
    const phonePattern = /^[0-9]*$/;
    this.validPhone = phonePattern.test(this.phone) && this.phone.length > 4 && this.phone.length < 12;
    this.updateButtonState();
  }

  updateButtonState() {
    const destinationValid = this.selectedItems.length > 0;
    const startDateValid = !!this.startDate;
    const endDateValid = !!this.endDate;
    const passengersValid = this.passengers.length > 0 && this.passengers.every(passenger =>
      passenger.age !== null && passenger.age >= this.MINIMUM_AGE && passenger.age <= this.MAXIMUM_AGE
    );

    this.disabledSubmitButton = !(destinationValid && startDateValid && endDateValid && passengersValid);
    this.disabledPassengerButton = !passengersValid;
  }

  passengerButtonState() {
    const passengersValid = this.passengers.length > 0 && this.passengers.every(passenger =>
      passenger.age !== null && passenger.age >= this.MINIMUM_AGE && passenger.age <= this.MAXIMUM_AGE
  );
    this.disabledPassengerButton = !passengersValid;
  }
}

export interface Passenger {
  age: number | null;
  validAge: boolean;
}
