import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideRouter } from "@angular/router";
import localeAr from "@angular/common/locales/es-AR";
import { IMAGE_CONFIG, registerLocaleData } from "@angular/common";
import { ApplicationConfig, LOCALE_ID } from "@angular/core";
import { routes } from "./app.routes";
import { provideHttpClient, withFetch } from "@angular/common/http";
import { provideTransloco } from "@ngneat/transloco";
import { TranslocoHttpLoader } from "./transloco-loader";
import { environment } from "../environments/environment";
import { RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
registerLocaleData(localeAr);

export const appConfig: ApplicationConfig = {
    providers: [
      provideRouter(routes),
      provideAnimationsAsync(),
      provideHttpClient(withFetch()),
      provideTransloco({
        loader: TranslocoHttpLoader,
        config: {
          availableLangs: ['en', 'es', 'pt'],
          defaultLang: 'es',
          fallbackLang: 'es',
          // Remove this option if your application doesn't support changing language in runtime.
          reRenderOnLangChange: true,
          prodMode: environment.production,
        }
      }),
      { provide: LOCALE_ID, useValue: "es-AR" },
      { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.siteKey },
      { provide: IMAGE_CONFIG,
        useValue: {
          disableImageSizeWarning: true, 
          disableImageLazyLoadWarning: true
        }
      },
    ]
    
  };