import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2, inject } from '@angular/core';
import { HeaderComponent } from '../../../../shared/components/header/header.component';
import { QuoteFormComponent } from '../../../../shared/components/quote-form/quote-form.component';
import { FooterComponent } from '../../../../shared/components/footer/footer.component';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { RouteService } from '../../../../core/route/route.service';
import { QuoteService } from '../../services/quote.service';
import { LocalStorageSources } from '../../../../core/enums/storage-sources';
import { Subject, catchError, map, mergeMap, of, switchMap, takeUntil } from 'rxjs';
import { QuoteModel } from '../../models/quote.model';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { VendeNowComponent } from '../../../../shared/components/vende-now/vende-now.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { CaptchaService } from '../../../../core/services/captcha.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationService } from '../../../../core/services/localization.service';
import { ProductRoute } from '../../../../core/route/routes/route-parser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [CommonModule, HeaderComponent, QuoteFormComponent, FooterComponent, LoaderComponent, MatDialogModule, RecaptchaV3Module]
})
export class HomeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private quoteService = inject(QuoteService);
  private localStorageService = inject(LocalStorageService);
  private dialog = inject(MatDialog)
  private recaptchaV3Service = inject(ReCaptchaV3Service);
  private internalCaptchaService = inject(CaptchaService);
  private localizationService = inject(LocalizationService);
  protected dataIsReady: boolean = false;
  private activatedRoute = inject(ActivatedRoute);
  private routeService = inject(RouteService);

  vendeNow() {
    this.dialog.open(VendeNowComponent ,
      {
        width: '500px',
        maxWidth: '90vw'
      }
    );
  }

  constructor() { }

  ngOnInit() {
    this.localStorageService.setItem(LocalStorageSources.CLIENT, this.routeService.getClient()); 
    this.dataIsReady = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onQuoteRequest(quoteModel: QuoteModel){
    this.dataIsReady = false;

    const quoteRequest = this.quoteService.quoteProducts(quoteModel, this.localizationService.getLanguage())
    .pipe(
        takeUntil(this.destroy$),           
        catchError((error) => {
          this.dataIsReady = true;
            return of(null);
        })
    );

    this.recaptchaV3Service.execute('QUOTE')
    .pipe(
      map(token => token),
      mergeMap(token => this.internalCaptchaService.verifyCaptchaToken(token).pipe(map(response => response.success))),
      catchError(error => of(null))
    )
    .subscribe(captchaValid => {
      if(!captchaValid) {
        this.dataIsReady = true;
        return;
      }

      quoteRequest.subscribe((quoteResponse) => {      
        if(!quoteResponse) return;

        this.quoteService.storeQuoteId(quoteResponse.quoteId);
        this.quoteService.storeQuotedProducts(quoteResponse.products);
        this.routeService.resolveRedirection(new ProductRoute());
        this.dataIsReady = true;
      });
    });    
  }
}
