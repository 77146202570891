import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";
import { environment } from "./environments/environment";
import { bodyGmtScript, gtmScript } from "./app/core/utils/gtm-script";

if(environment.production){
  const script = document.createElement('script');
  script.innerHTML = gtmScript(environment.tagManagerId);
  document.head.appendChild(script);
  
  const gmtNoScript = document.createElement('noscript'); 
  gmtNoScript.appendChild(bodyGmtScript(environment.tagManagerId));
  document.body.appendChild(gmtNoScript);
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
