<div [ngClass]="{'hidden' : !dataIsReady}">
    <app-header
        [showQuoteButton]="false" 
        [showLogin]="false" 
        [showLangs]="false" 
    ></app-header>
    <app-progress-bar
        activeStep = 0
    ></app-progress-bar>
    <app-quote-details
        (quoteRequest) = "onQuoteRequest($event)"
    ></app-quote-details>

    <div class="mx-4 md:mx-8 lg:mx-24 2xl:mx-40">
        <app-product-card
            [products] = "quotedProducts"
            (productPurchased) = "onProductPurchased($event)"
        ></app-product-card>
    </div>
    <app-footer></app-footer>
</div>
<app-loader [ngClass]="{'hidden' : dataIsReady}"></app-loader>