import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { ApiService } from '../../../core/services/api.service';
import { PassengerData } from '../models/passenger-data.model';
import { Pax, SavePassengerRequest } from '../models/passenger';
import { RouteService } from '../../../core/route/route.service';
import { Routes } from '../../../core/enums/routes';
import { LocalStorageSources } from '../../../core/enums/storage-sources';

@Injectable({
    providedIn: 'root'
})
export class PassengersService {
    private localService = inject(LocalStorageService);
    private apiService = inject(ApiService);
    private routeService = inject(RouteService);

    constructor() { }

    public getInitializationData(){
        const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
        const queryParams = '?purchaseId=' + purchaseId;
        return this.apiService.get('GetPurchaseDetails' + queryParams);
    }

    public getSelectedProduct(){
        const product = this.localService.getItemByKey('selectedProduct');
        if(!product){
            return null;
        }
        return product;
    }

    public savePassengersData(passengerData: PassengerData[], uniqueEmergencyContact: boolean){
        const discountCode = this.localService.getDiscountCode();
        const client = this.routeService.getClient();
        const request = this.convertToPaxSaveRequest(passengerData, discountCode, uniqueEmergencyContact, client);

        return this.apiService.post('SavePaxData', request);
    }

    public savePassengersDataToLocalStorage(passengerData: PassengerData[]){
        this.localService.setItem(JSON.stringify(passengerData), 'passengersData');
    }

    private convertToPaxSaveRequest(passengerData: PassengerData[], discountCode : string | null, uniqueEmergencyContact: boolean, client: string){
        const purchaseId = this.localService.getItemByKey(LocalStorageSources.PURCHASE_ID);
        if(!purchaseId || purchaseId == ""){this.returnToQuote(); return;}

        var passengers : Pax[] = [];

        passengerData.forEach(pax => {
            passengers.push({
                name: pax.name,
                lastName: pax.surname,
                nationality: pax.nationality,
                documentType: pax.documentType,
                documentNumber: pax.documentNumber,
                birthDate: new Date(pax.birthDate),
                email: pax.email,
                phonePrefix: pax.phonePrefix,
                phone: pax.phone,
                emergencyContactName: pax.emergencyContact.name,
                emergencyContactLastName: pax.emergencyContact.surname,
                emergencyContactPhonePrefix: pax.emergencyContact.phonePrefix,
                emergencyContactPhone: pax.emergencyContact.phone,
                emergencyContactEmail: pax.emergencyContact.email
            });
        });

        let request : SavePassengerRequest = 
        {
            purchaseId : Number(purchaseId),
            passengers,
            useSameEmergencyContact : uniqueEmergencyContact,
            discountCode, 
            client
        };
        

        return request;
    }


    public returnToQuote(){
        this.routeService.resolveRedirection(null);
    }
}