import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { PaymentRequest } from '../models/mercadopago';
import { RouteService } from '../../../core/route/route.service';
import { Routes } from '../../../core/enums/routes';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { LocalStorageSources } from '../../../core/enums/storage-sources';
import { VoucherRoute } from '../../../core/route/routes/route-parser';

@Injectable({
  providedIn: 'root'
})
export class MercadopagoService {
  constructor() { }

  private apiService = inject(ApiService);
  private router = inject(Router);
  private routeService = inject(RouteService);
  private localService = inject(LocalStorageService);

  public getPurchaseAmount() {
    const paymentRequestId = this.localService.getItemByKey(LocalStorageSources.PAYMENT_ID);
    return this.apiService.get('GetPaymentDetails?paymentId=' + paymentRequestId)
  }

  public createPayment(formData: any){
    const paymentRequestId = this.localService.getItemByKey(LocalStorageSources.PAYMENT_ID);

    const paymentRequest: PaymentRequest = {
      paymentRequestId: Number(paymentRequestId),
      amount: Number(formData.amount),
      cardHolderEmail: formData.cardholderEmail,
      documentType: formData.identificationType,
      documentNumber: formData.identificationNumber,
      installments: Number(formData.installments),
      issuerId: formData.issuerId,
      merchantAccountId: formData.merchantAccountId,
      paymentMethodId: formData.paymentMethodId,
      token: formData.token,
      processingMode: formData.processingMode
    }

    return this.apiService.post("GeneratePayment", paymentRequest);
  }

  endPayment() {
    this.localService.setItem("0", LocalStorageSources.PAYMENT_ID);
    this.routeService.resolveRedirection(new VoucherRoute());
  }

  returnToQuote(){
    this.routeService.resolveRedirection(null);
  }

}
