import { Component, OnInit, inject } from '@angular/core';
import { RouteService } from '../../route/route.service';
import { Routes } from '../../enums/routes';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  standalone: true,
  imports: [
    LoaderComponent
  ]
})
export class RedirectComponent implements OnInit {

  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private localizationService = inject(LocalizationService);

  constructor() { }

  ngOnInit() {
    //Definir lang y country.
    const country = this.localizationService.getUserLocation();
    // const lang = this.localizationService.getUsersLanguage();
    this.router.navigate([`/${country}/cotizador`]);
   
  }

}
