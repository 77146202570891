import { Routes } from "@angular/router";
import { ProductsComponent } from "../../../feature/products/pages/products/products.component";
import { CleanDataResolver } from "../resolvers/clean-data.resolver";
import { HomeComponent } from "../../../feature/quote/pages/home/home.component";
import { PassengersComponent } from "../../../feature/passengers/pages/passengers.component";
import { DocumentsResolver } from "../resolvers/documents.resolver";
import { PurchaseDetailsResolver } from "../resolvers/details.resolver";
import { authGuard } from "../guards/auth.guard";
import { OrderPayedGuard } from "../guards/order-payed.guard";
import { PaymentComponent } from "../../../feature/payment/pages/payment/payment.component";
import { VoucherComponent } from "../../../feature/voucher/voucher.component";
import { RouteResolver } from "../resolvers/route.resolver";
import { MetaResolver } from "../../meta/resolver/meta.resolver";
import { CallingCodeResolver } from "../resolvers/calling-code.resolver";

export const enRoutes : Routes = [
    { path: '', component: HomeComponent, canActivate: [authGuard], resolve: {route: RouteResolver, MetaResolver} },
    { path: 'prices', component: ProductsComponent, canActivate: [authGuard], resolve: {route: RouteResolver, CleanDataResolver, MetaResolver} },
    { path: 'passengers', component: PassengersComponent, canActivate: [authGuard, OrderPayedGuard], resolve: {route: RouteResolver, purchaseDetails: PurchaseDetailsResolver, documents: DocumentsResolver, MetaResolver,  prefixes: CallingCodeResolver} },
    { path: 'payment', pathMatch: 'full', component: PaymentComponent, canActivate: [authGuard, OrderPayedGuard], resolve: {route: RouteResolver, purchaseDetails: PurchaseDetailsResolver, MetaResolver} },
    { path: 'successfull-purchase', pathMatch: 'full', component: VoucherComponent, canActivate: [authGuard], resolve: {route: RouteResolver, purchaseDetails: PurchaseDetailsResolver, MetaResolver} },
] 