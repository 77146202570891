import { CommonModule } from "@angular/common";
import { Component, Inject, Input, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LockPlugin, RangePlugin, easepick } from "@easepick/bundle";

@Component({
  selector: "app-calendar-modal",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./calendar-modal.component.html",
  styleUrl: "./calendar-modal.component.scss",
})
export class CalendarModalComponent {
  @ViewChild("datepicker") pickerElement!: any;

  startDate: Date | string = "";
  endDate: Date | string = "";
  totalDays: number | string = "";
  buttonDisabled: boolean = true;

  MINMUM_DATE = new Date().setDate(new Date().getDate() + 1);

  picker: any;

  ngAfterViewInit() {
    this.picker = new easepick.create({
      element: this.pickerElement.nativeElement || "",
      css: [
        // "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css",
        "assets/calendar-css/easypick.css"
      ],
      calendars: 2,
      grid: 2,
      lang: "es-AR",
      inline: true,
      firstDay: 0,
      plugins: [LockPlugin, RangePlugin],
      RangePlugin: {
        tooltip: true,
        locale: { one: "DÍA", other: "DÍAS" },
      },
      LockPlugin: {
        minDate: this.MINMUM_DATE,
      },
      setup: (picker) => {
        picker.on("select", (e) => {
          const { view, date, target } = e.detail;
          this.startDate = picker.getStartDate();
          this.endDate = picker.getEndDate();

          this.totalDays =
            (this.endDate.getTime() - this.startDate.getTime()) /
              (1000 * 60 * 60 * 24) +
            1;
            this.buttonModal()
        }),
        picker.on("preselect", (e) => {
          this.startDate = e.detail.start;
          this.endDate = "";
          this.totalDays = "";

          this.buttonModal()
        });
      },
    });

    if(this.data.previousStartDate && this.data.previousEndDate) {
      this.picker.setStartDate(this.data.previousStartDate);
      this.picker.setEndDate(this.data.previousEndDate);

      this.startDate = new Date(this.picker.getStartDate());
      this.endDate = new Date(this.picker.getEndDate());
      
      this.totalDays =
            (this.endDate.getTime() - this.startDate.getTime()) /
              (1000 * 60 * 60 * 24) +
            1;
    }
    
  }

  deleteDates() {
    this.picker.clear();
    this.startDate = "";
    this.endDate = "";
    this.totalDays = "";
  }

  constructor(
    public dialogRef: MatDialogRef<CalendarModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: 
    { 
      previousStartDate: Date | string,
      previousEndDate: Date | string
    }
  ) {}

  buttonModal() {
    const getStartDate = !!this.startDate;
    const getEndDate = !!this.endDate;
    this.buttonDisabled = !(getStartDate && getEndDate);
  }

  closeModal() {
    this.dialogRef.close({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }
}
