import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { RouteService } from "../route.service";
import { catchError, map, of, switchMap } from "rxjs";
import { LocalStorageService } from "../../services/local-storage.service";

export const authGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,  
    state: RouterStateSnapshot
) => {

    const authService = inject(AuthService);
    const routeService = inject(RouteService);
    const localStorageService = inject(LocalStorageService);

    if(!authService.appAuthenticated){
        return authService.authorize(routeService.getClient())
        .pipe(
            map((authResponse) => {
                return of(authResponse);
            }),    
            switchMap(() => {
                return of(true);
            }),       
            catchError(() => {
                return of(false);
            })
        );
    }
    else{ return true; }
}