<div
  [ngClass]="{'hidden' : !dataIsReady}">
  <app-header class="z-0"
    [showQuoteButton]="false" 
    [showLogin]="false" 
    [showLangs]="false" 
  ></app-header>
  <div class="relative z-10 mt-4">
    <img src="../../../assets/img/home/misc-01-i.png" alt=""
      class="hidden lg:block absolute left-0 bottom-10 w-8 md:w-12">
    <img src="../../../assets/img/home/misc-01-d.png" alt="" class="absolute bottom-10 right-0 hidden w-24 lg:block" />
    <section
      class="ml-4 grid grid-cols-1 gap-2 lg:gap-8 sm:mx-4 md:mx-16 md:mb-8 md:grid-cols-2 lg:mx-24 2xl:mx-40 2xl:max-w-screen-2xl 2xl:grid-cols-3">
      <div class="relative col-span-1 my-auto">
        <img class="absolute -top-4 right-0 mr-2 block w-[150px] md:hidden" src="../../../assets/img/home/01-mobile.png"
          alt="Home" />
        <p class="mb-2 mr-[40%] text-2xl leading-none text-blue md:mr-0 lg:text-4xl 2xl:text-5xl">
          Tu destino confiable<br />
          para contratar
        </p>
        <p class="mb-5 mr-[40%] md:mr-0 lg:mb-4 text-2xl font-bold leading-none text-blue lg:text-4xl 2xl:text-5xl">
          ASISTENCIA <br />AL VIAJERO.
        </p>
        <p class="mb-2 mr-[40%] md:mr-0  font-medium text-grey lg:text-lg">
          Hacemos de cada viaje <br />una experiencia segura y única.
        </p>
        <p class="hidden md:block mb-4 font-medium text-grey lg:text-lg">
          Planes a medida, beneficios exclusivos, <br />atención personalizada y
          mucho más.
        </p>
        <p class="font-bold text-skyblue lg:text-xl">
          ¿Todo listo para despegar?
        </p>
      </div>
      <div class="col-span-1 xl:col-span-1 2xl:col-span-2">
        <img class="mx-auto hidden lg:block lg:w-4/5 2xl:w-3/5" src="../../../assets/img/home/01.png" alt="Home" />
        <img class="mr-0 hidden md:block lg:hidden" src="../../../assets/img/home/01-mobile.png" alt="Home" />
      </div>
    </section>
  </div>

  <app-quote 
    [home]="true"
    (quoteRequest)="onQuoteRequest($event)"
  >
  </app-quote>

  <div class="relative z-10 bg-blue text-white">
    <img src="../../../assets/img/home/misc-01.png" alt="" class="absolute bottom-10 left-0 hidden lg:block" />
    <img src="../../../assets/img/home/misc-02.png" alt="" class="absolute -bottom-64 right-0 hidden lg:block" />
    <section class="p-8 md:px-16 lg:px-24 2xl:px-40 2xl:mx-auto 2xl:max-w-screen-2xl pt-40 lg:pt-40 xl:pt-24">
      <div class="mb-8 flex items-start lg:mb-12">
        <img src="../../../assets/img/logo-celeste.svg" alt="logo" class="mr-4 w-[50px] lg:my-auto" />
        <div>
          <p class="mb-2 text-3xl font-bold lg:text-4xl">
            now para VOS
          </p>
          <p class="text-lg lg:text-xl leading-5">
            Sentite seguro, viajá seguro, tu asistencia está garantizada.
          </p>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
        <div class="flex items-center rounded-xl bg-white px-4 py-4 2xl:px-8">
          <img src="../../../assets/img/home/02-1.png" alt="02-1" class="mr-4 lg:w-[3rem] 2xl:w-1/4 md:w-10" />
          <div>
            <p class="text-base font-bold leading-5 text-blue">Planes a medida</p>
            <p class="text-base leading-5 text-grey">de tu viaje</p>
          </div>
        </div>
        <div class="flex items-center rounded-xl bg-white px-4 py-4 2xl:px-8">
          <img src="../../../assets/img/home/02-2.png" alt="02-2" class="mr-4 lg:w-[3.6rem] 2xl:w-1/4 md:w-10" />
          <div>
            <p class="text-base font-bold leading-5 text-blue">
              Servicio al cliente
            </p>
            <p class="text-base leading-5 text-grey">24/7</p>
          </div>
        </div>
        <div class="flex items-center rounded-xl bg-white px-4 py-4 2xl:px-8">
          <img src="../../../assets/img/home/02-3.png" alt="02-3" class="mr-4 lg:w-1/5 2xl:w-1/4" />
          <div>
            <p class="text-base font-bold leading-5 text-blue">Beneficios extra</p>
            <p class="text-base leading-5 text-grey">
              por ser parte de la comunidad
            </p>
          </div>
        </div>
        <div class="flex items-center rounded-xl bg-white px-4 py-4 2xl:px-8">
          <img src="../../../assets/img/home/02-4.png" alt="02-4" class="mr-4 lg:w-1/5 2xl:w-1/4" />
          <div>
            <p class="text-base font-bold leading-5 text-blue">Atención ágil</p>
            <p class="text-base leading-5 text-grey">y efectiva</p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="relative z-0 bg-skyblue text-white">
    <img src="../../../assets/img/home/misc-03.png" alt="" class="absolute bottom-10 left-0 hidden lg:block -z-10" />
    <section class="p-8 md:px-16 lg:px-24 2xl:px-40 lg:py-12 2xl:mx-auto 2xl:max-w-screen-2xl">
      <div class="mb-8 flex items-start lg:mb-12">
        <img src="../../../assets/img/logo-azul.svg" alt="logo" class="mr-4 w-[50px] lg:my-auto" />
        <div>
          <p class="mb-2 text-3xl font-bold lg:text-4xl">
            now PARTNERS
          </p>
          <p class="text-lg lg:text-xl leading-5">
            Protegé a tus clientes en los momentos de mayor exposición. Potenciá
            tu oferta de viajes con now assistance!
          </p>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
        <div class="flex items-center rounded-xl bg-white px-4 py-4">
          <img src="../../../assets/img/home/03-1.png" alt="03-1" class="mr-2" />
          <div>
            <p class="text-base font-bold leading-5 text-blue">
              Expansión de marca
            </p>
          </div>
        </div>
        <div class="flex items-center rounded-xl bg-white px-4 py-4">
          <img src="../../../assets/img/home/03-2.png" alt="03-2" class="mr-2" />
          <div>
            <p class="text-base font-bold leading-5 text-blue">Planes exclusivos</p>
          </div>
        </div>
        <div class="flex items-center rounded-xl bg-white px-4 py-4">
          <img src="../../../assets/img/home/03-3.png" alt="03-3" class="mr-2" />
          <div>
            <p class="text-base font-bold leading-5 text-blue">
              Diversificación de negocio
            </p>
          </div>
        </div>
        <div class="flex items-center rounded-xl bg-white px-4 py-4">
          <img src="../../../assets/img/home/03-4.png" alt="03-4" class="mr-2" />
          <div>
            <p class="text-base font-bold leading-5 text-blue">
              Herramientas digitales
            </p>
          </div>
        </div>
      </div>
      <div class="mt-12 text-center">
        <button class="rounded-xl bg-blue px-8 py-4 text-xl font-bold text-white" (click)="vendeNow()">
          VENDÉ now
        </button>
      </div>
    </section>
  </div>

  <div class="relative">
    <img src="../../../assets/img/home/misc-01-i.png" alt="" class="absolute bottom-10 left-0 w-12" />
    <img src="../../../assets/img/home/misc-01-d2.png" alt="" class="absolute right-0 top-10 w-12" />
    <section class="relative mx-4 py-8 md:py-16 md:mx-16 lg:mx-24 2xl:mx-40 2xl:mx-auto 2xl:max-w-screen-2xl">
      <p class="mb-8 text-center text-2xl font-bold text-blue 2xl:text-4xl">
        Coberturas ideales para todo tipo de viajes y viajeros
      </p>
      <div
        class="relative rounded-xl bg-homeMobile bg-cover bg-no-repeat px-0 py-16 text-white md:bg-homeDesktop lg:py-0 md:text-blue md:text-white">
        <img src="../../../assets/img/home/beneficios-l.png" alt=""
          class="absolute -bottom-5 -left-10 hidden w-[250px] xl:block" />
        <img src="../../../assets/img/home/beneficios-r.png" alt=""
          class="absolute -bottom-2 -right-5 block h-[40%] md:-right-10 md:h-[70%]" />
        <div class="mx-8 grid grid-cols-1 md:mr-[110px] md:grid-cols-2 xl:ml-[250px]">
          <div class="mb-8 flex items-start lg:mb-24 lg:mt-16">
            <img src="../../../assets/img/home/vr.png" alt="vr" class="mr-4" />
            <div>
              <p class="mb-2 text-xl font-bold leading-6 2xl:text-2xl">
                ¿Te enfermaste <br />o te accidentaste?
              </p>
              <p class="text-lg leading-5 2xl:text-xl">
                Asistencia médica a tu disposición <br class="hidden lg:block"/>para que nada interrumpa
                tu viaje.
              </p>
            </div>
          </div>
          <div class="mb-8 flex items-start lg:mb-24 lg:mt-16">
            <img src="../../../assets/img/home/vr.png" alt="vr" class="mr-4" />
            <div>
              <p class="mb-2 text-xl font-bold leading-6 2xl:text-2xl">
                ¿Tuviste gastos<br />
                en medicamentos?
              </p>
              <p class="text-lg leading-5 2xl:text-xl">
                Reintegro de los gastos imprevistos <br class="hidden lg:block"/>que afrontaste.
              </p>
            </div>
          </div>
          <div class="mb-8 flex items-start lg:mb-16">
            <img src="../../../assets/img/home/vr.png" alt="vr" class="mr-4" />
            <div>
              <p class="mb-2 text-xl font-bold leading-6 2xl:text-2xl">
                ¿Necesitas ayuda?
              </p>
              <p class="text-lg leading-5 2xl:text-xl">
                Asistencia en tu idioma <br />las 24hs
              </p>
            </div>
          </div>
          <div class="mb-8 flex items-start lg:mb-16">
            <img src="../../../assets/img/home/vr.png" alt="vr" class="mr-4" />
            <div>
              <p class="mb-2 text-xl font-bold leading-6 2xl:text-2xl">
                ¿Tu vuelo se demoró <br />o canceló?
              </p>
              <p class="mr-[50px] text-lg leading-5 2xl:text-xl">
                Compensación económica por <br class="hidden lg:block"/>modificaciones en tu viaje y
                acceso a salas VIP.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="relative bg-grey2">
    <img src="../../../assets/img/home/misc-viaja.png" alt="" class="hidden md:block absolute right-0 top-5" />
    <section
      class="grid grid-cols-1 gap-8 px-8 pt-8 md:px-16 lg:grid-cols-2 lg:px-24 2xl:px-40 lg:pt-16 2xl:mx-auto 2xl:max-w-screen-2xl">
      <img src="../../../assets/img/home/valijas.png" alt="Viaja" class="hidden lg:block lg:w-4/5 mx-auto" />
      <div class="my-auto lg:ml-16">
        <p class="mb-8 text-lg 2xl:leading-8 text-grey 2xl:text-xl">
          A cualquier destino y por cualquier motivo, <br class="hidden md:block"/>
          <b class="text-skyblue">now assistance</b> va con vos.
        </p>
        <p class="text-lg 2xl:leading-8 text-grey 2xl:text-xl">
          Somos tu aliado de confianza, agregamos valor <br />a las experiencias
          de tus clientes viajeros.
        </p>
      </div>
      <img src="../../../assets/img/home/valijas.png" alt="Viaja" class="block lg:hidden" />
    </section>
  </div>
  <app-footer></app-footer>
</div>
<app-loader [ngClass]="{'hidden' : dataIsReady}"></app-loader>