const faqs = [
	{
		"section":"Contratación",
		"faqs": [
			{
				"question": "¿Por qué contratar mi asistencia con Now Assitance?",
				"answer": "Tenemos diversidad de planes para cada tipo de viaje, pensados para la tranquilidad y seguridad de nuestos viajeros. Contamos con un equipo de profesionales las 24hs del día a disposición para resolver cualquier inconveniente lo más rápido posible y que nada detenga tu experiencia. ¿Vamos?"
			},
			{
				"question": "¿Qué documentos necesito para contratar un plan de asistencia al viajero?",
				"answer": "Para contratar un plan de asistencia al viajero,  necesitás tu documento de identidad, detalles del viaje (fechas, destino), y tus datos personales como nombre completo y fecha de nacimiento. Sólo tenes que cotizar en nuestra web, elegir el plan  para vos y seguir los pasos indicados, ¡es muy fácil!"
			},
			{
				"question": "¿Cómo funcionan los planes Multiviaje?",
				"answer": "Now Multiviaje es un plan diseñado para personas que realizan múltiples viajes internacionales a lo largo de un año. Este plan proporciona cobertura continua durante todo el año, eliminando la necesidad de contratar un nuevo voucher para cada viaje."
			},
			{
				"question": "¿Qué es un E-voucher? ¿Cuándo lo recibo?",
				"answer": "El E- voucher es la constancia de la contratación del servicio. El mismo cuenta con un número de identificación personal de Now Assitance. Sugerimos tenerlo a mano durante todo el viaje ya que en caso de solicitar asistencia será requerido. Te lo enviaremos por correo electrónico una vez que se haya completado el proceso de contratación exitosamente."
			}
		]
	},
	{
		"section":"Asistencia médica durante el viaje",
		"faqs": [
			{
				"question": "¿Qué debo hacer en caso de una emergencia médica durante mi viaje?",
				"answer": "En caso de una emergencia médica, debes contactar de inmediato a la central de asistencia al viajero para recibir instrucciones y coordinar la atención necesaria. Nuestros canales de atención son: <br/>Whatsapp: <br/>Teléfono:  <br/>Disponibles las 24 horas los 365 días del año."
			},
			{
				"question": "En caso de riesgo de vida, ¿debo comunicarme con ustedes previo a la atención obligatoriamente?",
				"answer": "Ante una situación donde te encuentres en riesgo inminente de vida, deberás recurrir al servicio médico de emergencia del lugar donde te encuentres. Un familiar o acompañante deberá comunicarse con nuestra Central Operativa para ofrecernos detalles de tu situación y ubicación antes de que abandones el centro medico o en caso de fuerza mayor justificada, dentro de las primeras 24hs de haber concurrido."
			},
			{
				"question": "¿Qué sucede si necesito medicamentos durante mi viaje?",
				"answer": "Si compras medicamentos que han sido autorizados por nuestra Central Operativa, haremos el reintegro correspondiente dentro del límite de  la cobertura contratada. Para hacerlo, necesitamos: <br/>- Los recibos originales de la compra y el comprobante de pago. <br/>- El informe médico original que incluya el diagnóstico y la receta. Recordá pedir estos documentos al médico que te atienda. Sin ellos, no podremos hacer el reintegro."
			},
			{
				"question": "¿Mi prepaga médica local cubre gastos en el extranjero?",
				"answer": "No todas las prepagas médicas locales cubren gastos en el extranjero. Es recomendable tener un plan de asistencia al viajero que cubra emergencias médicas internacionales."
			}
		]
	},
	{
		"section": "Asistencias no médicas durante el viaje",
		"faqs": [	
			{
				"question": "¿Qué hago si pierdo mi pasaporte durante el viaje?",
				"answer": "En caso de pérdida de pasaporte, la asistencia al viajero puede ayudarte a contactar con la embajada o consulado para gestionar la expedición de un nuevo documento."
			},
			{
				"question": "¿La asistencia cubre la pérdida de equipaje?",
				"answer": "¡Si!  Te compensaremos hasta el límite especificado en la cobertura contratada. Para obtener este beneficio, es fundamental presentar a nuestro equipo la siguiente documentación:<br/> • Formulario P.I.R. (Reclamo por Pérdida de Equipaje) Original<br/> • Documento o Pasaporte<br/> • Voucher de asistencia<br/> • Copia original del recibo de la indemnización de la línea aérea (Cheque, comprobante de pago de la misma), pasajes aéreos."
			},
			{
				"question": "¿Cómo funciona el reembolso de gastos por vuelvo demorado o cancelado?",
				"answer": "Si tu vuelo se demora más de 6 horas consecutivas respecto a la hora programada y no hay otra alternativa de transporte, te vamos a  reintegrar hasta el tope de cobertura para este beneficio por gastos de taxi, hotel, comidas y comunicaciones realizadas durante la demora. <br/>¡Importante! recordá guardar los recibos originales de los gastos para la gestión del reembolso."
			}
		]
	},
	{
		"section": "Gestión de reintegros y devoluciones",
		"faqs": [
			{
				"question": "¿Cómo solicito un reintegro?",
				"answer": "Para solicitar un reintegro, escribinos a reintegrosnowassistance@wmmsolutions.com enviando todos los documentos respaldatorios de la solicitud: comprobantes originales de los gastos; certificados o informes médicos; etc.<br/>Nuestro equipo revisará tu solicitud y se pondrá en contacto para infromarte los pasos a seguir. "
			},
			{
				"question": "¿Qué tipo de gastos son reembolsables?",
				"answer": "Los gastos reembolsables generalmente incluyen tratamientos médicos, medicamentos, y gastos de transporte de emergencia."
			},
			{
				"question": "¿Cuánto tiempo tengo para solicitar un reintegro?",
				"answer": "El plazo es de 30 días de corridos desde que regresas a tu país de residencia o desde que finaliza tu cobertura."
			},
			{
				"question": "¿Cómo recibo el reintegro?",
				"answer": "Una vez que se haya completado el proceso de reintegro, nos pondremos en contacto con vos para coordinar el pago. "
			}
		]
	}
]

export {
    faqs
};

export interface FaqsSection {
	section: string,
	toggle?: boolean,
	faqs: Array<Faq>
}

export interface Faq {
    question: string,
    answer: string,
    toggle?: boolean
}
