import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productName',
  standalone: true
})
export class ProductNamePipe implements PipeTransform {

  transform(value: string ): string {
    if (!value) return '';

   const productName = value.replace('now', '').replace('Now', '').trim();
   return productName;
  }

}