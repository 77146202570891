import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';
import { Countries } from '../enums/country.enum';
import { Language } from '../enums/language.enum';
import { IRoute } from '../route/routes/route-parser';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {

    private translocoService = inject(TranslocoService);

    private ENV_DEFAULT_LANG = environment.DEFAULT_LANG;
    private ENV_ENABLE_LANGUAGES = environment.ENABLE_LANGUAGES;

    private DEFAULT_LANG = Language.SPANISH;
    private ENABLE_LANGUAGES : Language[] = [  ];

    private DEFAULT_COUNTRY = Countries.ARGENTINA;
    private ENABLE_COUNTRIES = [ Countries.ARGENTINA ];

    private currentLanguage: Language = this.DEFAULT_LANG;
    private currentCountry: Countries = this.DEFAULT_COUNTRY;

    constructor() {
        var defaultLang : Language | null = Language[this.ENV_DEFAULT_LANG as keyof typeof Language];
        if(defaultLang){
            this.DEFAULT_LANG = defaultLang;
        }



        if(this.ENV_ENABLE_LANGUAGES){
            const enabledLangs = this.ENV_ENABLE_LANGUAGES.split(',');

            enabledLangs.forEach( lang => { 
                switch(lang){
                    case 'es':
                        this.ENABLE_LANGUAGES.push(Language.SPANISH);
                        break;
                    case 'en':
                        this.ENABLE_LANGUAGES.push(Language.ENGLISH);
                        break;
                    case 'pt':
                        this.ENABLE_LANGUAGES.push(Language.PORTUGUESE);
                        break;
                }
             });
        }
    }

    public setLanguage(language: Language) {
        if(this.isLanguageEnabled(language)){            
            this.currentLanguage = language;
        }
        else{
            this.currentLanguage = this.DEFAULT_LANG;
        }

        this.translocoService.setActiveLang(this.currentLanguage || this.DEFAULT_LANG);
    }

    public getLanguage(){
        return this.currentLanguage || this.DEFAULT_LANG;
    }

    public setCountry(country: Countries, setDefaultLang: boolean = false) {
        if(this.isCountryEnabled(country)){
            this.currentCountry = country;
        }
        else{
            this.currentCountry = this.DEFAULT_COUNTRY;
        }

        if(setDefaultLang){
            this.setLanguage(this.DEFAULT_LANG);
        }
    }

    public getCountry(){
        return this.currentCountry || this.DEFAULT_COUNTRY;
    }

    public getUserLocation(){
        // let timeZone;
        // if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
        //   timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // }

        // this.getUsersLanguage();
        // if(navigator.geolocation){
        //     navigator.geolocation.getCurrentPosition((position) => {
        //     });
        // }
        return 'ar';
    }

    public getUsersLanguage(){
        const navigatorLanguage = navigator.language.split('-')[0]

        if(this.ENABLE_LANGUAGES.includes(Language[navigatorLanguage as keyof typeof Language])){
            return navigatorLanguage;
        }

        return this.DEFAULT_LANG;
    }

    public isLanguageEnabled(language: Language){
        return this.ENABLE_LANGUAGES.includes(language);
    }

    public getDefaultLanguageByCountry(country: Countries){
        switch(country){
            case Countries.ARGENTINA:
                return Language.SPANISH;
            case Countries.BRASIL:
                return Language.PORTUGUESE;
            default:
                return this.DEFAULT_LANG;
        }
    }

    public isCountryEnabled(country: Countries){
        return this.ENABLE_COUNTRIES.includes(country);
    }

    public returnRouteByLanguage(route : IRoute){

        switch(this.currentLanguage){
            case Language.SPANISH:
                return route.spanishName;
            case Language.ENGLISH:
                return route.englishName;
            case Language.PORTUGUESE:
                return route.portugueseName;
            default:
                return route.spanishName;
        }
    }
}