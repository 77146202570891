import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product } from '../../models/products';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ProductDetailModalComponent {

  showButton = true;
  product!: any;
  productAttributes: any[] = [];
  productName : string = "";

  constructor(
    public dialogRef: MatDialogRef<ProductDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: 
    { 
      showButton: boolean,
      product: any
    },
  ) {
    this.showButton = data.showButton;
    this.product = data.product;
    this.productAttributes = this.product.attributes;
    this.setProductName();
  }

  setProductName(){
    const name = this.product.name;
    this.productName = name.replace('now', '').replace('Now', '').trim(); 
  }

  closeModal(purchase: boolean) {
    this.dialogRef.close({
      purchase
    });
  }
}


