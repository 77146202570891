import { Component, OnInit, Input, inject, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from '../../models/products';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faInfoCircle, faLock, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ProductNamePipe } from '../../../../core/pipes/product-name.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ProductDetailModalComponent } from '../product-detail-modal/product-detail-modal.component';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgxTippyModule,
        ProductNamePipe,
        FormsModule
    ]
})
export class ProductCardComponent implements OnInit, OnChanges {
    @Input() products: Product[] = [];
    @Output() productPurchased = new EventEmitter<number>();

    protected tooltipName: string = '';
    protected tooltipDescription: string = '';

    private productsValue = new BehaviorSubject<Product[]>([]);
    products$ : Observable<Product[]> = this.productsValue.asObservable();

    faInfo = faInfoCircle;
    faLock = faLock;
    faPen = faPenToSquare;

    cardProducts : ProductCardElement[] = [];
    productsOrder : ProductOrder = ProductOrder.lower;

    protected modalProduct : Product = Product.createProduct();

    constructor(        
        private dialog: MatDialog
    ) { 
    }

    ngOnInit(): void {
        this.products$.subscribe(
            (products)  => {
                this.setProductCard();
            }
        );     
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['products']){
            this.productsValue.next(this.products);
        }
    }
    
    showDetails(showButton: boolean = true, productId: number) {
        const product = this.products.find((product) => product.id === productId);
        const dialogRef = this.dialog.open(ProductDetailModalComponent, {
            data: { 
                showButton,
                product
            }
        });
        dialogRef.afterClosed().pipe(catchError( error => of({ purchase: false }) )).subscribe(({purchase}) => {
            if(purchase){
                this.purchaseProduct(productId);
            }
        });
    }

    setProductForModal(index: number){
        this.products.findIndex((product, i) => {
            if(i === index){
                this.modalProduct = product;
            }
        });
    }

    public setProductCard(){
        this.cardProducts = this.products.map((product) => {
            const productCard : ProductCardElement = {
                id: product.id,
                name: product.name,
                currency: product.currency,
                price: product.price,
                type: product.type,
                priceType: ProductPriceType.standard,
                outstanding: product.outstanding,
                attributes: product.attributes.map((attribute) => {
                    return {
                        name: attribute.name,
                        value: attribute.value,
                        description: attribute.description,
                        tooltipEnabled: attribute.description !== '' ? true : false
                    }
                })
            }
            return productCard;
        });
    }

    sortProducts(){
        if(this.productsOrder == ProductOrder.higher){
            this.cardProducts = this.cardProducts.sort((a, b) => b.price - a.price);
        } else {
            this.cardProducts = this.cardProducts.sort((a, b) => a.price - b.price);
        }
    }

    purchaseProduct(productId: number){
        this.productPurchased.emit(productId);
    }

    setTooltip(name : string, description: string){
        this.tooltipName = name;
        this.tooltipDescription = description;
    }
}

interface ProductCardElement{
    id: number;
    name: string;
    currency: string;
    price: number;
    type: ProductType;
    priceType: ProductPriceType;
    outstanding: boolean;
    attributes: ProductAttributes[];
} 

interface ProductAttributes{
    name: string;
    value: string;
    description: string;
    tooltipEnabled: boolean;
}

enum ProductType{
    standard = 1,
    mostChosen = 2,
    multiTrip = 3
}

enum ProductPriceType{
    standard = 1,
    withInstallments = 2,
    withDiscount = 3
}

enum ProductOrder{
    higher = 1,
    lower = 2
}
