import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RouteService } from '../route/route.service';
import { LocalizationService } from './localization.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private apiUrl = environment.bffBaseUrl; // Replace with your API URL

    private routeService = inject(RouteService);
    private localizationService = inject(LocalizationService);

    constructor(private http: HttpClient) { 
    }

    // Example GET request
    public get(endpoint: string): Observable<any> {
        const url = `${this.apiUrl}${endpoint}`;
        return this.http.get(url, this.generateHttpOptions());
    }

    // Example POST request
    public post(endpoint: string, data: any): Observable<any> {
        const url = `${this.apiUrl}${endpoint}`;
        return this.http.post(url, data, this.generateHttpOptions());
    }

    // Example PUT request
    public put(endpoint: string, data: any): Observable<any> {
        const url = `${this.apiUrl}${endpoint}`;
        return this.http.put(url, data);
    }

    // Example DELETE request
    public delete(endpoint: string): Observable<any> {
        const url = `${this.apiUrl}${endpoint}`;
        return this.http.delete(url);
    }

    public healthCheck() {
        return this.http.get(`${this.apiUrl}healthz`)
    }

    public generateHttpOptions() {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams().set('lang', this.localizationService.getLanguage() ?? 'es'),
            withCredentials: true
        }; 
    }
}