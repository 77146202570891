import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialog } from "@angular/material/dialog";
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { FooterComponent } from '../../../../shared/components/footer/footer.component';
import { HeaderComponent } from '../../../../shared/components/header/header.component';
import { QuoteFormComponent } from '../../../../shared/components/quote-form/quote-form.component';
import { faInfoCircle, faLock, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { ProgressBarComponent } from '../../../../shared/components/progress-bar/progress-bar.component';
import { QuoteDetailsComponent } from '../../components/quote-details/quote-details.component';
import { QuoteModel } from '../../../quote/models/quote.model';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { QuoteService } from '../../../quote/services/quote.service';
import { Subject, catchError, of, takeUntil } from 'rxjs';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/products';
import { ProductCardComponent } from '../../components/product-card/product-card.component';
import { Routes } from '../../../../core/enums/routes';
import { LocalizationService } from '../../../../core/services/localization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PassengersRoute } from '../../../../core/route/routes/route-parser';
import { RouteService } from '../../../../core/route/route.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    HeaderComponent,
    FooterComponent,
    QuoteFormComponent,
    FontAwesomeModule,
    NgxTippyModule,
    ProgressBarComponent,
    QuoteDetailsComponent,
    LoaderComponent,
    ProductCardComponent
  ]
})
export class ProductsComponent implements OnInit, OnDestroy {

  protected dataIsReady: boolean = false;
  private quoteService = inject(QuoteService);
  private productService = inject(ProductService);
  private localizationService = inject(LocalizationService);
  private routeService = inject(RouteService);
  private activatedRoute = inject(ActivatedRoute);
  private destroy$ = new Subject<void>();

  quotedProducts : Product[] = [];

  faInfo = faInfoCircle;
  faPen = faPenToSquare;
  faLock = faLock;

  cotizadorOpened = false;

  stepNumber = 0;

  constructor(
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.quotedProducts = this.productService.getProducts();
    this.dataIsReady = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onQuoteRequest(quoteModel: QuoteModel){
    this.dataIsReady = false;
    this.quoteService.quoteProducts(quoteModel, this.localizationService.getLanguage())
    .pipe(
        takeUntil(this.destroy$),           
        catchError((error) => {
          this.dataIsReady = true;
            return of(null);
        })
    )
    .subscribe((quoteResponse) => {      
        if(!quoteResponse) return;

        this.quoteService.storeQuoteId(quoteResponse.quoteId);
        this.quoteService.storeQuotedProducts(quoteResponse.products);
        this.quotedProducts = quoteResponse.products;
        this.dataIsReady = true;
    });
  }

  onProductPurchased(productId: number){
    this.dataIsReady = false;

    const selectedProduct = this.quotedProducts.find(product => product.id == productId);
    
    if(!selectedProduct){         
        return;
    }

    this.productService.puchaseProduct(selectedProduct)
    .pipe(
        takeUntil(this.destroy$),           
        catchError((error) => {
            return of(null);
        })
    )
    .subscribe((productsResponse) => {
        if(productsResponse){
            this.productService.savePurchaseId(productsResponse.purchaseId);
            this.productService.saveSelectedProduct(selectedProduct);
            this.routeService.resolveRedirection(new PassengersRoute());
        }
        else{
            this.dataIsReady = true;
        }
    });
  }

}
