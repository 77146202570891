import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { catchError, map, of } from "rxjs";
import { ApiService } from "../../services/api.service";

export const ServerStatusResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const apiService = inject(ApiService);
    return apiService.healthCheck()
    .pipe(
        map( () => of(true)),
        catchError((err) => {
            return of(false);
        })
    );
}
