import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { RouteService } from "../route.service";
import { RouteParser } from "../../utils/route-parser";

export const RefreshResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) => {
    const routeService = inject(RouteService);
    const page = RouteParser(route.routeConfig?.path as string);
    // routeService.setCurrentPage(page);
    // if(routeService.needsRefresh){
    //   routeService.needsRefresh = false;
    //   return routeService.resolveRedirection(page);
    // }
  };