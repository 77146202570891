import { Injectable, inject } from '@angular/core';
import { ApiService } from '../services/api.service';
import { BehaviorSubject, Observable, catchError, map, of, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private isAuthenticatedSubject: BehaviorSubject<boolean>;
    public isAuthenticated: Observable<boolean>;

    private apiService = inject(ApiService);

    constructor() { 
        this.isAuthenticatedSubject = new BehaviorSubject<boolean>(this.checkAuthentication());
        this.isAuthenticated = this.isAuthenticatedSubject.asObservable();
    }

    public authorize(client: string){   
        const request = {
            client:client        
        }

        return this.apiService.post('AuthorizeApplication', request)
        .pipe(
            tap(() => {
                this.isAuthenticatedSubject.next(true);
            })
        );
    }

    get appAuthenticated(): boolean {
        return this.isAuthenticatedSubject.value;
    }

    private checkAuthentication(): boolean{
        this.apiService.get('CheckAuthentication')
        .pipe(
            map(() => true),
            catchError(() => of(false))
        )
        .subscribe((isAuthenticated) => {
            this.isAuthenticatedSubject.next(isAuthenticated);
        });
        return false;
    }
}