<app-header 
    [showQuoteButton]="false" 
    [showLogin]="false" 
    [showLangs]="false" 
></app-header>
<div class="relative px-4 py-8 lg:px-24 2xl:px-40 text-left lg:text-justify text-grey lg:min-h-[550px] 2xl:min-h-[700px]">
    <img src="../../assets/img/politicas/misc-i.png" class="hidden lg:block absolute left-0 top-10">
    <img src="../../assets/img/condiciones/misc-d.png" class="hidden lg:block absolute right-0 top-10">
    
    <h1 class="text-blue text-3xl mb-4 font-bold">Condiciones Generales</h1>
    <p class="mb-8">A continuación, vas a encontrar el detalle de las Condiciones Generales de tu asistencia. <br/>
        <b>Es importante que descargues la versión que corresponda según la fecha de contratación de tu cobertura.</b>
    </p>
    <p class="font-bold text-blue mb-4">2024</p>
    <p class="mb-4">
        <b>CONDICIONES GENERALES Y PARTICULARES SEGURO DE VIAJE INDIVIDUAL</b><br/>
        En vigencia desde 01/09/2024
    </p>
    <a href="../../assets/docs/Condiciones Generales Now Assistance 2024-08.pdf" class="hover:underline mr-4"><b class="text-skyblue">ABRIR</b> - Versión Español</a>
    <!-- <br class="block lg:hidden"/> -->
    <!-- <a href="../../assets/docs/Condiciones Generales Now Assistance 2024-08.pdf" class="hover:underline"><b class="text-skyblue">ABRIR</b> - Versión Inglés</a> -->
    <!--
    <p class="font-bold mb-4">RECOMENDACIÓN IMPORTANTE</p>
    <p class="mb-4">
        Invitamos a los Beneficiarios de un voucher o plan de asistencia <b>NOW ASSISTANCE</b> a leer estas Condiciones Generales antes de iniciar su viaje. En las siguientes páginas, encontrará las Condiciones Generales, Condiciones Particulares y Exclusiones, así como las instrucciones que le permitirán un mejor uso de los beneficios y servicios contratados.
    </p>

    <p class="font-bold mb-4 underline">I. INTRODUCCIÓN</p>
    <p class="mb-4">Todos los servicios proporcionados por este plan de asistencia son brindados a través de NOW ASSISTANCE, y/o por terceros designados por esta, cuyo objeto principal es el de proporcionar, entre otros, servicios de asistencia médica, y personal únicamente en casos de EMERGENCIAS en el transcurso de un viaje internacional durante el periodo de vigencia del plan contratado.</p>

    <p class="font-bold mb-2">Aceptación del Beneficiario:</p>
    <p class="mb-4">
        Las presentes Condiciones Generales, junto con la restante documentación se pone a disposición del Beneficiario al momento de la compra del plan, conforman el contrato de asistencia al viajero que brinda <b>NOW ASSISTANCE</b>. El Beneficiario declara conocer y aceptar las presentes Condiciones Generales, dicha aceptación queda ratificada por
        medio de cualquiera de los siguientes actos:<br/>
        1. El pago de los servicios contratados<br/>
        2. El uso o el intento de uso de cualquiera de los servicios contratados

    </p>
    <p class="mb-4">
        En ambos casos, el Beneficiario reconoce que ha elegido, leído y que acepta todos los términos y condiciones de los servicios expresados en las presentes Condiciones Generales y que las mismas rigen la relación entre las partes en todo momento convirtiéndose en un contrato de adhesión.
    </p>
    <p class="mb-4">
        Es claramente entendido y aceptado por el Beneficiario que los planes de NOW ASSISTANCE, no constituyen bajo ningún motivo un seguro o producto afín, como tampoco es un programa de seguridad social o de medicina prepagada, un servicio médico a domicilio o servicio médico ilimitado. Por lo tanto, no tienen como objeto principal la sanidad completa, ni el tratamiento definitivo de las dolencias del Beneficiario. Los servicios de asistencia médica a ser brindados por <b>NOW ASSISTANCE</b> se limitan expresa y únicamente a tratamientos de urgencia de cuadros agudos y están únicamente orientados a la asistencia primaria en viaje de eventos súbitos e imprevisibles donde se haya diagnosticado una enfermedad o condición médica clara, comprobable y aguda que impida la normal continuación de un viaje, siempre y cuando dicha enfermedad o condición médica no se encuentre en la lista de las exclusiones. Estos planes están diseñados para garantizar la recuperación primaria y normal e inicial del Beneficiario y las condiciones físicas que permitan una normal continuación de su viaje. No están diseñados ni se contratan ni se prestan para:<br/>
        ◦ Procedimientos médicos electivos <br/>
        ◦ Efectuarse chequeos médicos de rutina, ni chequeos que no hayan sido previamente autorizados por la Central de Servicios de Asistencia<br/>
        ◦ Adelantar tratamientos o procedimientos benignos o de larga duración
    </p>
    <p class="mb-4">
        Toda asistencia o tratamiento cesará y no será responsabilidad de <b>NOW ASSISTANCE</b> una vez que el Beneficiario regrese a su país de residencia o cuando expire el período de validez del plan elegido, exceptuando planes que tengan cobertura en el país de origen, limitado a accidentes. La adquisición por parte de un Beneficiario de uno o más voucher no produce la acumulación de los beneficios ni del tiempo en ellas contempladas, en estos casos solo podrán aplicarse los topes establecidos en el voucher que primero haya sido emitido.
    </p>
    <p class="mb-4">
        Adicionalmente, en caso de que el tiempo de permanencia en el país destino sea por dos (2) años o más, será considerado país de residencia habitual y no se brindará cobertura de ninguna de las prestaciones del plan contratado.
    </p>

    <p class="font-bold mb-4">
        NOTA: Es claramente entendido por el Beneficiario que este plan es un producto de asistencia en viajes y que en la eventualidad que sea ofrecido a través de una compañía de seguros no lo hace un seguro médico internacional.
    </p>

    <p class="mb-4">
        Por otra parte, una vez iniciada la vigencia del voucher, el Beneficiario no podrá hacer cambios ni ampliación del producto contratado, tampoco se procederá a la cancelación del voucher por ninguna razón, ni bajo ninguna circunstancia. Sin perjuicio de lo anterior, cuando el Beneficiario prolongue su viaje en forma imprevista, podrá solicitar la emisión de un nuevo voucher. <b>NOW ASSISTANCE</b> se reserva el derecho de aceptar o negar la emisión de un nuevo voucher sin dar más explicaciones rigiéndose bajo las siguientes condiciones:<br/>
        a. El beneficiario no podrá solicitar la emisión de un nuevo voucher si ha hecho uso de cualquiera de los servicios de <b>NOW ASSISTANCE</b> durante la vigencia del primer voucher.
        b. El Beneficiario podrá adquirir su nuevo voucher con un plan que tenga la misma cobertura del primero o mayor, no se emitirá con coberturas menor a la contratada originalmente.<br/>
        c. El Beneficiario deberá solicitar autorización para la emisión de un nuevo voucher exclusivamente al agente emisor con el cual contrató la asistencia anterior o de haber sido comprado en página web, a través de la sección <span class="text-red">“ayuda”</span> en la misma, indicando explícitamente que desea realizar la compra de un nuevo voucher,  informando el certificado vigente y la cantidad de días que desea contratar. El agente emisor está obligado a informar a <b>NOW ASSISTANCE</b> que se trata de una emisión del beneficiario con el viaje ya iniciado y fuera de su país de residencia y solicitará la autorización para el nuevo período de contratación.<br/>
        d. La solicitud de emisión de un nuevo voucher deberá efectuarse antes de la finalización de la vigencia del voucher anterior.<br/>
        e. El Beneficiario deberá realizar el pago del nuevo voucher al momento de la emisión.
    </p>
    <p class="mb-4">
        El nuevo plan de su servicio de asistencia en viajes y su correspondiente voucher emitido en las condiciones referidas en esta cláusula no podrá ser utilizado bajo ningún concepto, para iniciar o continuar el tratamiento y/o asistencia de problemas que ya hubieran surgido durante la vigencia del primer voucher original y/o anteriores o antes de la vigencia del nuevo plan y/o voucher, independientemente de las gestiones o tratamientos en curso hayan sido autorizados por <b>NOW ASSISTANCE</b> o por terceros. Toda asistencia médica tratada durante la vigencia de un voucher pasará automáticamente a ser considerada como preexistencia durante la vigencia del siguiente voucher y por lo tanto no será asumida por <b>NOW ASSISTANCE</b>.
    </p>
    <p class="mb-4">
        Cuando la vigencia del voucher anterior haya finalizado al momento de la compra del nuevo voucher, o el pasajero compre con el viaje ya iniciado y fuera de su país de residencia, el voucher será expedido con 3 (tres) días de carencia para cualquier otro gasto contemplado dentro de la tabla de coberturas. Durante estos períodos de carencia el Beneficiario podrá contactarse para solicitar la coordinación de cualquier asistencia, pero <b>NOW ASSISTANCE</b> no será responsable por los gastos que se generen, aun cuando los mismos se generen luego de finalizado este período. <b>NOW ASSISTANCE</b> tampoco será responsable por los gastos que se generen si del relato del Beneficiario y/o de quien solicite la asistencia y/o de los reportes médicos obtenidos se estableciera que los inconvenientes, los síntomas
        y/o accidentes fueron anteriores a la fecha de emisión del producto y/o hubiesen ocurrido durante el período de carencia que debe cumplir el Beneficiario, aun cuando se hayan originado o manifestado estando en viaje.
    </p>

    <p class="mb-4">
        Cuando la vigencia del voucher anterior no haya finalizado al momento de la compra del nuevo voucher, entendiendo que el inicio de vigencia del nuevo voucher es inmediatamente consecutivo a la fecha de finalización del voucher anterior, el beneficiario no tendrá que cumplir con un período de carencia, de todas maneras toda asistencia médica tratada durante la vigencia de un voucher pasará automáticamente a ser considerada como preexistencia durante la vigencia del siguiente voucher y por lo tanto los costos no serán asumidas por <b>NOW ASSISTANCE</b>.
    </p>
    <p class="mb-4">
        La compra de un nuevo voucher, será viable únicamente tras haber recibido autorización expresa de la Central de Servicios de Asistencia, sin importar el medio por el cual el beneficiario adquiera el mismo.
    </p>
    <p class="mb-4">
        Las Condiciones Generales y Particulares de los Servicios y las Condiciones de los Planes son concordantes y establecidos de conformidad con el proveedor de los mismos; quien establece las condiciones para el uso, alcance y validez de la cobertura para la asistencia contratada. Los Servicios de <b>NOW ASSISTANCE</b> son provistos y asumidos 100% (cien por ciento) por WORLD MEDICAL CARE LLC, con domicilio físico y legal en 800SE 4th Avenue, Suite 718, Hallandale Beach, Florida (33009).
    </p>


    <p class="font-bold mb-2">DEFINICIONES:</p>
    <p class="mb-4">
        A continuación, enumeramos las definiciones de los términos usados en estas condiciones generales, para un mayor entendimiento de los Beneficiarios de un plan NOW ASSISTANCE:
    </p>

    <p class="font-bold mb-2">A</p>
    <p class="mb-4">
        <b>- Accidente</b>: es el evento generativo de un daño corporal que sufre el Beneficiario, causado por agentes extraños, fuera de control y en movimiento, agentes externos, violentos y visibles y súbitos. Siempre que se mencione el término "accidente", se entenderá que la lesión o dolencia resultante fue provocada directamente por tales agentes e independientemente de cualquier otra causa. Los accidentes cuyo origen es debido a descuido, provocación o la falta de medidas de prevención por parte del Beneficiario se encuentran excluidos de toda asistencia. Si el daño corporal es producido como consecuencia de causas diferentes a las anteriores mencionadas, el Beneficiario tendrá cobertura hasta el monto de Asistencia Médica por Enfermedad del plan adquirido.<br/>
        <b>- Accidente Grave</b>: Aquel que trae como consecuencia amputación de cualquier segmento corporal; fractura de huesos largos (fémur, tibia, peroné, humero, radio y cúbito); trauma craneoencefálico; quemaduras de segundo y tercer grado; lesiones severas de mano, tales como, aplastamiento o quemaduras; lesiones severas de columna vertebral con compromiso de médula espinal; lesiones oculares que comprometan la agudeza o el campo visual o lesiones que comprometan la capacidad auditiva. En general todo accidente en el cual esté en riesgo la vida del paciente.
    </p>
    <p class="font-bold mb-2">C</p>
    <p class="mb-4">
        <b>- Catástrofe</b>: Suceso infausto que altera gravemente el orden regular de las cosas, donde se ven implicadas numerosas personas.<br/>
        <b>- Central de Servicios de Asistencia</b>: Es la oficina que coordina la prestación de los servicios requeridos por el Beneficiario con motivo de su asistencia. Es igualmente el departamento de profesionales que presta los servicios de supervisión, control y coordinación que intervienen y deciden todos aquellos asuntos y/o prestaciones a brindarse o prestarse en virtud de las presentes condiciones generales que están relacionados con temas médicos.
    </p>

    <p class="font-bold mb-2">D</p>
    <p class="mb-4">
        <b>- Departamento Médico</b>: Grupo de profesionales médicos de <b>NOW ASSISTANCE</b> que intervienen y toman decisión en todos los asuntos y/o prestaciones brindadas o que vayan a brindarse de conformidad con las presentes Condiciones Generales.<br/>
        <b>- Deportes Amateur</b>: Es el practicado por aficionados, por ocio y/o actividades recreativas.<br/>
        <b>- Deportes Profesionales</b>: Es el practicado con o sin ánimo de lucro, realizado en cualquier tipo de competencia como Intercolegiados, torneos, campeonatos, deportes que supongan un alto riesgo, entre otros.
    </p>

    <p class="font-bold mb-2">E</p>
    <p class="mb-4">
        <b>- Emergencia</b>: Asunto o situación imprevista que requiere de una atención inmediata, ya que la vida del pasajero se encuentra en una situación crítica.<br/>
        <b>- Enfermedad o Condición Médica Aguda</b>: Proceso corto y relativamente severo de la alteración del estado del cuerpo o alguno de sus órganos, que pudiere interrumpir o alterar el equilibrio de las funciones vitales, pudiendo provocar dolor, debilidad u otra manifestación extraña al comportamiento normal del mismo.<br/>
        <b>- Enfermedad Congénita</b>: Patología presente o existente desde antes del momento de nacer.<br/>
        <b>- Enfermedad Crónica</b>: Todo proceso patológico continuo, repitente y persistente en el tiempo, mayora 30 días de duración.<br/>
        <b>- Enfermedad o Condición Médica Preexistente</b>: todo proceso físico patológico que reconozca un origen o una etiología anterior a la fecha de inicio de la vigencia del plan o del viaje (o la que sea posterior) y que sea factible de ser objetivado a través de métodos complementarios de diagnóstico de uso habitual, cotidiano, accesible y frecuente en todos los países del mundo (incluyendo, pero no limitado a: Doppler, resonancia nuclear, magnética, cateterismo, radiología, etc.).<br/>
        Se entiende por preexistencia toda enfermedad, o estado del cuerpo, o proceso conocido o no por el Beneficiario, que haya necesitado o requerido de un periodo de formación, o de una gestación, o de una incubación dentro del organismo del Beneficiario antes de haber iniciado el viaje y/o antes de haber iniciado la vigencia del producto contratado. Ejemplos claros y comunes de preexistencias solo para citar algunos son: los cólicos y cálculos renales y/o biliares, las obstrucciones de arterias o venas por coágulos u otros, enfermedades respiratorias como el asma, problemas pulmonares, enfisemas, el VIH, generalmente problemas relacionados con la tensión arterial, glaucoma, cataratas, nefritis, úlceras o enfermedades gástricas, enfermedades resultantes de deformaciones congénitas, micosis genitales, abscesos hepáticos, cirrosis, azúcar en la sangre, colesterol alto, triglicéridos altos, y otras. Los mismos requieren de un periodo de formación corto o largo, pero en todos los casos superior a unas horas de vuelo, reconociendo que dicho estado o proceso patológico existía dentro del organismo antes de subirse al avión o al medio de transporte en la fecha de la entrada en vigencia del servicio de asistencia, aun cuando la sintomatología se presente por primera vez después de haber iniciado el viaje.<br/>
        <b>- Enfermedad o Condición Médica Recurrente</b>: regreso, repetición u aparición de la misma enfermedad o condición luego de haber sido tratada.<br/>
        <b>- Enfermedad o Condición Médica Repentina o Imprevista</b>: Enfermedad pronta, impensada, no prevista, contraída con posterioridad a la fecha de inicio de vigencia del Beneficiario del servicio de asistencia médico para viajes <b>NOW ASSISTANCE</b>.<br/>
        <b>- Enfermedad Grave</b>: Es una alteración o desviación del estado fisiológico en una o varias partes del cuerpo, manifestadas por síntomas y unos signos característicos, y cuya evolución es más o menos previsible, es decir cualquier dolencia o lesión con secuelas permanentes o no permanentes que limiten parcialmente o impidan totalmente la ocupación o actividad habitual de la persona afectada, o la incapaciten para cualquier actividad y requiera o no la asistencia de otras personas para las actividades más esenciales de la vida.
    </p>

    <p class="font-bold mb-2">F</p>
    <p class="mb-4">
        <b>- Fuerza Mayor</b>: la que, por no poderse prever o resistir, exime del cumplimiento de alguna obligación por parte de <b>NOW ASSISTANCE</b> y/o que procede de la voluntad de un tercero.
    </p>

    <p class="font-bold mb-2">G</p>
    <p class="mb-4">
        <b>- Gastos de Primera Necesidad</b>: gastos realizados por la compra de elementos de uso personal e intransferible. Se entienden por estos, única y exclusivamente: ropaje (ropa exterior, ropa interior), zapatos, elementos de aseo personal (shampoo, acondicionador, jabón -líquido, en barra, en polvo-, cepillo de dientes, pasta de dientes, desodorante, crema de afeitar, máquina de afeitar, productos de higiene femenina) y maquillaje. Cualquier otro elemento no considerado dentro del listado anteriormente indicado, se entenderá como excluido de cualquier tipo de cobertura.
    </p>

    <p class="font-bold mb-2">M</p>
    <p class="mb-4">
        <b>- Médico Tratante</b>: Profesional médico provisto o autorizado por la Central de Servicios de Asistencia de <b>NOW ASSISTANCE</b> que asiste al Beneficiario en el lugar donde se encuentra este último.
    </p>

    <p class="font-bold mb-2">P</p>
    <p class="mb-4">
        <b>- Paciente Estable</b>: que no presenta variación de su estado de salud y suele hacer referencia a que los síntomas y signos no han cambiado recientemente.<br/>
        <b>- Plan o Producto de Asistencia</b>: Es el conjunto detallado de los servicios de asistencia en viaje ofrecidos, indicando una enumeración taxativa de las mismas y sus límites monetarios, cuantitativos, geográficos y de edad del Beneficiario.
        <b>- Plazo o Período de Carencia</b>: Intervalo de tiempo durante el cual no son efectivas las coberturas incluidas dentro del Plan. Dicho plazo se computa por días contados desde la fecha de inicio de vigencia del voucher o fecha de confirmación de pago del voucher (lo que sea posterior), siempre que el Beneficiario ya se encuentre fuera del lugar de residencia habitual al momento de contratación.
    </p>

    <p class="font-bold mb-2">T</p>
    <p class="mb-4">
        <b>- Topes Máximos</b>: Montos máximos de cobertura por parte de NOW ASSISTANCE, indicados en el voucher para cada prestación y según el producto de asistencia contratado.
    </p>

    <p class="font-bold mb-2">V</p>
    <p class="mb-4">
        <b>- Voucher y/o Certificado de Asistencia</b>: Es el documento que usted recibe al momento de la compra de su producto <b>NOW ASSISTANCE</b> y que lleva sus datos para ser informados a la central en caso de solicitar asistencia.
    </p>


    <p class="font-bold mb-4 underline">II. BENEFICIARIO/EDAD LÍMITE</p>
    <p class="mb-4">
        El Beneficiario es la persona natural cuyo nombre aparece reflejado en el plan de asistencia y es la sola beneficiaria de todas sus coberturas hasta el día aniversario, inclusive, de su edad límite según el tipo de Plan adquirido, fecha a partir de la cual el Beneficiario pierde todo derecho a los beneficios y a todas las prestaciones de asistencia definidas en estas condiciones generales, así como derecho a cualquier reintegro o reclamo alguno originado en eventos posteriores a tal día.<br/>
        Límites de edad de acuerdo al tipo de plan contratado: <br/>
        - Límite de edad: 85 años inclusive, teniendo un 50% de recargo desde los 75 hasta los 85 años.
    </p>
    <p class="mb-4">
        Las prestaciones o beneficios del Plan respectivo los podrá recibir exclusivamente el Beneficiario y son intransferibles, por lo cual el mismo deberá comprobar y demostrar su identidad, presentar el voucher carta, o voucher certificado de asistencia correspondiente y documentos de viajes para determinar la vigencia y aplicabilidad de las prestaciones o beneficios solicitados.
    </p>
    <p class="mb-4">
        El Beneficiario podrá hacer uso de los servicios contratados hasta las cero 00:00 horas del día de su aniversario según el plan contratado. A partir de esa fecha el Beneficiario pierde todo derecho a los beneficios en cuanto a las prestaciones de Asistencia definidas en estas Condiciones Generales, así como el derecho a reintegro o reclamo alguno originado en eventos posteriores a tal día. A manera de ejemplo, se considera que una persona tiene 84 años hasta el día anterior a cumplir los 85 años.
    </p>
    <p class="font-bold mb-4 underline">III. VIGENCIA - VALIDEZ</p>
    Es el lapso de tiempo en el que pueden ser obtenidos los beneficios indicados en los planes de <b>NOW ASSISTANCE</b>. Es el
    que transcurre desde las cero horas del día de comienzo de validez de dicho plan, estando el beneficiario en
    territorio extranjero, hasta las 24 (23.59) horas del día del fin de dicha validez. Ambas fechas reflejadas en el
    voucher adquirido por el Beneficiario. La finalización de la vigencia implicará el cese automático de todos los
    beneficios, prestaciones, o servicios en curso o no, incluyendo aquellos casos o tratamientos iniciados al momento o
    antes del término de la vigencia.
    Los planes en la categoría “Viajes Cortos”, tendrán una vigencia máxima de 90 días consecutivos de viaje. Pasados
    este período, el Beneficiario perderá todo beneficio de los servicios de asistencia contratado mientras esté en ese
    viaje.
    Los planes NOW ASSISTANCE, operan bajo la modalidad de días corridos, por lo tanto, una vez iniciada la vigencia de
    un plan, no es posible interrumpir la misma, los periodos de días no utilizados en los voucher no son reembolsables.

    El beneficiario podrá regresar a su país de residencia habitual durante el periodo de vigencia de su certificado de
    asistencias, teniendo en cuenta que no tendrá cobertura en dicho país. Sin embargo, si la fecha de vigencia de su
    certificado de asistencias lo permite, podrá retomar su viaje y continuar con la cobertura contratada en el
    exterior.

    El propósito del viaje tendrá que ser turístico y en ningún momento podrá garantizar a personas que ejerzan una
    actividad profesional y/o laboral en el exterior. Si el motivo del viaje del Beneficiario fuese la ejecución de
    cualquier tipo de trabajo, aún de bajo riesgo o tareas que involucren un alto riesgo profesional, por realizar
    tareas de alta especialización en donde se exponga la vida, se esté expuesto a sustancias peligrosas, al manejo de
    maquinarias pesadas o que funcionen con gases, presión de aire o fluidos hidroneumáticos, que requieran habilidades
    físicas especiales, o donde se vea expuesto a peligro y como consecuencia de ello sufra un accidente o una
    enfermedad consecuencial, <b>NOW ASSISTANCE</b> quedará eximido de toda responsabilidad de prestar sus servicios o asumir
    costos que se deriven de tales circunstancias, y en estos casos será obligación patronal de asumirlos a través de su
    plan de responsabilidad de riesgos profesionales. Esta normatividad aplica igualmente a aquellas personas que no
    estén ligadas laboralmente con una empresa y que actúan por su cuenta como trabajadores independientes o en
    situación migratoria o laboral ilegal.

    En casos en que el beneficiario se encuentre hospitalizado por una enfermedad y/o accidente cubierto por NOW
    ASSISTANCE en la fecha de terminación del período de cobertura, se cubrirán únicamente los gastos por
    correspondientes a Hotelería Hospitalaria dentro de la cobertura de gastos médicos por enfermedad y/o accidente
    según corresponda entendiéndose de la siguiente manera:

    1. Hasta ocho (8) días adicionales contados desde el día de finalización de vigencia del voucher, o
    2. Hasta que se haya agotado la cobertura contratada, o
    3. Hasta que el médico firme el alta del Beneficiario en el transcurso de los ocho (8) días de ampliación de
    cobertura
    4. Los gastos de Hotelería Hospitalaria hacen referencia al valor de la cama, la habitación que el beneficiario
    ocupe durante este período, excluyendo cualquier otro gasto como, por ejemplo, cirugías, honorarios médicos,
    medicamentos, estudios de imágenes y laboratorios, terapias de recuperación física, traslados, etc.

    Toda asistencia o tratamiento cesará y no será responsabilidad de <b>NOW ASSISTANCE</b> una vez que el Beneficiario regrese
    a su lugar de residencia o expire el período de validez del plan elegido salvo excepciones anteriormente mencionadas
    o los gastos de la asistencia hayan alcanzado el límite de cobertura contratada.


    <p class="font-bold mb-4 underline">IV. VALIDEZ GEOGRÁFICA</p>
    La cobertura geográfica será de carácter Mundial. Independientemente de donde se encuentre el Beneficiario, se le
    dará cobertura en caso de requerir asistencia de acuerdo al respectivo plan adquirido. Se excluye en todo caso el
    país de residencia habitual del Beneficiario.


    <p class="font-bold mb-4 underline">V. PROCEDIMIENTO PARA SOLICITAR ASISTENCIA - CENTRALES DE ASISTENCIAS</p>
    De necesitar asistencia, e independientemente de su situación geográfica en estricta concordancia con el resto de
    las cláusulas de este condicionado general, el Beneficiario deberá contactar a la Central de Servicios de Asistencia
    <b>NOW ASSISTANCE</b> y/o terceros que ella indique. Para poder contactarse con dicha central podrá hacerlo por WhatsApp
    y/o por vía telefónica. En caso de hacerlo por vía telefónica, el Beneficiario podrá solicitar la llamada por cobrar
    o llamar directamente a la Central de Servicios de Asistencia en los números habilitados por los países indicados.

    En caso tal de existir cobro por contactarse con la Central de Servicios de Asistencia y reportar la emergencia, NOW
    ASSISTANCE le reembolsará al Beneficiario el costo de la llamada; para ello solicitamos guardar el comprobante o
    factura por el pago de dicha llamada, donde esté reflejado el cobro a alguno de los números indicados en el voucher
    de asistencia correspondiente.

    Es obligación del Beneficiario siempre contactarse y reportar la emergencia. En los casos en que el Beneficiario no
    pueda hacerlo personalmente deberá y podrá hacerlo cualquier persona, acompañante, amigo o familiar pero siempre el
    contacto deberá hacerse a más tardar dentro de las 24 horas de haber sucedido la emergencia. Para los casos en que
    el beneficiario se encuentre en altamar, y por ende impedido de comunicarse con la Central de Asistencias, deberá
    informar el hecho médico hasta 24 horas después de desembarcado en el primer puerto al que arribe. El no
    cumplimiento de esta norma acarrea la pérdida automática de cualquier derecho de reclamo de parte del Beneficiario.


    <p class="font-bold mb-4 underline">VI. OBLIGACIONES DEL BENEFICIARIO</p>
    En todos los casos, para la obtención de los servicios el Beneficiario debe:
    1. Solicitar y obtener la autorización de la Central de Servicios de Asistencia antes de tomar cualquier iniciativa
    o comprometer cualquier gasto en relación a los beneficios otorgados por el plan de la voucher asistencia. En los
    casos donde no se haya solicitado la autorización a la central ni se haya obtenido la autorización, no procederán
    reintegros algunos, ni darán derechos a reclamos.
    2. Queda claramente entendido que la notificación a la central resulta imprescindible, aun cuando el problema
    suscitado se encuentre totalmente resuelto, ya que <b>NOW ASSISTANCE</b> no podrá tomar a su cargo el costo de ninguna
    asistencia sin el previo conocimiento y autorización de la Central de Servicios de Asistencia.
    3. El Beneficiario acepta que <b>NOW ASSISTANCE</b> se reserva el derecho de grabar y auditar las conversaciones
    telefónicas que estime necesarias para el buen desarrollo de la prestación de sus servicios. El Beneficiario acepta
    expresamente la modalidad indicada y manifiesta su conformidad por la eventual utilización de los registros como
    medio de prueba en caso de existencia de controversias respecto de la asistencia prestada.
    4. Si el Beneficiario o una tercera persona no pudiera comunicarse por una circunstancia o razón involuntaria con la
    Central de Servicios de Asistencia antes de ser asistido, el Beneficiario o una tercera persona, con la obligación
    ineludible, deberá informar a más tardar dentro de las 24 horas de producido el evento. No notificar dentro de las
    24 horas acarrea la pérdida automática de los derechos del Beneficiario a reclamar o solicitar indemnización alguna.
    5. Aceptar y acatar las soluciones indicadas y recomendadas por la Central de Servicios de Asistencia y llegado el
    caso, consentir con la repatriación a su país de origen cuando, según opinión médica, su estado sanitario lo permita
    y lo requiera.
    6. Proveer la documentación que permita confirmar la procedencia del caso, así como todos los comprobantes
    originales de gastos y que acrediten haber sido abonados a ser evaluados para su eventual reintegro por NOW
    ASSISTANCE y toda la información médica (incluyendo la anterior al inicio del viaje), que permita a la central la
    evaluación del caso.
    7. En todos aquellos casos en que <b>NOW ASSISTANCE</b> lo requiera, el Beneficiario deberá otorgar las autorizaciones para
    revelar su historia clínica completando el Record Release Form que el centro médico solicitará firmar y devolverá
    por fax o por mail a la Central de Servicios de Asistencia. Asimismo, el Beneficiario autoriza en forma absoluta e
    irrevocable a <b>NOW ASSISTANCE</b> a requerir en su nombre cualquier información médica a los profesionales tanto del
    exterior como del país de su residencia, con el objetivo de poder evaluar y eventualmente decidir sobre la
    aplicabilidad de las restricciones en casos de enfermedades o condiciones preexistentes o de la afección que haya
    dado origen a su asistencia. Recomendamos en forma especial a los Beneficiarios diligenciar siempre el Formulario
    cuando se registren en un centro hospitalario, eso será de gran ayuda en los casos de reintegros y/o en la toma de
    decisiones sobre determinados casos que requieran del estudio del historial médico del paciente.

    Nota: En algunos países y principalmente en los Estados Unidos de Norteamérica y Europa, debido a razones de
    estandarización informática la mayoría de los centros médicos asistenciales tales como hospitales, consultorios,
    clínicas, laboratorios suelen enviar facturas y/o reclamos de pago a los pacientes atendidos, inclusive aun después
    de que las cuentas o facturas haya sido pagadas y saldadas. En caso de que ello ocurra, el Beneficiario deberá
    dejarlo notificado en el apartado de “reclamos” de la página web www.XXXXXXX.com.


    <p class="font-bold mb-4 underline">VII. OBLIGACIONES ASUMIDAS POR NOW ASSISTANCE</p>
    1. Cumplir las prestaciones y beneficios descritos en las Condiciones Generales de eventos cubiertos en el plan
    contratado durante la vigencia del voucher.
    2. <b>NOW ASSISTANCE</b> queda expresamente liberada, exenta y excusada de cualquiera de sus obligaciones y
    responsabilidades en caso de que el Beneficiario sufra algún daño o solicite asistencia a consecuencia y/o derivada
    de caso fortuito o de fuerza mayor, la cuales se citan a título de ejemplo y no taxativamente: catástrofes, sismos,
    inundaciones, tempestades, guerra internacional o guerra civil declaradas o no, rebeliones, conmoción interior,
    insurrección civil, actos de guerrilla o anti guerrilla, hostilidades, represalias, conflictos, embargos, apremios,
    huelgas, movimientos populares, lockout, actos de sabotaje o terrorismo, disturbios laborales, actos de autoridades
    gubernamentales, etc.; así como problemas y/o demoras que resulten por la terminación, interrupción o suspensión de
    los servicios de comunicación. Cuando elementos de esta índole interviniesen y una vez superados los mismos, NOW
    ASSISTANCE se compromete a ejecutar sus compromisos y obligaciones dentro del menor plazo posible.
    3. <b>NOW ASSISTANCE</b> se obliga a analizar cada solicitud de reintegro para determinar si es procedente y en
    consecuencia reintegrar los montos que correspondieren de acuerdo a las presentes condiciones generales y montos de
    cobertura del Plan contratado. Todas las compensaciones y/o reintegros y/o demás gastos que vayan a asumirse por NOW
    ASSISTANCE, en el marco del presente contrato, podrán ser abonados en moneda local correspondiente al país de
    residencia informado al momento de realizar la compra del voucher de asistencia o a elección de la empresa los
    reintegros podrán abonarse en dólares estadounidenses si lo permiten las normativas legales del país de residencia
    del beneficiario y/o del país desde donde se realice el reembolso. El tipo de cambio de moneda que se aplicará para
    el reintegro es al cambio oficial del día del efectivo pago del gasto a reintegrar.

    Los tiempos establecidos para el procesamiento de un reintegro son:
    a. El Beneficiario tiene hasta treinta (30) días continuos a partir del día finalización de la vigencia del voucher
    para
    presentar la documentación y respaldos necesarios para iniciar el proceso de reintegro. Después de ese plazo, no se
    aceptarán documentos para tramitar ningún reintegro.

    Nota: Para solicitar un reintegro, el beneficiario deberá completar el formulario de reintegro online en la página
    web www.XXXXXXX.com.

    b. Una vez recibidos los documentos, <b>NOW ASSISTANCE</b> tiene hasta quince (15) días continuos para solicitar cualquier
    documento faltante que no haya sido entregado por el Beneficiario.

    c. Con todos los documentos necesarios en mano, <b>NOW ASSISTANCE</b> procederá durante los siguientes quince (15) días
    hábiles a analizar el caso y emitir la carta de aprobación o negación de dicho reintegro.

    d. Siendo procedente el reintegro, <b>NOW ASSISTANCE</b> procederá a efectuar el pago en 15 días, posteriores a la fecha de
    recepción de los datos completos vía escrita para la realización de la transferencia.

    Nota: Los reintegros pagados directamente por <b>NOW ASSISTANCE</b> pueden hacerse a través de transferencia bancaria,
    sistemas de pago electrónico y/o virtual, giro postal o cheque. Cuando el beneficiario realiza un reintegro deberá
    tener en su poder, todos los comprobantes originales de los gastos por los cuales solicita reintegros. Estos
    comprobantes originales podrán ser solicitados por <b>NOW ASSISTANCE</b> para una auditoría, ante esta solicitud el
    beneficiario deberá enviarlos a la oficina que <b>NOW ASSISTANCE</b> indique.


    <p class="font-bold mb-4 underline">VIII. MONEDA DE ALCANCES Y SERVICIOS</p>
    Los beneficios ofrecidos por <b>NOW ASSISTANCE</b> se detallan en el punto IX. y sus límites de cobertura máximos están
    reflejados en el voucher contratado expresados en dólares americanos (USD).


    <p class="font-bold mb-4 underline">IX. DEFINICIÓN DE LOS BENEFICIOS</p>
    Algunos beneficios se encuentran incluidos solo en algunos productos <b>NOW ASSISTANCE</b>. Verifique en su voucher los
    beneficios y topes contratados. Si en su voucher no figura algún ítem, es porque el producto por usted elegido no
    dispone de este servicio.

    Asistencia médica por accidente/enfermedad o condición médica no preexistente

    - Consultas Médicas: Se prestarán en caso de accidente y enfermedad o condición médica aguda e imprevista no
    preexistente.
    - Atención por Especialistas: Se prestará únicamente cuando sea indicada y autorizada por el Departamento Médico de
    la Central de Servicios de Asistencia de <b>NOW ASSISTANCE</b> o por el médico tratante de la central.
    - Exámenes Médicos Complementarios: Únicamente cuando sean indicados y previamente autorizados por el Departamento
    Médico de la Central de Servicios de Asistencia.
    - Internaciones: De acuerdo con la naturaleza de la lesión o enfermedad, y siempre que el Departamento Médico de la
    Central de Servicios de Asistencia de <b>NOW ASSISTANCE</b> así lo prescriba, se procederá a la internación del
    Beneficiario en el centro sanitario más próximo al lugar donde éste se encuentre y/o el más conveniente. Este ítem
    aplicará únicamente al Beneficiario del plan de asistencia, y bajo ningún motivo se cubrirá cama y/o alimentación en
    el hospital o clínica para una persona acompañante.
    - Intervenciones Quirúrgicas: Cuando sean autorizadas por el Departamento Médico de la Central de Servicios de
    Asistencia, en los casos de emergencia que requieran en forma inmediata tratamiento, y que no puedan ser diferidas o
    postergadas hasta el retorno del Beneficiario a su país de origen.
    - Terapia Intensiva y Unidad Coronaria: cuando la naturaleza de la enfermedad o lesión así lo requiera, y siempre
    con la previa autorización del Departamento Médico de la Central de Servicios de Asistencia se autorizará este
    servicio.

    Nota: La Central de Servicios de Asistencia se reserva el derecho de decidir el más adecuado de entre los
    tratamientos propuestos por el cuerpo médico y/o la repatriación al país de residencia si su condición física lo
    permitiere. Si a juicio de los médicos tratantes de la Central de Servicios de Asistencia fuese posible el regreso
    al lugar de origen para recibir en el tratamiento de larga duración, cirugía programable o cirugías no urgentes, se
    procederá a la repatriación del Beneficiario, quien está obligado a aceptar tal solución, perdiendo en caso de
    rechazo todos los beneficios otorgados por su plan <b>NOW ASSISTANCE</b>.

    - Asistencia médica por COVID-19 (hasta 85 años inclusive)
    El Beneficiario deberá siempre y sin excepción entrar en contacto con la Central de Emergencias, quienes a su vez
    coordinarán una cita virtual por Telemedicina y, de acuerdo con el dictamen brindado por el Departamento Médico, si
    el
    Beneficiario presenta sintomatología relacionada al COVID-19, la Central de Asistencias coordinará la consulta
    médica pertinente, de acuerdo a los protocolos de seguridad y salud de cada país, cubriendo los gastos incurridos
    hasta el tope de cobertura indicado en el voucher por este concepto. Los siguientes gastos estarán cubiertos bajo el
    mismo tope:
    ◦ Gastos Hospitalarios por COVID-19: En caso de requerir internación hospitalaria para estabilizar la condición del
    Beneficiario.
    ◦ Gastos de respirador mecánico: Si el Departamento Médico, en conjunto con el médico tratante, considera necesario
    el uso de un respirador mecánico, la Central autorizará y cubrirá dicho gasto.
    ◦ Test PCR: Con previa autorización del Departamento Médico de la central de asistencia, en conjunto con el médico
    tratante, si se considera necesaria la prueba PCR, se contemplará el costo de la prueba que permita obtener el
    diagnóstico que permita brindarle continuidad al tratamiento de emergencia necesario. La realización del test PCR
    debe ser autorizado y aprobado por el equipo médico de nuestra Central de Emergencias. El beneficiario deberá
    presentar síntomas que ameriten su realización. No será cubierto por decisión unilateral del mismo o porque tiene
    como requisito presentar su resultado ante una entidad migratoria, gubernamental, de frontera, sanitaria, laboral
    y/o empresa de transporte.

    ESTE BENEFICIO NO OPERARÁ A MANERA DE REINTEGRO, SALVO CUANDO SEA PREVIAMENTE AUTORIZADO POR <b>NOW ASSISTANCE</b>.

    Nota 1: El límite de edad para este beneficio es de 85 años inclusive.
    Nota 2: Este producto no cubrirá gastos de cuarentena en hotel, se limitará únicamente a gastos médicos.

    - Cobertura en el país de residencia, limitado a accidentes
    En aquellos casos donde el beneficiario debiera regresar a su país de residencia durante la vigencia del plan
    contratado, únicamente como consecuencia de la muerte de familiar en primer grado de consanguinidad o por siniestro
    grave en domicilio dentro del periodo de vigencia del voucher de asistencia, y siempre que el propósito original del
    viaje sea por vacaciones demostrables y una vez de regreso en su país de residencia requiriera de asistencia médica
    a causa de un accidente en dicho lugar, se contemplará el beneficio de asistencia médica por accidente ocurrido en
    país residencia del beneficiario hasta el límite específico indicado en el plan contratado. Este beneficio contará
    con una vigencia de 30 días máximo tras la llegada al país de residencia, y nunca podrá exceder la vigencia original
    del plan contratado y está sujeto a subrogación. Esta cobertura aplica solamente si el Beneficiario no tiene ningún
    sistema de cobertura en su país de residencia.
    <b>NOW ASSISTANCE</b> no tomará a su cargo accidentes cuyo origen sean responsabilidad del beneficiario, refiriéndose a
    eventos en país de residencia que se encuentren en el anexo “exclusiones aplicables a todos los servicios y
    beneficios” de las condiciones generales.

    - Asistencia médica por enfermedad preexistente.
    En aquellos casos en que el Beneficiario contrate específicamente la cobertura para emergencias sufridas por una
    condición preexistente y/o crónica, se cubrirá hasta el monto que se especifique claramente en su voucher por este
    concepto.
    La cobertura proporcionada para enfermedades crónicas y/o preexistentes contempla las siguientes eventualidades:
    - Episodio agudo o evento no predecible, descompensación súbita e inesperada de enfermedades crónicas y/o
    preexistentes conocidas o previamente asintomáticas.
    Esta cobertura se proporciona exclusivamente para la atención médica primaria en el episodio agudo, o caso no
    predecible hasta lograr la estabilización del Beneficiario. La emergencia por estas dolencias debe requerir la
    asistencia médica durante el viaje y no puede aplazarse hasta el retorno al país de residencia, la Central de
    Asistencias se reserva el derecho de decidir el tratamiento más adecuado de entre los propuestos por el personal
    médico y/o la repatriación a su país de residencia.
    La repatriación podrá ser una posible solución y el monto disponible para los gastos de repatriación sanitaria ante
    enfermedades crónicas y/o preexistentes será el monto específico indicado el producto como Asistencia médica por
    enfermedad preexistente.
    El beneficiario está obligado a aceptar todas las soluciones propuestas por NOW ASSISTANCE, perdiendo en caso de
    rechazo de la solución, todos los beneficios que ofrece el plan de asistencia.

    Se excluye de este beneficio:
    - El inicio o la continuación de tratamientos, procedimientos diagnósticos, de investigación, o conducta diagnóstica
    y terapéutica, que no están relacionados con el episodio agudo e impredecible.
    - Todas las enfermedades relacionadas con la transmisión sexual, incluyendo, pero no limitando a la sífilis, la
    gonorrea, el herpes genital, la clamidia, el virus del papiloma humano tricomonas vaginalis, tricomoniasis, virus de
    la inmunodeficiencia humana (VIH), el síndrome de inmunodeficiencia adquirida (SIDA), entre otros.
    - Procedimientos de diálisis, trasplantes, oncología y tratamiento psiquiátrico, audífonos, anteojos, lentes de
    contacto, puentes dentales, marcapasos, desfibriladores implantables, respiradores externos, dispositivos
    implantables, equipo
    desechable específico, etc.
    - Enfermedades causadas por la ingestión de drogas, estupefacientes, medicamentos que se toman de forma no fiable
    sin receta, alcoholismo, etc.
    - Las lesiones sufridas durante un acto ilícito.
    - Los viajes y actividades realizadas que estuvieran medicamente desaconsejadas.
    - Las asistencias médicas solicitadas luego que el Beneficiario haya recibido un diagnóstico terminal.
    - Cuando se determine que la razón del viaje fue el tratamiento médico en el extranjero.

    - Médico Online 24 horas
    El Beneficiario de <b>NOW ASSISTANCE</b> tendrá el servicio disponible las 24 horas del día los 365 días al año, con el
    propósito de atender citas virtuales de medicina general ante dolencias leves y siempre que puedan ser atendidas por
    parte de un Especialista en Telemedicina. El beneficiario será valorado, y podrá ser diagnosticado y tratado por el
    profesional, y se le podrán brindar las recomendaciones, indicaciones, sugerencias y prescripciones de medicamentos
    apropiadas, según las necesidades de su sintomatología.

    Desde la comodidad de su vivienda el Usuario/Contratante de <b>NOW ASSISTANCE</b> podrá solicitar una asistencia médica
    virtual con alguno de nuestros profesionales en la salud; de acuerdo con la disponibilidad del usuario se pacta un
    horario de encuentro con nuestro doctor. Un link es generado y enviado al usuario con el fin de dar inicio a la
    asistencia médica virtual.

    Nota: El departamento medico de <b>NOW ASSISTANCE</b> se reserva el derecho de decidir el más adecuado de entre los
    tratamientos propuestos por el cuerpo médico.
    - Terapias de recuperación física Estas terapias de recuperación física (fisioterapias o kinesioterapias) se
    cubrirán únicamente en caso tal que la dolencia haya sido ocasionada por un accidente no laboral bajo autorización
    previa del Departamento Médico de la Central de Servicios de Asistencia en caso tal que se determine que con las
    mismas el beneficiario podrá mejorar su condición actual y bajo ningún motivo, podrá exceder las diez (10) sesiones.

    - Soporte Emocional a través de Tele psicología
    Teléfono de apoyo psicológico 24 horas para Beneficiarios que, a causa de repatriación sanitaria, fallecimiento de
    un familiar o catástrofe natural hayan sido afectados durante su viaje. Este servicio se presta como apoyo
    psicológico en momentos que pueden generar fuerte tensión emocional, en ningún caso podrá sustituir la atención
    directa del Psicólogo o Psiquiatra de los Beneficiarios, por lo que no debe utilizarse en ningún caso por parte de
    los mismos para establecer un diagnóstico o automedicarse, debiendo consultar con los referidos profesionales en
    cada caso particular.

    - Medicamentos ambulatorios
    En los límites de cobertura, <b>NOW ASSISTANCE</b> se hará cargo de los gastos de los medicamentos recetados por el Médico
    Tratante de la Central de Servicios de Asistencia hasta los montos establecidos en los topes de cobertura del plan
    contratado. Los desembolsos realizados por el Beneficiario para la compra de medicamentos previamente autorizados
    por la Central de Servicios de Asistencia serán reintegrados, dentro de los límites de cobertura, y contra la previa
    presentación de los comprobantes originales de compra y la acreditación de su pago, de la copia original del
    dictamen o informe médico en donde indique claramente el diagnóstico recibido, así la fórmula o receta médica.
    Recomendamos a los Beneficiarios no olvidar solicitar estos documentos al médico tratante, la no presentación de
    estos documentos puede resultar en el no reembolso de los gastos.
    Se deja constancia y se informa que los gastos de medicamentos por concepto de enfermedades preexistentes no serán
    asumidos por NOW ASSISTANCE, así hayan sido diagnosticados por el Médico Tratante de la Central de la Central de
    Servicios de Asistencia. Se encuentran también excluidos los medicamentos por tratamiento de enfermedades mentales o
    psíquicas o emocionales, aun en los casos en que la consulta médica haya sido autorizada por el Departamento Médico
    de <b>NOW ASSISTANCE</b>.
    Tampoco se cubrirán bajo ningún motivo las pastillas anticonceptivas, inyecciones anticonceptivas, dispositivos
    intrauterinos o cualquier otro método de planificación familiar, así hayan sido diagnosticados por el Médico
    Tratante de la Central de la Central de Servicios de Asistencia.

    Nota: Las prescripciones médicas destinadas a la recuperación inicial de los síntomas sólo serán autorizadas por los
    primeros 30 días de tratamiento.

    - Medicamentos en caso de hospitalización
    En caso de que el Beneficiario fuera hospitalizado a consecuencia de algún accidente o enfermedad no preexistente
    <b>NOW ASSISTANCE</b> cubrirá los medicamentos que tengan relación directa con el motivo de la hospitalización referente a
    la atención médica brindada y vayan acorde con el diagnóstico otorgado por el Departamento Médico de la Central de
    Asistencia, hasta los límites indicados en su voucher de asistencia. Este servicio no tendrá validez si el
    diagnóstico es resultado de una enfermedad preexistente, congénita, recurrente y si el caso no está autorizado por
    <b>NOW ASSISTANCE</b>.

    - Práctica recreativa de deportes
    <b>NOW ASSISTANCE</b> asumirá los gastos causados por eventos ocurridos en la práctica de deportes, solamente cuando dicha
    actividad tenga carácter meramente recreativo, exclusivamente en lugares habilitados, autorizados y adecuados a tal
    fin, siempre y cuando no se trate de ninguna de las actividades que se encuentran explícitamente excluidas de
    acuerdo a lo estipulado por la cláusula “X. Exclusión aplicables a todos los servicios y beneficios”.

    - Prótesis por operación
    Solo se autorizará y hasta el monto máximo indicado para este beneficio, si debido a una emergencia o urgencia que
    ponga en riesgo la vida del Beneficiario, y por lo tanto el mismo deba ser sometido a un procedimiento quirúrgico en
    donde el Departamento Médico de la Central, en conjunto acuerdo con el Equipo Médico que esté asistiendo al
    Beneficiario, indique que es necesario el uso de una prótesis para salvaguardar la vida del paciente.
    El equipo médico de <b>NOW ASSISTANCE</b> se reserva el derecho de determinar la prótesis, o ayuda mecánica a ser
    suministrada al Beneficiario. Se deja constancia que a los efectos interpretativos que se considerará “prótesis,
    ortesis, síntesis y/o ayudas mecánicas” a toda extensión artificial necesaria para reemplazar una parte del cuerpo
    como así también todo elemento que sirva de apoyo, u otro dispositivo externo aplicado al cuerpo para modificar los
    aspectos funcionales y/o estructurales del sistema neuromuscular esquelético.
    Quedan expresamente excluidos los gastos por prótesis estéticas, odontológicas, audífonos, anteojos, lentes de
    contacto y prótesis funcionales de competición; como así también los gastos ocasionados por rotura, desgaste o
    mantenimiento de prótesis, órtesis, síntesis o ayudas mecánicas que resulten necesarios por el paso del tiempo o por
    algún motivo no accidental.

    - Evacuación Médica
    En caso de emergencia o de urgencia y si la Central de Servicios de Asistencia lo juzgare necesario, se organizará
    el traslado del Beneficiario al centro de salud más cercano, por el medio de transporte que el Departamento Médico
    de la Central de Servicios de Asistencia considere más apropiado y según corresponda a la naturaleza de la lesión o
    enfermedad. Queda igualmente establecido que aun para los casos de tratamientos y cirugías que ocurren en casos
    catalogados como urgencia o emergencia el traslado sanitario debe ser previamente solicitado y autorizado por la
    central de <b>NOW ASSISTANCE</b>. El no cumplimiento de esta norma exime a <b>NOW ASSISTANCE</b> de tomar a cargo la cobertura de
    dicho traslado.

    - Urgencia Odontológica
    En los límites de cobertura, <b>NOW ASSISTANCE</b> se hará cargo de los gastos por atención odontológica derivados de una
    urgencia, debido o causada por un trauma, accidente o infección, limitándose únicamente al tratamiento del dolor y/o
    a la extracción de la pieza dentaria resultante de infección o trauma únicamente. Los tratamientos odontológicos de
    conductos, cambios de calzas, coronas, prótesis, sellamientos, limpiezas dentales, diseños de sonrisa o cualquier
    otro tratamiento no especificado claramente en estas condiciones se encuentran excluidos de la cobertura.

    - Repatriación sanitaria
    Se entiende por repatriación sanitaria el traslado del Beneficiario enfermo o accidentado desde el lugar en donde se
    encuentra hasta el aeropuerto de ingreso del país de residencia habitual. Solamente el Departamento Médico de NOW
    ASSISTANCE podrá autorizar a tomar todas las providencias mencionadas en esta cláusula, quedando el Beneficiario o
    un familiar prohibido de realizarlo por cuenta propia sin la previa autorización escrita de <b>NOW ASSISTANCE</b>.
    Adicionalmente la repatriación deberá ser autorizada y justificada médica y científicamente por el Médico tratante
    de <b>NOW ASSISTANCE</b> , en el caso en que el Beneficiario y o sus familiares o acompañantes decidieran efectuar la
    repatriación dejando de lado o sin solicitar la opinión del Departamento Médico de NOW ASSISTANCE, de realizarse de
    esta manera, ninguna responsabilidad recaerá sobre <b>NOW ASSISTANCE</b> siendo por lo tanto la repatriación así como todos
    los demás gastos y consecuencias, responsabilidad del Beneficiario o de sus familiares o acompañantes, sin derecho a
    reclamo contra <b>NOW ASSISTANCE</b>.

    Cuando el Departamento Médico de <b>NOW ASSISTANCE</b> en común acuerdo con el Médico Tratante estime necesario y
    recomiende la repatriación sanitaria, esta se efectuará en primera instancia por el medio de transporte disponible
    más conveniente para ello, y/o por avión de línea aérea comercial, en clase turista y sujeta a disponibilidad de
    cupo aéreo, hasta el aeropuerto de ingreso del país de residencia. <b>NOW ASSISTANCE</b> se hará cargo del pago de las
    diferencias por cambio de fecha del pasaje o la compra de uno nuevo si el original se tratase de un tiquete sin
    posibilidad de cambio. Esta asistencia comprende su transporte en ambulancia u otro medio de transporte que sea
    compatible con su estado de salud y aprobado por el Departamento Médico de <b>NOW ASSISTANCE</b> desde el lugar de
    internación el aeropuerto de salida, con la estructura de apoyo necesaria incluyendo camilla, sillas de rueda,
    caminador, acompañamiento médico, etc.
    No será reconocido ningún gasto por concepto de repatriación cuando la causa que dio origen a ella es a consecuencia
    de una enfermedad preexistente u obedezca a un evento que figura dentro de las exclusiones generales, salvo en
    planes que contemplen preexistencias. Este beneficio aplicará única y exclusivamente dentro de las fechas de
    vigencia de su voucher.

    - Repatriación funeraria
    En caso de fallecimiento del Beneficiario durante la vigencia de la tarjeta <b>NOW ASSISTANCE</b> a causa de un evento no
    excluido en las condiciones generales. <b>NOW ASSISTANCE</b> organizará y sufragará la repatriación funeraria tomando a su
    cargo los gastos de: féretro simple obligatorio para el transporte internacional, los trámites administrativos y el
    transporte del cuerpo por el medio que consideren más conveniente hasta el aeropuerto de ingreso al país de
    residencia habitual del fallecido, hasta el tope especificado en la tabla de beneficios.
    Si el derechohabiente lo desea, dentro de esta misma cobertura, puede optar por la cremación del cuerpo y también
    estarán incluidos todos los trámites administrativos a los que haya lugar y traslado de cenizas hasta el aeropuerto
    de ingreso al país de residencia habitual del fallecido.
    Los gastos que se originaren como consecuencias de velatorios y/o cualquier clase de ceremonias en el exterior no
    serán responsabilidad económica de <b>NOW ASSISTANCE</b>.
    Los gastos de féretro definitivo, trámites funerarios, traslados terrestres o aéreos y/o cualquier otro gasto en el
    país de residencia e inhumación no estarán a cargo de <b>NOW ASSISTANCE</b>.
    <b>NOW ASSISTANCE</b> quedará eximido de prestar los servicios y asumir los costos relativos al presente beneficio en caso
    que el fallecimiento del Beneficiario se origine por causa de un suicidio o fallecimiento por consecuencia de
    ingesta de alcohol o cualquier tipo de drogas, tampoco corresponderá la repatriación funeraria en aquellos casos en
    que el Beneficiario hubiera viajado con fines de tratamiento médico y/o como consecuencia de recibir dicho
    tratamiento requiera de estos servicios. Tampoco corresponderá la repatriación funeraria cuando la compra del
    producto es realizada con el Beneficiario en viaje mientras se encuentra hospitalizado y/o cuando se determine que
    la compra del producto es realizada porque existe la posibilidad de requerir estos servicios.
    Este beneficio no contempla ni incluye bajo ninguna circunstancia gastos de regreso de familiares acompañantes del
    fallecido, por lo que <b>NOW ASSISTANCE</b> no tomará a su cargo ningún gasto de terceros.

    - Traslado de un familiar por hospitalización en 1º. Grado de consanguinidad
    En caso de que la hospitalización de un Beneficiario, viajando solo y no acompañado, fuese superior a diez (10)
    días, <b>NOW ASSISTANCE</b> se hará cargo del reembolso, hasta el monto máximo indicado en el producto, de un pasaje aéreo
    en clase turista, sujeto a disponibilidad de espacio para un familiar de compañía.

    - Compensación por pérdida de equipaje
    <b>NOW ASSISTANCE</b> compensará al Beneficiario de forma complementaria, hasta el tope especificado en la tabla de
    beneficios. Para la obtención de este beneficio rigen los siguientes términos y condiciones:

    - Que la línea aérea y la Central de <b>NOW ASSISTANCE</b> hayan sido notificadas del hecho por el Beneficiario antes de
    abandonar el aeropuerto donde se registró la pérdida siguiendo las instrucciones descritas más abajo.
    - Que el equipaje se haya extraviado durante su transporte en vuelo regular internacional, este beneficio no aplica
    cuando la pérdida se origina en un trayecto de vuelo nacional, ni de vuelos charters o fletados, aviones
    particulares o militares, o cualquier vuelo que no tenga un itinerario fijo publicado que opera regularmente, ni
    tampoco cuando la pérdida se origina en vuelos domésticos en el exterior.
    - Que el mencionado equipaje haya sido debidamente registrado, etiquetado y despachado en la bodega del avión y haya
    sido debidamente presentado y entregado al personal de la aerolínea en el despacho del terminal. NOW ASSISTANCE, no
    indemnizará a los Beneficiarios de un plan, por la pérdida de equipaje de mano o de cabina o cualquier otro bulto
    que no haya sido debidamente registrado ante la aerolínea y haya sido transportado en la bodega del avión.
    - Que la pérdida del equipaje haya ocurrido entre el momento en que el mismo fue entregado a personal autorizado de
    la línea aérea para ser embarcado y el momento en que debió ser devuelto al pasajero al finalizar el viaje.
    - Que la línea aérea se haya hecho cargo de su responsabilidad por la pérdida del mencionado equipaje, y haya
    abonado o pagado al Beneficiario la indemnización prevista por ella. <b>NOW ASSISTANCE</b> no podrá indemnizar al
    Beneficiario cuando este no haya aún recibido la indemnización de la aerolínea.
    - No tienen derecho a esta compensación las pérdidas ocurridas en cualquier tipo de transporte que no sea transporte
    aéreo en el extranjero.
    - La compensación por pérdida total del equipaje se limitará a un solo bulto entero y completo faltante en forma
    definitiva y a un solo Beneficiario damnificado. En el caso de que el bulto faltante estuviera a nombre de varios
    Beneficiarios, la compensación será prorrateada entre los mismos, siempre que incluya los correspondientes números
    de billetes de cada uno, así como el número de voucher. No se indemnizan faltantes parciales de las maletas ni
    tampoco roturas o daños a la maleta ni al contenido de la misma.
    - En caso que la línea aérea ofreciera al Beneficiario como indemnización la posibilidad de optar entre percibir un
    valor en dinero o uno o más pasajes u otro medio de compensación, <b>NOW ASSISTANCE</b> procederá a abonar al Beneficiario
    la compensación económica por extravío de equipaje, una vez que dicha opción sea ejercida.

    Es importante anotar que, en los casos de extravío de equipaje, los directos responsables de las mismas son las
    aerolíneas o empresas transportadoras, por lo tanto, <b>NOW ASSISTANCE</b> intervendrá en calidad de intermediario
    facilitador entre la aerolínea y o la compañía transportadora y el pasajero, y por lo tanto no podrá ser considerada
    ni tomada como responsable directa de dicha pérdida, ni de la
    búsqueda del equipaje. Las aerolíneas se reservan el derecho de aceptar o no los reclamos a NOW ASSISTANCE, y en
    términos generales pueden exigir que las reclamaciones sean puestas directamente por los pasajeros y no permitir la
    intermediación de <b>NOW ASSISTANCE</b>.

    Las indemnizaciones por conceptos de pérdida total de equipaje se abonarán únicamente en el país donde se compró la
    asistencia <b>NOW ASSISTANCE</b>.
    Al regresar a su país de origen, el Beneficiario deberá presentar en las oficinas de <b>NOW ASSISTANCE</b> la siguiente
    documentación:
    ◦ Formulario P.I.R. (Reclamo por Pérdida de Equipaje) Original
    ◦ Documento o Pasaporte
    ◦ Voucher de asistencia
    ◦ Copia original del recibo de la indemnización de la línea aérea (Cheque, comprobante de pago de la misma), pasajes
    aéreos.

    <b>NOW ASSISTANCE</b> sólo podrá proceder al reintegro por concepto de indemnización por pérdida de equipaje únicamente
    después de que la Aerolínea responsable de la pérdida haya debidamente indemnizado al Beneficiario. No se podrá
    indemnizar al Beneficiario sin el comprobante de pago de la aerolínea.

    NOTA: La indemnización al Beneficiario será complementaria a la abonada por la línea aérea conforme a lo indicado en
    el voucher correspondiente al plan <b>NOW ASSISTANCE</b> adquirido. En caso de indemnización complementaria, el importe de
    la misma se determinará como la diferencia entre lo abonado por la línea aérea y el monto que se determine conforme
    a lo estipulado en el plan adquirido, y siempre hasta el límite máximo indicado por este concepto en el voucher. No
    será válida compensación alguna si la indemnización de la compañía aérea iguala o supera el límite máximo
    establecido en el voucher para este concepto. Por otra parte, la compensación por pérdida de equipaje se aplica por
    bulto o carga y no por persona.

    - Reembolso de gastos por vuelo demorado o cancelado
    Si el vuelo del Beneficiario fuera demorado por más de seis (6) horas consecutivas a la programada originalmente, y
    siempre y cuando no exista otra alternativa de transporte durante ellas, <b>NOW ASSISTANCE</b> reintegrará hasta el tope de
    cobertura convenido para este beneficio en razón de gastos de taxi, hotel, comidas y comunicaciones realizadas
    durante la demora y contra la presentación de sus comprobantes originales, acompañados de un certificado de la
    compañía aérea reflejando la demora o cancelación sufrida por el vuelo del Beneficiario.
    Es imprescindible que el Beneficiario se contacte con <b>NOW ASSISTANCE</b> lo más inmediatamente posible, y siempre dentro
    de las primeras 24 horas después de haber tenido conocimiento de la cancelación o demora.
    Se deja expresa constancia que el presente beneficio únicamente regirá para los siguientes casos:
    a) Retraso de la compañía aérea causados por las inclemencias del tiempo, lo que significa cualquier condición
    climática severa que retrasa la llegada o la salida de la compañía aérea.
    b) Retraso causado por fallas en los equipos de la aeronave, lo que significa que cualquier avería repentina,
    imprevista que retrasa la llegada o salida programada.
    c) Retraso debido a una huelga imprevista u otra acción laboral de los empleados de la compañía aérea, lo que
    significa cualquier desacuerdo laboral que retrasa la llegada o salida.
    Este beneficio no se brindará:
    a) Cuando la cancelación y/o retraso se produzca en virtud de un evento que se hizo público o conocido por el
    Beneficiario con anterioridad a la fecha en que fue reservado su viaje.
    b) Cuando la cancelación y/o retraso sea como consecuencia de leyes, reglamentos u órdenes emitidas o hechas por
    cualquier gobierno o autoridad pública;
    c) Cuando la cancelación y/o retraso se deba a huelgas o conflictos laborales que existían o que habían sido
    dictadas con anterioridad a la fecha en que fue reservado el viaje.
    d) Por la retirada del servicio, de manera temporal o permanente, de la compañía transportadora por orden o
    recomendación de cualquier Autoridad Aeroportuaria o la Agencia de Aviación o cualquier organismo similar en
    cualquier país.
    e) Por la búsqueda de bomba o amenaza de bomba.
    f) Aquellos gastos con motivo de cancelaciones o demoras cuando el pasaje del Beneficiario esté sujeto a
    disponibilidad de espacio.
    g) Cuando la cancelación y/o retraso se genere dentro del país de origen del Beneficiario o dentro del país donde el
    Beneficiario se encuentra al momento de la compra del producto <b>NOW ASSISTANCE</b>.
    h) Cuando la cancelación y/o retraso se genere por tareas de mantenimiento y/o service programados a la aeronave.
    i) No corresponderá el reembolso de gastos en concepto de transporte cuando se compruebe que la empresa de
    transporte realice al Beneficiario la devolución total del dinero correspondiente al transporte demorado o
    cancelado.

    - Cobertura para Mascotas
    En caso de que la mascota, acompañante del beneficiario del servicio de asistencia al viajero, sufra un accidente
    y/o enfermedad no preexistente y termine hospitalizada, <b>NOW ASSISTANCE</b> le cubrirá a la mascota los gastos de
    asistencia veterinaria necesaria, como las consultas, medicación, pruebas diagnósticas o intervenciones quirúrgicas,
    entre otros, siempre y cuando se trate de una emergencia comprobable donde la mascota termine hospitalizada. Además,
    en caso de fallecimiento de la mascota, <b>NOW ASSISTANCE</b> organizará y sufragará la repatriación funeraria, tomando a
    su cargo los gastos de: féretro obligatorio para transporte internacional, trámites administrativos y transporte del
    cuerpo por el medio que considere más conveniente hasta el lugar de ingreso al país de residencia habitual del
    propietario de la mascota, el mismo que figura en el plan de asistencia de <b>NOW ASSISTANCE</b>.

    La validez será la misma del voucher de asistencia en viajes de <b>NOW ASSISTANCE</b> con un máximo 90 días naturales. Éste
    beneficio se puede vender para Perros y Gatos, que no se encuentren descritos en las exclusiones particulares del
    presente servicio.

    Requisitos particulares a este beneficio:
    Sólo se admitirán perros y gatos.
    Que la mascota tenga cuando menos cumplidos cuatro meses de edad y no sean mayores de ocho años.
    El propietario de la mascota deberá presentar la cartilla de vacunación completa y vigente, en caso tal que la
    mascota sea menor a un año de edad, el propietario deberá acreditar haber recibido sus vacunas de refuerzo.
    La mascota al momento del viaje no deberá presentar ninguna enfermedad. La mascota debe estar debidamente
    desparasitada, interna y externamente. Sólo aplicará para una mascota por persona.

    Exclusiones particulares a este beneficio:
    No se cubrirá ningún tipo de controles, exámenes investigativos, consultas veterinarias en general, estudios de
    laboratorio e imágenes, etc.,
    Vacunas y/o desparasitación.
    Enfermedades resultantes de la falta de desparasitación o vacunas. Mascotas en gestación
    Mascotas que, al momento del viaje, se presenten enfermas Mascotas en tratamiento médico
    Mascotas menores de cuatro meses y mayores de ocho años de edad Mascotas que no cuenten con la cartilla de
    vacunación completa y vigente
    Mascotas que no cumplan con las normas y requerimientos legales para traslado internacional. Pueden aplicarse otras
    exclusiones mencionadas en el contrato principal.

    Air Help
    Los Beneficiarios de <b>NOW ASSISTANCE</b> cuentan con la opción de realizar sus solicitudes para el estudio de su caso
    ante la compañía Air Help por vuelos demorados o cancelados.
    Nota: Todas las solicitudes están sujetas a los términos y condiciones de la compañía AirHelp – en el siguiente
    link: https://www.airhelp.com/es/terminos/

    Sala VIP por demora de vuelo
    Los beneficiarios de <b>NOW ASSISTANCE</b> podrán acceder a las salas VIP dispuestas en los aeropuertos, en el caso que se
    presenten demoras de 60 minutos o más, en los vuelos programados para su viaje.
    Para acceder y obtener el beneficio debe cumplirse con los siguientes requisitos:
    - Registrar los vuelos programados para el viaje con un tiempo no menor a 7 horas antes del vuelo a través del
    siguiente link: https://travelregistration.online
    - Para acceder a los términos y condiciones de este beneficio lo podrá hacer a través del siguiente link:
    https://drive.google.com/file/d/1vH5akgbBRT9iZ5lcTpNq56VDBIDKgfTK/view
    Nota: El acceso al beneficio está sujeto a los términos y condiciones de la compañía Collinson Service Solutions
    Limited

    - BENEFICIOS ADICIONALES DE COMPRA OPCIONAL POR EL BENEFICIARIO: Up Grades
    Los Beneficiarios, hasta un día antes que inicie la vigencia del producto, tendrán la opción de adquirir
    adicionalmente, pero no por separado, beneficios adicionales a los establecidos para cada plan de asistencia en
    particular de NOW ASSISTANCE, mediante el pago de un complemento al precio del plan original, todo ello de acuerdo
    con lo establecido y precios de la oferta pública en la plataforma web de <b>NOW ASSISTANCE</b>.
    Los beneficios adicionales o up grades sólo podrán ser emitidos para la categoría viajes por días.

    1. Up Grade: Embarazo
    Toda persona embarazada que desee comprar un plan de asistencia de NOW ASSISTANCE, lo podrá hacer mediante el pago
    de un monto adicional. Éste beneficio aplica únicamente para embarazos hasta la semana 32 de gestación inclusive. El
    beneficio aplica básicamente para las emergencias que se presenten durante el viaje y nunca excediendo las 32
    semanas de gestación, incluyendo controles en situación de emergencia, ecografías de urgencia, asistencias médicas
    por enfermedades ocasionadas por su situación de embarazo, partos de emergencia por enfermedad o accidente que ponga
    en riesgo la vida de la madre o del hijo, aborto espontáneo, cualquier tipo y cualquier asistencia médica de
    emergencia e inesperada derivada de la situación de embarazo.

    Exclusiones particulares a este beneficio:
    Controles, ecografías, consultas médicas en general, estudios médicos, etc., que sean parte de los controles
    rutinarios del proceso de embarazo o de un embarazo de curso normal y no sean de emergencia. Asimismo, todas las
    complicaciones resultantes durante y después del parto.
    Partos y cesáreas de curso normal y en término.
    Abortos provocados y/o sus consecuencias Gastos médicos relacionados con el recién nacido.
    Cuando se compruebe que el motivo del viaje es para atender su parto en el exterior.
    Solicitudes de asistencias en embarazos que superen las 32 semanas de gestación, aun cuando se trate de una
    emergencia y/o un evento inesperado.

    NOTA 1: el límite de edad para acceder al beneficio de embarazo es mínimo de 19 años de edad hasta máximo 45 años de
    edad.
    NOTA 2: Se deja aclarado que el límite máximo de dinero, específico para este Up Grade, no es adicional al Monto por
    Asistencia Médica en caso de enfermedad/accidente, sino que está dentro de este último.

    2. Up Grade: Tecnología protegida
    <b>NOW ASSISTANCE</b> indemnizará al beneficiario de un plan de asistencia que así lo establezca, únicamente en caso de
    robo de los siguientes elementos: cámaras de fotografía, filmadoras, teléfonos inteligentes, tabletas y computadores
    hasta el tope de cobertura del plan contratado.
    El robo debe ocurrir durante un viaje, dentro de la vigencia del producto NOW ASSISTANCE, con el Up Grade emitido
    bajo las condiciones informadas anteriormente y siempre y cuando el Beneficiario haya atravesado el sector de
    Migraciones de su país de residencia y hasta que haya regresado al mismo sector en su país de residencia.
    La suma asegurada para aplicar a este Up Grade se calculará sobre el costo de reposición del bien robado y hasta el
    límite máximo indicado en su voucher.

    Para acceder a este beneficio, el beneficiario deberá:
    -Comunicar a NOW ASSISTANCE, de inmediato o, en el plazo máximo de 24 horas (veinticuatro horas), bajo pena de
    pérdida de este Up Grade, la existencia o posible existencia del siniestro, comunicando las circunstancias exactas
    en que se haya producido el robo.
    - El Beneficiario deberá realizar la denuncia policial al momento de ocurrido el robo y la misma deberá ser
    coincidente con lo informado a <b>NOW ASSISTANCE</b>.
    - La denuncia policial, el voucher con el Up Grade contratado, las copias del pasaporte deberán ser enviados a
    NOW ASSISTANCE, al igual que cualquier otro documento que se le solicite al Beneficiario.
    - Factura de compra o declaración en aduana del objeto robado con fecha anterior al robo.
    - Factura por reposición del objeto robado, por uno de la misma marca y referencia, con fecha posterior a la de la
    radicación de la denuncia policial.
    - Todos los pagos descriptos se abonarán en la moneda local del país de residencia del Beneficiario.

    Nota: <b>NOW ASSISTANCE</b> se reserva el derecho de pagar por el robo del bien amparado. El presente beneficio actuará
    única y exclusivamente en defecto o insuficiencia de cualquier otro servicio o seguro que exista o pueda existir
    sobre los mismos bienes amparados, contratado por el propio Beneficiario como tomador del mismo o cualquier otro
    tomador en beneficio del Beneficiario.

    Exclusiones:
    - Si el robo ocurre bajo la custodia de una línea aérea u otro medio de trasporte.
    - Si el robo se hubiese producido en un hotel, o en cualquier alojamiento del beneficiario.
    - Actos ilegales, fraude o abuso de o con respecto a los bienes asegurados.
    - El uso comercial de los bienes amparados, por ejemplo, para exposición o para la venta.
    - Cuando el delito haya sido instigado o cometido por o en complicidad con cualquier miembro de la familia del
    Beneficiario o personas allegadas.
    - Hurto, extravío, pérdida, olvido
    - Terremoto, meteorito, maremoto, erupción volcánica, tornado, vendaval, huracán o ciclón, granizo o inundación.
    - hechos de guerra civil o internacional, rebelión, sedición o motín, tumulto popular, conmoción civil, vandalismo,
    guerrilla o terrorismo, huelga o lock-out.
    - Secuestro, confiscación, incautación o decomiso u otras decisiones, legítimas o no de la autoridad o de quien se
    la arrogue.
    - Dolo o culpa grave del Beneficiario.

    Bienes no amparados por el Up Grade:
    a) Equipos electrónicos utilizados por razones relacionadas con la salud, incluyendo, pero no limitándose a
    audífonos y marcapasos.
    b) Bienes adquiridos con fines comerciales, incluyendo insumos o herramientas para comercio o profesión.
    c) Accesorios de Notebook/Netbook/Laptop/Tablet/Ibooks/Smartphone, tales como equipos manos libres, cargadores,
    baterías, tarjetas complementarias, fundas y todo otro accesorio secundario.
    d) Los despachados para su transporte en cualquier medio de transporte, aun cuando los mismos sean despachados en
    vuelos internacionales.

    3. Up grade: Cancelación Multi Causa
    En aquellos casos que el Beneficiario en forma explícita contrate el beneficio de protección de cancelación multi
    causa que ofrece NOW ASSISTANCE, se otorgará cobertura hasta el monto específicamente contratado y dicho beneficio
    deberá constar expresamente en el voucher del Beneficiario.
    Este beneficio es únicamente válido para viajes internacionales.
    <b>NOW ASSISTANCE</b> cubrirá hasta el tope de cobertura según el plan contratado las penalidades por cancelar
    anticipadamente un viaje conocido como tours, paquetes turísticos, excursiones, tiquetes aéreos y cruceros que hayan
    sido organizadas por un tour operador profesional debidamente acreditado en el destino de dicho viaje. Para ser
    acreedor de este beneficio el Beneficiario del voucher deberá:
    1) Contratar el plan de asistencia, antes o hasta un máximo de 72 horas posteriores al primer pago de los servicios
    turísticos que pudieran cancelarse.
    2) Dar aviso a la central de asistencia en un máximo de 24 horas luego de ocurrido el evento que motiva la
    cancelación. Se tomará como base de cálculo de la indemnización, la fecha de ocurrencia de la causa de Cancelación y
    no la fecha de aviso del Beneficiario a <b>NOW ASSISTANCE</b>. Al mismo tiempo, el Beneficiario deberá cancelar con el
    organismo de turismo Crucero, Agencia de viaje, Tour Operador, 16 etc. su viaje, para no incrementar la penalidad
    que ese mismo organismo le vaya a aplicar.
    3) Presentar, hasta 30 días calendario después del día de finalización de la vigencia del voucher, toda la
    documentación que <b>NOW ASSISTANCE</b> considere para evaluar la cobertura de este beneficio incluyendo, pero no limitando
    a: Documento donde se demuestre de forma clara y fehaciente el motivo de cancelación del viaje, cartas de los
    respectivos prestadores del servicio, facturas y recibos de pago.
    4) En caso de planes Multiviajes, este beneficio aplicará una sola vez y corresponde al viaje inicial del pasajero,
    no podrá ser tenido como aplicable para todos los viajes que pueda realizar el Beneficiario durante la vigencia
    total del voucher.
    5) El Beneficiario tiene hasta treinta (30) días continuos a partir de la fecha de sucedido el evento para presentar
    la documentación completa y respaldos necesarios para iniciar el proceso de reintegro. Después de ese plazo, no se
    aceptarán documentos para tramitar ningún reintegro.
    Son causas justificadas a los efectos del presente beneficio y contempladas al 100% del tope marcado en el voucher:
    1. El fallecimiento, accidente o enfermedad grave no preexistente del Beneficiario o familiar en primer grado de
    consanguinidad cónyuge, padres, hijos, hermanos, entendiéndose por enfermedad grave una alteración de la salud que,
    a juicio del Departamento Médico de la Central de Asistencia, imposibilite al Beneficiario para iniciar el viaje en
    la fecha contratada originalmente.
    2. La convocatoria como parte, testigo o jurado de un tribunal.
    3. Los daños que, por incendio, robo, hurto o por la fuerza de la naturaleza en su residencia habitual o en sus
    locales profesionales que los hagan inhabitables y justifican ineludiblemente su presencia.
    4. Cuarentena médica como consecuencia de un suceso accidental.
    5. Despido laboral comprobado, con fecha posterior a la contratación de asistencia.
    6. Convocatoria de emergencia para prestar servicio militar, médico o público.
    7. Por epidemia, desastre natural o cenizas volcánicas. En el caso de los productos Crucero no será contemplada como
    una de las causas justificadas para acceder a este beneficio, las cancelaciones ocasionadas por emanaciones de
    cenizas volcánicas.
    8. Si la persona que ha de acompañar al Beneficiario en el viaje, entendiéndose como tales las personas que
    compartan la misma habitación de hotel con el Beneficiario, o cabina de crucero, o sean familiares de primer grado
    de consanguinidad cónyuge, padres, hijos o hermanos también poseedor es de un Plan de Asistencia en las mismas
    condiciones que el Beneficiario y dichos acompañantes se viesen obligados a anular el viaje por alguna de las causas
    enumeradas anteriormente.
    9. Las agudizaciones imprevistas de enfermedades preexistentes. En cuyo caso el Beneficiario o familiar en primer
    grado de consanguinidad cónyuge, padres, hijos, hermanos con dicha enfermedad preexistente deberá haber permanecido
    estable, sin episodios, durante un periodo mínimo de 6 meses previos al viaje. <b>NOW ASSISTANCE</b> se reserva el derecho
    de solicitar la historia clínica original del pasajero previa a la fecha en que éste manifiesta la agudización de la
    enfermedad.
    10. Las complicaciones de embarazos
    11. Cancelación de boda
    12. Entrega de niño en adopción
    13. Parto de emergencia Son causas justificadas a los efectos del presente beneficio y contempladas al 70% del tope
    marcado en el voucher:
    14. Secuestro del Beneficiario o familiares directos siempre y cuando sea comprobable, y de conocimiento público
    15. Cancelación de vacaciones por disposición de la empresa
    16. Cambio de trabajo
    17. No aprobación de la Visa para ingresar al país de destino. Esta cobertura es válida si la compra del Suplemento
    se realiza por lo menos 72 horas antes de la cita para la obtención de la visa ante la 17 embajada respectiva. No
    aplica para los costos de los trámites consulares (costo de la visa). Adquirido el plan en las condiciones antes
    indicadas, y de ser aplicable el beneficio, la vigencia del mismo comienza en el momento en que el Beneficiario
    adquiere su plan de asistencia y finaliza en el momento de inicio de la vigencia del voucher. Nota: Este beneficio
    no aplica para los beneficiarios mayores de 74 años.


    <p class="font-bold mb-4 underline">X. EXCLUSIÓN APLICABLES A TODOS LOS SERVICIOS Y BENEFICIOS</p>

    Quedan expresamente excluidos del sistema de asistencia <b>NOW ASSISTANCE</b> los eventos siguientes:

    1. Enfermedades crónicas o preexistentes, definidas, o recurrentes, padecidas con anterioridad al inicio de la
    vigencia del plan y/o del viaje, sean estas del conocimiento o no por el Beneficiario, así como sus agudizaciones,
    secuelas y consecuencias directas o indirectas (incluso cuando las mismas aparezcan por primera vez durante el
    viaje). Ejemplos claros y comunes de preexistencias solo para citar algunos y de manera enunciativa y no taxativa
    son: los cólicos y cálculos renales y/o biliares, las obstrucciones de arterias o venas por coágulos u otros,
    enfermedades respiratorias como el asma, problemas pulmonares, ACV, enfisemas, el VIH, generalmente problemas
    relacionados con la tensión arterial, glaucoma, cataratas, nefritis, úlceras o enfermedades gástricas, enfermedades
    resultantes de deformaciones congénitas, micosis genitales, abscesos hepáticos, cirrosis, diabetes, colesterol alto,
    triglicéridos altos, y otras.

    2. Las siguientes condiciones no tendrán cobertura, bajo ningún beneficio: Infarto cerebral de cualquier tipo,
    infarto y/o estado lacunar, parálisis facial, ACV, cáncer, tumores, aterosclerosis, infarto al miocardio, trombosis
    coronaria, oclusión coronaria, angina de pecho y/o neumonía. Dolores inespecíficos cualquiera fuera su etiología.

    3. Enfermedades, lesiones, afecciones o complicaciones médicas resultantes de tratamientos efectuados o llevados a
    cabo por personas o profesionales no autorizados por el Departamento Médico de la Central de Servicios de Asistencia
    NOW ASSISTANCE, o exceptuando lo determinado en el punto precedente.

    4. Los tratamientos homeopáticos, tratamientos de acupuntura, las curas termales, la podología, la manicura, la
    pedicura, etc.

    5. Las afecciones, las enfermedades o lesiones derivadas de empresa, intento o acción criminal o penal del
    Beneficiario, directa o indirectamente como peleas, riñas, flagelaciones, etc.

    6. Tratamiento de enfermedades o estados patológicos producidos por la ingesta o la administración intencional de
    tóxicos (drogas), narcóticos, alcohol, o por la utilización de medicamentos sin la respectiva orden médica.

    7. Gastos incurridos en cualquier tipo de prótesis dentales, lentes, audífonos, sillas de rueda, muletas, anteojos,
    etc.

    8. Eventos ocurridos como consecuencia de simples entrenamientos, prácticas sencillas o participación activa o no
    activa en competencias deportivas (profesionales o amateurs). Además quedan expresamente excluidas las ocurrencias
    consecuentes a la práctica de deportes peligrosos o de alto riesgo o extremos incluyendo pero no limitando a:
    Motociclismo, Cuatriciclo, Automovilismo, Boxeo, Polo, Ski acuático, Buceo, Ala Deltismo, Kartismo, Cuatrimotos,
    Alpinismo, Ski , Football, Rugby, Boxeo, Canotaje, Parapente , Kayak, Bádminton, Básquetbol, Voleibol, Handball,
    karate do, Kung fu, Judo, Todo tipo de arte marcial, Tiro al arco, Tiro fusil, Tejo, Rappel, Torrentismo,
    Montañismo, Escalada, Puenting, Atletismo, Ciclismo, Luge Espeleología, Skeleton, la Caza de animales, Bobsleigh,
    etc., y otros deportes practicados fuera de pistas reglamentarias y autorizadas por las respectivas federaciones de
    deportes.
    9. Los partos, estados de embarazo, controles ginecológicos, exámenes relacionados con los mismos. Los abortos, o
    las pérdidas cualquiera sea su etiología u origen. Asimismo, todas las complicaciones resultantes durante y después
    del embarazo.

    10. Todo tipo de enfermedades mentales.

    11. Afecciones, enfermedades o lesiones derivadas del consumo de bebidas alcohólicas de cualquier tipo.

    12. El Síndrome de inmune deficiencia adquirida (SIDA) y virus de inmunodeficiencia humana (VIH) en todas sus
    formas, secuelas y consecuencias. Las enfermedades y/o infecciones de transmisión sexual en general todo tipo de
    prestación, examen y/o tratamiento que no haya recibido la autorización previa de la Central de Servicios de
    Asistencia.

    13. Los Eventos y las consecuencias de desencadenamiento de fuerzas naturales, tsunamis, temblores, terremotos,
    tormentas, tempestades, huracanes, ciclones, inundaciones, eventos de radiación nuclear y de radioactividad, así
    como cualquier otro fenómeno natural o no, con carácter extraordinario o evento que, debido a sus proporciones o
    gravedad, sea considerado como desastre nacional regional o local o catástrofe, sismos, huracanes, inundaciones etc.

    14. El suicidio, o el intento de suicidio o las lesiones infringidas a sí mismo por parte del Beneficiario, así como
    cualquier acto de manifiesta irresponsabilidad o imprudencia grave por parte del Beneficiario de la asistencia en
    viajes.

    15. Los Eventos como consecuencia de actos de guerra, invasión, actos cometidos por enemigos extranjeros o
    nacionales, terrorismo, hostilidades u operaciones de guerra (sea que haya sido declarada o no la guerra) guerra
    civil, rebelión, insurrección o poder militar, naval o usurpado, la intervención del Beneficiario en motines,
    manifestaciones o tumultos que tengan o no carácter de guerra civil, o sea que la intervención sea personal o como
    miembro de una organización civil o militar; terrorismo u otra alteración grave del orden público.

    16. Los actos mal intencionados y/o de mala fe de parte del Beneficiario o de sus apoderados. Las mentiras, el
    ocultamiento de información, incluyendo el suministro de información falsa, incompleta o diferente de la realidad,
    así como también documentación fraudulenta o empleando pruebas falsas.

    17. Los exámenes médicos de rutina, los exámenes de laboratorio para chequeos médicos, los exámenes de diagnóstico y
    o de controles, exámenes de laboratorio o radiológicos o de otros medios, cuya finalidad es la de establecer si la
    enfermedad es una preexistencia, tales como los exámenes de radiología, el doppler, las resonancias magnéticas,
    tomografías, ultrasonidos, imágenes, scanner de toda índole, etc. Los exámenes médicos practicados para establecer
    si la dolencia corresponde a una enfermedad preexistente o no.

    18. Gastos correspondientes a transportes públicos o privados o desplazamientos pagados por el Beneficiario desde su
    hotel o lugar donde esté hasta el centro hospitalario, o centro médico, o consultorio del médico. A menos que dichos
    gastos hayan sido expresamente autorizados en forma escrita o verbal por la Central de Servicios de Asistencia.

    19. Las enfermedades derivadas o debidas o consecuentes de las deformaciones congénitas conocidas o no por el
    Beneficiario.

    20. Lesiones o accidentes derivados de accidentes aéreos en aviones no destinados ni autorizados como transporte
    público, incluyendo los vuelos fletados particulares.

    21. Afecciones, enfermedades o lesiones derivadas directa o indirectamente de riña o peleas (salvo que se tratase de
    un caso de legítima defensa comprobada con reporte policial), huelga, actos de vandalismo o tumulto popular en que
    el Beneficiario hubiese participado como elemento activo. El intento de o la comisión de un acto ilegal y, en
    general, cualquier acto doloso o criminal del Beneficiario, incluido el suministro de información falsa o diferente
    de la realidad.

    22. Enfermedades endémicas, pandémicas, o epidémicas, Las asistencias por estas enfermedades en países con o sin
    emergencia sanitaria en caso de que el Beneficiario no haya seguido las sugerencias y/o indicaciones sobre
    restricciones de viaje y/o tratamiento profiláctico y/o vacunación emanadas de autoridades sanitarias.

    23. Cualquier gasto o asistencia médica que no haya sido previamente consultado y autorizado por la Central de
    Servicios de Asistencia <b>NOW ASSISTANCE</b>.
    24. Enfermedades Hepáticas, como Cirrosis, Abscesos y otros.

    25. Exámenes y/u hospitalizaciones para pruebas de esfuerzo y todo tipo de chequeos preventivos.

    26. Cualquier tipo de hernias y sus consecuencias.

    27. Secuestro o su intento.

    28. Riesgos profesionales: si durante el viaje del Beneficiario fuese la ejecución de trabajos o tareas que
    involucren un riesgo profesional, así como las lesiones clasificadas como lesiones por esfuerzos repetitivos,
    enfermedades osteomusculares relacionadas al trabajo, lesión por trauma continuado o continuo, etc., o similares,
    tanto como sus consecuencias post tratamiento inclusive quirúrgicos en cualquier tiempo.

    29. Lesiones de conductor o pasajero por el uso de cualquier tipo de vehículos, incluidos bicicletas, motocicletas y
    velomotores sin licencia de conducir, o sin casco, o sin seguros contratados.

    30. Están excluidos los accidentes y enfermedades presentadas en países en guerra civil o extranjera. Ejemplo:
    Afganistán, Irak, Sudan, Somalia, Corea del Norte, etc.

    31. No se brindará asistencia de ningún tipo al Beneficiario en situación migratoria o laboral ilegal (incluyendo
    trabajo no declarado en el país desde donde se requiere la asistencia, o a estudiantes sorprendidos trabajando en
    país extranjero sin la respectiva autorización de las autoridades locales).

    32. <b>NOW ASSISTANCE</b> no tomará a su cargo costos por fisioterapias referidas para el tratamiento de dolencias
    relacionadas a accidentes laborales, tareas repetitivas o enfermedades crónicas y/o degenerativas de los huesos o
    músculos.

    33. En caso de constatarse que el motivo del viaje fuera el tratamiento en el extranjero de una enfermedad de base,
    y que el tratamiento actual tenga alguna vinculación directa o indirecta con la dolencia previa motivo del viaje,
    <b>NOW ASSISTANCE</b> quedará relevada de prestar sus servicios. A tal fin <b>NOW ASSISTANCE</b> se reserva el derecho de
    investigar la conexión del hecho actual con la dolencia previa.

    Servicios no acumulativos y/o Intervención de otras empresas
    En ningún caso <b>NOW ASSISTANCE</b> prestará los servicios de asistencia al Beneficiario establecidos en el PLAN DE
    ASISTENCIA MÉDICA del VOUCHER DE ASISTENCIA, ni efectuará reintegro de gastos de ningún tipo, en tanto y en cuanto
    el Beneficiario solicite o haya solicitado prestaciones por el mismo problema y/o afección a cualquier otra empresa,
    antes, durante o después de haberlas solicitado al proveedor.


    <p class="font-bold mb-4 underline">XI. SUBROGACIÓN Y CESIÓN DE DERECHOS</p>
    Hasta la concurrencia de las sumas desembolsadas en cumplimiento de las obligaciones emanadas de las presentes
    Condiciones Generales de los Servicios de <b>NOW ASSISTANCE</b> y/o las compañías de seguro que asumen el riesgo como fruto
    del encargo de <b>NOW ASSISTANCE</b> quedarán automáticamente subrogada en los derechos y acciones que puedan corresponder
    al beneficiario o a sus herederos contra terceras personas físicas o jurídicas y/u organismo(s) público(s) u
    oficial(es) en virtud del evento que ocasionó la asistencia prestada.
    El beneficiario se compromete a reembolsar en el acto a <b>NOW ASSISTANCE</b> todo importe que haya recibido de parte del
    sujeto causante y/o responsable del accidente y/o de su(s) compañía(s) de seguro(s) en concepto de adelanto(s) a
    cuenta de la liquidación de la indemnización final a la cual el beneficiario pudiere tener derecho. Ello a
    concurrencia de los importes a cargo de <b>NOW ASSISTANCE</b> en el caso ocurrido.
    Además, <b>NOW ASSISTANCE</b> estará subrogada, entendiéndose que cualquier seguro, asistencia en viaje y/o seguro médico
    tendrá la obligación en primera instancia del pago ya sea de la totalidad o parte de los gastos que puedan
    desencadenarse a causa del evento sufrido por el Beneficiario.
    Sin que la siguiente enunciación deba entenderse limitativa, quedan expresamente comprendidos en la subrogación los
    derechos y acciones susceptibles de ser ejercitados frente a las siguientes personas:
    1) Terceros responsables de un accidente (de tránsito o de cualquier otro tipo) y/o sus compañías de seguro.
    2) Empresas de transporte, en lo atinente a la restitución -total o parcial- del precio de pasajes no utilizados,
    cuando <b>NOW ASSISTANCE</b> haya tomado a su cargo el traslado del beneficiario o de sus restos.
    3) Terceros responsables del pago de la totalidad o parte de gastos que puedan desencadenarse a causa del evento
    sufrido por el Beneficiario, entendiéndose como: seguro médico nacional del Beneficiario, compañía de asistencia en
    viajes, cobertura de asistencia en viajes incluida en la(s) tarjeta(s) de crédito del Beneficiario.
    4) Los accidentes en cualquier tipo de transporte que sea obligatorio el cubrimiento de Seguro de
    Responsabilidad a Terceros o su equivalente, deben cubrir en primera instancia este seguro. En caso de transporte
    terrestre de pasajeros en exceso del Seguro de Responsabilidad a Terceros seguirá la cobertura de la póliza de
    responsabilidad civil contractual del vehículo. En exceso de cualquier seguro adicional del transporte terrestre,
    fluvial o marítimo, <b>NOW ASSISTANCE</b> asumirá hasta el monto indicado

    IMPORTANTE: El beneficiario cede irrevocablemente a favor de <b>NOW ASSISTANCE</b> los derechos y acciones comprendidos en
    la presente Cláusula, obligándose a llevar a cabo la totalidad de los actos jurídicos que a tal efecto resulten
    necesarios y a prestar toda la colaboración que le sea requerida con motivo de la subrogación acordada. En tal
    sentido, se compromete y obliga a formalizar la subrogación o cesión a favor de <b>NOW ASSISTANCE</b> dentro de los tres
    (3) días calendario siguientes de intimado el beneficiario al efecto. De negarse a suscribir y/o prestar
    colaboración para ceder tales derechos a NOW ASSISTANCE, esta última quedara automáticamente eximida de pagar los
    gastos de asistencia originados.
    Además, <b>NOW ASSISTANCE</b> estará subrogada, entendiéndose que cualquier seguro, asistencia en viaje y/o seguro médico
    tendrá la obligación en primera instancia del pago ya sea de la totalidad o parte de los gastos que puedan
    desencadenarse a causa del evento sufrido por el Beneficiario.

    <b>NOW ASSISTANCE</b> quedará subrogado en los derechos y acciones que correspondan al Beneficiario, por hechos que hayan
    motivado la intervención de aquel y hasta el total del costo de los servicios prestados. De igual forma NOW
    ASSISTANCE se reserva el derecho de poder ceder en todo o en parte tanto los derechos que le asistieran derivados de
    la relación contractual con el Beneficiario, así como la ejecución, prestación de servicios y demás obligaciones a
    su cargo a terceras personas jurídicas profesionales en el ramo de la asistencia a empresas del ramo.
    En tal sentido el Beneficiario está consciente de dicho derecho y por tanto renuncia expresamente a ser notificado o
    comunicado previamente de dichas cesiones.


    <p class="font-bold mb-4 underline">XII. CIRCUNSTANCIAS EXCEPCIONALES DE INEJECUCIÓN INIMPUTABLE</p>
    Ni NOW ASSISTANCE, ni su red de prestadores de servicios, serán responsables, exigibles o ejecutables por casos
    fortuitos, que originen retrasos o incumplimientos inimputables debidos a catástrofes naturales, huelgas, guerras,
    invasiones, actos de sabotaje, hostilidades, rebelión, insurrección,
    terrorismo o pronunciamientos, manifestaciones populares, radioactividad, o cualquier otra causa de fuerza mayor.
    Cuando elementos de esta índole interviniesen, <b>NOW ASSISTANCE</b> se compromete a ejecutar sus compromisos dentro del
    menor plazo que fuera posible y siempre y cuando una vez sea factible dicha prestación de servicios, se mantenga la
    contingencia que la justifique.


    <p class="font-bold mb-4 underline">XIII. RECURSO</p>
    <b>NOW ASSISTANCE</b> se reserva el derecho de exigir al Beneficiario el reintegro de cualquier gasto efectuado por éste en
    forma indebida, en caso de habérsele prestado servicios no contemplados por este contrato o fuera del período de
    vigencia del plan de asistencia contratado, así como cualquier pago realizado por cuenta del Beneficiario.


    <p class="font-bold mb-4 underline">XIV. RESPONSABILIDAD</p>
    El servicio brindado por <b>NOW ASSISTANCE</b> de conformidad a los términos de las presentes condiciones generales y al
    contrato de asistencia en viaje, se circunscribe única y exclusivamente a facilitar al Beneficiario el acceso a
    profesionales para la prestación por parte de éstos últimos, bajo su única y exclusiva responsabilidad, servicios
    médicos, odontológicos, farmacéuticos, y/o de asistencia en general. De esta forma, <b>NOW ASSISTANCE</b> no será
    responsable en forma alguna, ya sea directa o indirectamente, de cualquier reclamo que pudiera realizar el
    Beneficiario por la prestación de los servicios llevada a cabo por cualquiera de los profesionales antes
    mencionados.
    NOW ASSISTANCE, no será responsable y no indemnizará al Beneficiario por ningún tipo de daño, perjuicio, lesión o
    enfermedad causada por haberle brindado al Beneficiario a su solicitud, personas o profesionales para que lo asistan
    médicas, odontológicas y/o farmacéutica. En estos casos, la persona o personas designadas por <b>NOW ASSISTANCE</b> serán
    tenidas como agentes del Beneficiario, sin posible recurso de naturaleza o circunstancia alguna contra NOW
    ASSISTANCE, en razón de tal designación. <b>NOW ASSISTANCE</b> se esfuerza para poner a disposición de los beneficiarios
    los mejores profesionales de la salud y los mejores medios, sin embargo, NOW ASSISTANCE,no podrá nunca ser tenida ni
    total ni parcialmente como responsable por la disponibilidad, la calidad, los resultados, falta de atención,
    servicios médicos y/o mala praxis de dichos profesionales o entidades, pues son condiciones que se encuentran
    totalmente fuera del control de <b>NOW ASSISTANCE</b>.

    <p class="font-bold mb-4 underline">XV. CADUCIDAD - RESOLUCIÓN - MODIFICACIÓN</p>
    Toda reclamación tendiente a hacer efectivas las obligaciones que NOW ASSISTANCE, asume a través de las presentes
    condiciones generales, deberá formularse en debida forma y por escrito dentro del plazo máximo improrrogable de
    treinta (30) días continuos calendario, contados a partir de la fecha de finalización de la vigencia del voucher.
    Transcurrido el plazo indicado, se producirá la automática caducidad de todos los derechos no ejercidos
    oportunamente. Los reclamos podrán ser canalizados a través de la casilla de mail: operaciones&#64;nowassistance.com
    y/o en la dirección XXXXXX 757 3º. CP:1425. Ciudad Autónoma de Buenos Aires.

    <p class="font-bold mb-4 underline">XVI. JURISDICCIÓN</p>
    Queda expresamente convenido entre las partes, en lo relativo a la relación contractual entre el Beneficiario del
    voucher y <b>NOW ASSISTANCE</b> que cualquier problema de interpretación sobre los alcances de la misma y/o reclamación
    judicial, que no puedan ser resueltos en forma amistosa, deberán ser sometidos a la jurisdicción de los tribunales
    comerciales ordinarios de la capital del país donde se encuentre presente jurídicamente la empresa titular de la
    marca NOW ASSISTANCE, con exclusión de todo otro fuero o jurisdicción.

    NOTA: Las presentes Condiciones Generales y Particulares están vigentes desde XXXXXX. Si Usted contrató el servicio
    con anterioridad rigen las Condiciones Generales y Particulares correspondientes a la fecha de contratación.
-->

</div>
<app-footer></app-footer>