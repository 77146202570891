export class Product{
    constructor(
        public id: number,
        public name: string,
        public code: string,
        public attributes: Attributes[],
        public price: number,
        public outstanding: boolean,
        public type : number,
        public currency: string
    ){}

    static createProduct(): Product {
        return new Product(0, '', '', [], 0, false, 0, '');
    }
}

export class Attributes{
    constructor(
        public name: string,
        public value: string,
        public description: string
    ){}
}