import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { RouteService } from "../route.service";
import { inject } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { catchError, firstValueFrom, map, of } from "rxjs";

export const OrderPayedGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,  
    state: RouterStateSnapshot
) => {
    const routeService = inject(RouteService);
    const apiService = inject(ApiService);
    const localStorageService = inject(LocalStorageService);

    const purchaseId = localStorageService.getItemByKey('purchaseId');

    try {
        const response = await firstValueFrom(
            apiService.get(`IsOrderPayed?purchaseId=${purchaseId}`).pipe(
                map(response => response),
                catchError((error) => {
                    return of(false);
                })
            )
        );

        if(response){
            routeService.refreshPage();
        }

        return !response;
    } catch (error) {
        routeService.refreshPage();
        return false;
    }
}