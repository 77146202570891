import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../../../shared/components/header/header.component';
import { FooterComponent } from '../../../../shared/components/footer/footer.component';
import { Faq, faqs, FaqsSection } from '../../models/faqs';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [CommonModule, HeaderComponent, FooterComponent],
  templateUrl: './FaqsComponent.component.html',
  styleUrl: './FaqsComponent.component.scss'
})
export class FaqsComponent {

  faqs:Array<FaqsSection> = faqs;

  currentSection: FaqsSection | null = null;

  toggleSection(section: FaqsSection) {
    if(section != this.currentSection){
      this.closeSection(this.currentSection);
    }
    this.currentSection = section;
    section.toggle = !section.toggle
  }

  toggleQuestion(faq: Faq) {
    faq.toggle = !faq.toggle;
  }

  closeSection(section: FaqsSection | null) {
    if (section){
      section.toggle = false;
      this.currentSection = null;
    } 
  }
}