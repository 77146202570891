import { Routes } from "@angular/router";
import { authGuard } from "./core/route/guards/auth.guard";
import { RouteResolver } from "./core/route/resolvers/route.resolver";
import { RedirectComponent } from "./core/components/redirect/redirect.component";
import { ServerStatusResolver } from "./core/route/resolvers/server-status.resolver";
import { LanguageResolver } from "./core/route/resolvers/language.resolver";
import { CountryResolver } from "./core/route/resolvers/country.resolver";
import { CleanHomeComponent } from "./feature/quote/pages/clean-home/clean-home.component";
import { esRoutes } from "./core/route/routes/es-routes";
import { enRoutes } from "./core/route/routes/en-routes";
import { ptRoutes } from "./core/route/routes/pt-routes";
import { staticsEnRoutesNoLang, staticsEsRoutesNoLang, staticsPtRoutesNoLang, staticsEnRoutesWithLang, staticsEsRoutesWithLang, staticsPtRoutesWithLang } from "./core/route/routes/statics.routes";
import { MetaResolver } from "./core/meta/resolver/meta.resolver";

export const routes: Routes = [
  { path: '', component: RedirectComponent },
  
  { path: ':country', canActivate: [authGuard], component: CleanHomeComponent, resolve: { serverStatus: ServerStatusResolver, CountryResolver, MetaResolver } },
  
  { path: ':country/cotizador', canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver }, children: esRoutes },

  { path: ':country/cotador', canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver }, children: ptRoutes }, 

  { path: ':country/quote', canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver }, children: enRoutes },
     
  //STATICS WITHOUT LANG 
  ...staticsEsRoutesNoLang,

  ...staticsEnRoutesNoLang,

  ...staticsPtRoutesNoLang,
   

  { path: ':country/:lang', canActivate: [authGuard], component: CleanHomeComponent, resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver, RouteResolver, MetaResolver } },

  { path: ':country/:lang/cotizador', canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver }, children: esRoutes },

  { path: ':country/:lang/cotador', canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver }, children: ptRoutes },

  { path: ':country/:lang/quote', canActivate: [authGuard], resolve: { serverStatus: ServerStatusResolver, CountryResolver, LanguageResolver }, children: enRoutes },


  //STATICS WITH LANG
  ...staticsEsRoutesWithLang,

  ...staticsEnRoutesWithLang,

  ...staticsPtRoutesWithLang,

  {
    //TODO: 404 Page Component
    path: '**',
    component: RedirectComponent
  }
];