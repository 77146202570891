  <div class="rounded-xl p-2 md:p-8 shadow border border-grey3">
    <form id="form-checkout" #formCheckout="ngForm">
      <div id="form-checkout__cardNumber" class="mp-container"></div>
      <div class="mp-container-group half-container">
        <div id="form-checkout__expirationDate" class="mp-container"></div>
        <div id="form-checkout__securityCode" class="mp-container"></div>
      </div>
      <input type="text" id="form-checkout__cardholderName" class="mp-input" />
      <select id="form-checkout__issuer" class="mp-input"></select>
      <select id="form-checkout__installments" class="mp-input"></select>
      <select id="form-checkout__identificationType" class="mp-input"></select>
      <input type="text" id="form-checkout__identificationNumber" class="mp-input"/>
      <input type="email" id="form-checkout__cardholderEmail" class="mp-input"/>    
    </form>
  </div>

  <div *ngIf="errorOnPayment" class="my-4">
    <p class="bg-red/20 text-red px-8 py-2 text-center">{{displayedErrorMessage}}</p>
  </div>

  <div class="mt-8 lg:flex lg:justify-between">

      <div class="lg:ml-8 text-sm font-medium">
          <label><input type="checkbox" class="mr-2" (change)="checkTerms($event)" />Acepto
              <a (click)="openTermsPage()" class="underline">Términos y Condiciones</a> y <a (click)="openPolicyPage()" class="underline">Políticas de Privacidad</a></label>
          <p>
              <img src="../../assets/img/icons/mp.png" alt="" class="w-[15px] mr-1 inline"> El proceso de pago está siendo respaldado por Mercado Pago.
          </p>
      </div>

      <div class="text-center lg:text-right">
          <button 
              [disabled]="!termsAccepted || paymentLoading"
              class="rounded-lg bg-blue mt-4 lg:mt-0 px-8 py-2 lg:py-4 font-bold text-white disabled:bg-[#D5D5D5] hover:bg-[#1D1191] transition-colors hover:easy-in duration-300"
              (click)="submitForm()">  
              <div class="flex items-center">
                  <mat-icon *ngIf="paymentLoading">
                  <mat-spinner color="primary" diameter="20">
                  </mat-spinner>
                </mat-icon>     
                <p>PAGAR</p>
              </div>
          </button>
      </div>

  </div>