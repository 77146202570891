<app-header
    [showQuoteButton]="false" 
    [showLogin]="false" 
    [showLangs]="false" 
>
</app-header>
<div class="px-4 py-8 lg:px-24 xl:px-32 xl:pb-24 xl:min-h-[65vh]">
    <div class="flex gap-8 lg:gap-16">
        <div class="flex-1">
            <h1 class="text-blue text-4xl mb-4">¡Hey you! <b>¿te ayudamos?</b></h1>
            <p class="text-grey text-2xl mb-8 lg:mb-16">Nuestro equipo está al pie del cañón para vos.</p>
            <p class="text-blue mb-4"><b>Atención al cliente: </b>
                <a href="mailto:clientes@nowassistance.com" class="hover:underline text-grey">clientes&#64;nowassistance.com</a>
            </p>
            <p class="text-blue mb-4"><b>Ventas: </b>
                <a href="mailto:ventas@nowassistance.com" class="hover:underline text-grey">ventas&#64;nowassistance.com</a>
            </p>
            <!-- <p class="text-blue mb-4 flex gap-2 items-center"><b>Chateanos a: </b>
                <img src="../../assets/img/contacto/whatsapp.png">
                <span class="text-grey">XX-XXXX-XXXX</span>
            </p> -->
            <!-- <p class="text-blue font-bold text-sm mb-2">Lunes a viernes 9hs a 18hs</p> -->
            <!-- <div class="flex flex-col lg:flex-row gap-2">
                <div class="flex items-center gap-2">
                    <img src="../../assets/img/contacto/whatsapp.png">
                    <span>XX-XXXX-XXXX</span>
                </div>
                <div class="flex items-center gap-2">
                    <img src="../../assets/img/contacto/mail.png">
                    <span>ventas&#64;nowassistance.com</span>
                </div>
                <div class="flex items-center gap-2">
                    <img src="../../assets/img/contacto/preguntas.png">
                    <a href="/preguntas-frecuentes">Preguntas Frecuentes</a>
                </div>
            </div> -->
        </div>
        <div class="self-end hidden lg:block">
            <img src="../../assets/img/contacto/contacto.png" alt="" class="lg:w-[350px]">
        </div>
    </div>
</div>
<app-footer></app-footer>