import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[letterOnly]',
})
export class LetterOnlyDirective {
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home' ];

  constructor(private el: ElementRef) {  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const regex = /^[a-z A-Z]*$/;

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (!regex.test(key)) {
      event.preventDefault();    
    }
  }
}