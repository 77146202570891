import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { RouteService } from '../../../core/route/route.service';
import { FaqsRoute, AboutUsRoute, ContactRoute, PrivacyRoute, TermsRoute } from '../../../core/route/routes/route-parser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [CommonModule, FontAwesomeModule]
})
export class FooterComponent implements OnInit {
  @Input() showButtons : boolean = true;
  @Input() showAirplane:boolean = true;

  private routeService = inject(RouteService);

  constructor() { }

  ngOnInit() {
  }


  faIg = faInstagram;
  faLk = faLinkedin;
  faFb = faFacebook;

  prd = true;

  goUp() {
    window.scrollTo(0,0);
  }

  redirectToStatic(route: string){

    this.routeService.setSubdirectory(null);

    switch(route){
      case 'faq':
          return this.routeService.resolveRedirection(new FaqsRoute());
      case 'about-us':
          return this.routeService.resolveRedirection(new AboutUsRoute());
      case 'contact':
          return this.routeService.resolveRedirection(new ContactRoute());
      case 'privacy':
          return this.routeService.resolveRedirection(new PrivacyRoute());
      case 'terms':
          return this.routeService.resolveRedirection(new TermsRoute());
    }
  }

}
