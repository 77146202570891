<div class="p-8 md:p-12 relative">
    <p class="absolute right-5 top-2 cursor-pointer text-lg" (click)="closeModal(false)">x</p>
    <form (ngSubmit)="onSubmit()">
        <div class="grid gap-3 lg:gap-4 grid-cols-2">
            <p class="col-span-2 font-medium text-2xl text-center text-blue mb-4">Vendé now</p>
            <input type="text"
                [(ngModel)]="vendeNow.companyName" 
                name="companyName" 
                (ngModelChange)="validateQuoteRequest()"
                [ngClass]="{'border-2 border-red text-red focus-visible:outline-red': !validCompanyName && vendeNow.companyName}"
                class="col-span-2 lg:col-span-1 rounded-lg border border-grey3 p-3 text-base" 
                placeholder="Razón Social *" 
            />

            <input type="text" 
                [(ngModel)]="vendeNow.cuit" 
                name="cuit" 
                (ngModelChange)="validateQuoteRequest()"
                [ngClass]="{'border-2 border-red text-red focus-visible:outline-red': !validCuit && vendeNow.cuit}"
                class="col-span-2 lg:col-span-1 rounded-lg border border-grey3 p-3 text-base" 
                placeholder="CUIT *" 
                numberOnly
            />

            <input type="text"
                [(ngModel)]="vendeNow.fullName"
                name="fullName" 
                (ngModelChange)="validateQuoteRequest()"
                [ngClass]="{'border-2 border-red text-red focus-visible:outline-red': !validFullName && vendeNow.fullName}"
                class="col-span-2 lg:col-span-1 rounded-lg border border-grey3 p-3 text-base" 
                placeholder="Nombre y Apellido *" 
                letterOnly
            />

            <input type="text" 
                [(ngModel)]="vendeNow.phone" 
                name="phone" 
                (ngModelChange)="validateQuoteRequest()"
                [ngClass]="{'border-2 border-red text-red focus-visible:outline-red': !validPhone && vendeNow.phone}"
                class="col-span-2 lg:col-span-1 rounded-lg border border-grey3 p-3 text-base" 
                placeholder="Teléfono *" 
                numberOnly
            />

            <input 
                type="email" 
                [(ngModel)]="vendeNow.email" 
                name="email"
                (ngModelChange)="validateQuoteRequest()"
                [ngClass]="{'border-2 border-red text-red focus-visible:outline-red': !validEmail && vendeNow.email}"
                class="col-span-2 rounded-lg border border-grey3 p-3 text-base" placeholder="Email *" 
            />

            <textarea [(ngModel)]="vendeNow.comment" name="comment"
                class="col-span-2 rounded-lg border border-grey3 p-3 text-base" placeholder="Comentario"></textarea>
 
            <p class="col-span-2 text-xs text-grey mb-4">* Campos obligatorios</p>
            <div class="col-span-2 text-center">
                <button 
                    type="submit"
                    [disabled]="buttonDisabled"
                    class="rounded-lg bg-blue mt-4 lg:mt-0 px-8 py-2 lg:py-4 font-bold text-white disabled:bg-[#D5D5D5]">
                    ENVIAR
                </button>
            </div>
        </div>
    </form>
</div>

<!-- <div class="p-8 md:p-12 relative">
    <p class="absolute right-5 top-2 cursor-pointer text-lg" (click)="closeModal(false)">x</p>
    <div class="grid grid-cols-1 gap-3 lg:gap-4 lg:grid-cols-2">
        <p class="lg:col-span-2 font-medium text-2xl text-center text-blue mb-4">Vendé now</p>
        <input type="text" class="col-span-1 rounded-lg border border-grey3 p-3 text-base" placeholder="Razón Social *" />
        <input type="text" class="col-span-1 rounded-lg border border-grey3 p-3 text-base" placeholder="Nombre y Apellido *" />
        <input type="text" class="col-span-1 rounded-lg border border-grey3 p-3 text-base" placeholder="Teléfono *" />
        <input type="text" class="col-span-1 rounded-lg border border-grey3 p-3 text-base" placeholder="Email *" />
        <textarea class="lg:col-span-2 rounded-lg border border-grey3 p-3 text-base" placeholder="Comentario"></textarea>
        <p class="lg:col-span-2 text-xs text-grey mb-4">* Campos obligatorios</p>
        <div class="lg:col-span-2 text-center">
            <button class="rounded-lg bg-blue mt-4 lg:mt-0 px-8 py-2 lg:py-4 font-bold text-white disabled:bg-disabledGrey"
                (click)="closeModal(true)">
                ENVIAR
            </button>
        </div>
    </div>
</div> -->
