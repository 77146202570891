<div [@collapse]="isMobileMenuOpen"
  class="fixed right-0 top-0 bottom-0 z-50 h-full w-full bg-grey2 px-4 pt-4 text-grey md:w-1/2 lg:w-1/4 lg:pl-8">
  <div class="mb-4 flex items-center justify-between">
    <img src="../../../assets/img/logo.svg" class="w-[150px]" />
    <button (click)="toggleMobileMenu()" class="text-3xl text-grey">
      x
    </button>
  </div>
  <div *ngIf="isMobileMenuOpen" class="mt-8 flex flex-col">
    <button class="pt-2 pb-2 text-lg text-left font-medium hover:bg-[#D5DBFF]" (click)="redirectToStatic('about-us')"> Quienes somos </button>
    <button class="pt-2 pb-2 text-lg text-left font-medium hover:bg-[#D5DBFF]" (click)="redirectToStatic('faq')"> Preguntas Frecuentes </button>
    <button class="pt-2 pb-2 text-lg text-left font-medium hover:bg-[#D5DBFF]" (click)="redirectToStatic('contact')"> Contacto </button>
    <button class="pt-2 pb-2 text-lg text-left font-medium hover:bg-[#D5DBFF]" (click)="redirectToStatic('privacy')">Políticas de Privacidad</button>
    <button class="pt-2 pb-2 text-lg text-left font-medium hover:bg-[#D5DBFF]" (click)="redirectToStatic('terms')">Condiciones Generales</button>
  </div>
</div>
<header class="mx-4 mt-4 h-[60px] lg:h-[80px] md:mx-16 lg:mx-24 2xl:mx-40 2xl:max-w-screen-2xl">
  <nav class="flex items-center justify-between">
    <a href="/">
      <img src="../../../assets/img/logo.svg" class="w-[150px] md:w-[160px]" />
    </a>
    <div class="block lg:hidden" *ngIf="prd">
      <button (click)="toggleMobileMenu()"
        class="inline rounded-lg border-white bg-skyblue px-2 py-1 text-white hover:border-blue hover:text-grey">
        <fa-icon [icon]="faBars" class="text-[1.2rem]"></fa-icon>
      </button>
    </div>

    <div class="hidden lg:flex items-center gap-2">
      <a *ngIf="showQuoteButton"
        class="rounded-lg border border-blue bg-blue px-4 py-2 text-center text-xs text-white hover:bg-[#1D1191] transition-colors hover:easy-in duration-300">COTIZAR
        ASISTENCIA</a>
      <a *ngIf="showLogin" href="#"
        class="rounded-lg border border-skyblue bg-skyblue text-white px-4 py-2 text-center text-xs hover:bg-blue transition-colors hover:easy-in duration-300">LOGIN</a>
      <a *ngIf="showLangs" class="rounded-lg cursor-pointer border border-grey bg-white px-2 py-2 text-center text-xs text-blue"
        [ngxTippy]="flagTooltip"
        [tippyProps]="{
            placement: 'bottom',
            arrow: false,
            trigger: 'click',
            interactive: true,
            theme: 'light'
        }">
        <img [src]="selectedFlag.img" alt="" class="w-[15px] mr-1 inline" />
        <span class="mr-2">{{ selectedFlag.text }}</span>
        <img src="../../../../assets/img/icons/caret-down.svg" alt="" class="w-[10px] inline">
      </a>
      <button (click)="toggleMobileMenu()"
        class="rounded-lg bg-skyblue h-[34px] w-[34px] text-white" *ngIf="showMenu">
        <fa-icon [icon]="faBars" class="text-[1.2rem] my-auto"></fa-icon>
      </button>
    </div>
  </nav>
</header>

<ng-template #flagTooltip>
  <div class="w-full bg-white font-medium">
    <div *ngFor="let flag of flags" class="flex justify-between p-2 cursor-pointer" (click)="selectFlag(flag)">
      <img [src]="flag.img" alt="" class="w-[15px] h-[15px] mr-1 inline" />
        <span class="mr-2 text-blue font-normal">{{ flag.text }}</span>
      </div>
  </div>
</ng-template>