import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CaptchaService {

    private apiService = inject(ApiService);

    verifyCaptchaToken(captchaToken : string) {
        return this.apiService.post('ValidateCaptcha', { captchaToken });
    }
}